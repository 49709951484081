import * as React from "react";
import styles from "./SideMenuBar.module.css";

export default function SideMenuBar({activeClass}) {
    var dashboardClass = styles.a;
    var dashboardTextClass = styles.menuOptionTextNotActive;
    var dashboardIcon = "https://cdn.builder.io/api/v1/image/assets/TEMP/1aa52052-2f3e-4fb9-9978-01eec0d3704a?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/1aa52052-2f3e-4fb9-9978-01eec0d3704a?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1aa52052-2f3e-4fb9-9978-01eec0d3704a?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/1aa52052-2f3e-4fb9-9978-01eec0d3704a?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/1aa52052-2f3e-4fb9-9978-01eec0d3704a?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1aa52052-2f3e-4fb9-9978-01eec0d3704a?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/1aa52052-2f3e-4fb9-9978-01eec0d3704a?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/1aa52052-2f3e-4fb9-9978-01eec0d3704a?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&"

    var applyForLoanClass = styles.a;
    var applyForLoanTextClass = styles.menuOptionTextNotActive;
    var applyForLoanIcon = "https://cdn.builder.io/api/v1/image/assets/TEMP/474d7198-ed2f-40a6-b947-150265a79d57?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/474d7198-ed2f-40a6-b947-150265a79d57?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/474d7198-ed2f-40a6-b947-150265a79d57?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/474d7198-ed2f-40a6-b947-150265a79d57?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/474d7198-ed2f-40a6-b947-150265a79d57?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/474d7198-ed2f-40a6-b947-150265a79d57?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/474d7198-ed2f-40a6-b947-150265a79d57?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/474d7198-ed2f-40a6-b947-150265a79d57?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&";

    var homeSearchClass = styles.a;
    var homeSearchTextClass = styles.menuOptionTextNotActive;
    var homeSearchIcon = "https://cdn.builder.io/api/v1/image/assets/TEMP/477ceb88-3b5d-43ef-a198-d94d0972bfa9?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/477ceb88-3b5d-43ef-a198-d94d0972bfa9?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/477ceb88-3b5d-43ef-a198-d94d0972bfa9?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/477ceb88-3b5d-43ef-a198-d94d0972bfa9?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/477ceb88-3b5d-43ef-a198-d94d0972bfa9?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/477ceb88-3b5d-43ef-a198-d94d0972bfa9?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/477ceb88-3b5d-43ef-a198-d94d0972bfa9?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/477ceb88-3b5d-43ef-a198-d94d0972bfa9?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&";

    var resourcesClass = styles.a;
    var resourcesTextClass = styles.menuOptionTextNotActive;
    var resourcesIcon = "https://cdn.builder.io/api/v1/image/assets/TEMP/92dcf7db-4abb-4dc7-8b7c-b117c630a260?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/92dcf7db-4abb-4dc7-8b7c-b117c630a260?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/92dcf7db-4abb-4dc7-8b7c-b117c630a260?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/92dcf7db-4abb-4dc7-8b7c-b117c630a260?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/92dcf7db-4abb-4dc7-8b7c-b117c630a260?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/92dcf7db-4abb-4dc7-8b7c-b117c630a260?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/92dcf7db-4abb-4dc7-8b7c-b117c630a260?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/92dcf7db-4abb-4dc7-8b7c-b117c630a260?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&";

    var creditScoreClass = styles.a;
    var creditScoreTextClass = styles.menuOptionTextNotActive;
    var creditScoreIcon = "https://cdn.builder.io/api/v1/image/assets/TEMP/5ba8ae83-f350-415d-90f2-d751d27f94ae?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/5ba8ae83-f350-415d-90f2-d751d27f94ae?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5ba8ae83-f350-415d-90f2-d751d27f94ae?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/5ba8ae83-f350-415d-90f2-d751d27f94ae?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/5ba8ae83-f350-415d-90f2-d751d27f94ae?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5ba8ae83-f350-415d-90f2-d751d27f94ae?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/5ba8ae83-f350-415d-90f2-d751d27f94ae?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/5ba8ae83-f350-415d-90f2-d751d27f94ae?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&";

    var connectBankClass = styles.a;
    var connectBnakTextClass = styles.menuOptionTextNotActive;
    var connectBankIcon = "https://cdn.builder.io/api/v1/image/assets/TEMP/19b93c2f-8c72-4c28-919c-89fddd46e026?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/19b93c2f-8c72-4c28-919c-89fddd46e026?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/19b93c2f-8c72-4c28-919c-89fddd46e026?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/19b93c2f-8c72-4c28-919c-89fddd46e026?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/19b93c2f-8c72-4c28-919c-89fddd46e026?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/19b93c2f-8c72-4c28-919c-89fddd46e026?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/19b93c2f-8c72-4c28-919c-89fddd46e026?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/19b93c2f-8c72-4c28-919c-89fddd46e026?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&";

    var chatLoClass = styles.a;
    var chatLoTextClass = styles.menuOptionTextNotActive;
    var chatLoIcon = "https://cdn.builder.io/api/v1/image/assets/TEMP/1d4acb12-0abe-4a64-a174-3004e92a343c?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/1d4acb12-0abe-4a64-a174-3004e92a343c?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1d4acb12-0abe-4a64-a174-3004e92a343c?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/1d4acb12-0abe-4a64-a174-3004e92a343c?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/1d4acb12-0abe-4a64-a174-3004e92a343c?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1d4acb12-0abe-4a64-a174-3004e92a343c?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/1d4acb12-0abe-4a64-a174-3004e92a343c?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/1d4acb12-0abe-4a64-a174-3004e92a343c?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&";

    var escrowClass = styles.a;
    var escrowTextClass = styles.menuOptionTextNotActive;
    var escrowIcon = "https://cdn.builder.io/api/v1/image/assets/TEMP/d02a50f6-9592-4ebc-ab4c-85840b09fe7b?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d02a50f6-9592-4ebc-ab4c-85840b09fe7b?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d02a50f6-9592-4ebc-ab4c-85840b09fe7b?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d02a50f6-9592-4ebc-ab4c-85840b09fe7b?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d02a50f6-9592-4ebc-ab4c-85840b09fe7b?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d02a50f6-9592-4ebc-ab4c-85840b09fe7b?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d02a50f6-9592-4ebc-ab4c-85840b09fe7b?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d02a50f6-9592-4ebc-ab4c-85840b09fe7b?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&";

    var profileClass = styles.a;
    var profileTextClass = styles.menuOptionTextNotActive;
    var profileIcon = "https://cdn.builder.io/api/v1/image/assets/TEMP/674f5cd2-56da-4cac-a906-2ef4cf7bbb53?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/674f5cd2-56da-4cac-a906-2ef4cf7bbb53?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/674f5cd2-56da-4cac-a906-2ef4cf7bbb53?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/674f5cd2-56da-4cac-a906-2ef4cf7bbb53?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/674f5cd2-56da-4cac-a906-2ef4cf7bbb53?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/674f5cd2-56da-4cac-a906-2ef4cf7bbb53?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/674f5cd2-56da-4cac-a906-2ef4cf7bbb53?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/674f5cd2-56da-4cac-a906-2ef4cf7bbb53?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&";

    var settingsClass = styles.a;
    var settingsTextClass = styles.menuOptionTextNotActive;
    var settingsIcon = "https://cdn.builder.io/api/v1/image/assets/TEMP/faf898a0-2179-47c0-929a-8b12b4da776d?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=100"

    if(activeClass === "dashboard"){
        dashboardClass = styles.active;
        dashboardTextClass = styles.menuOptionText;
        dashboardIcon = "https://cdn.builder.io/api/v1/image/assets/TEMP/aacb5815-11ec-4eba-9aa1-6fcea9c10d12?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/aacb5815-11ec-4eba-9aa1-6fcea9c10d12?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/aacb5815-11ec-4eba-9aa1-6fcea9c10d12?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/aacb5815-11ec-4eba-9aa1-6fcea9c10d12?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/aacb5815-11ec-4eba-9aa1-6fcea9c10d12?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/aacb5815-11ec-4eba-9aa1-6fcea9c10d12?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/aacb5815-11ec-4eba-9aa1-6fcea9c10d12?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/aacb5815-11ec-4eba-9aa1-6fcea9c10d12?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&";
    } else if(activeClass === "applyForLoan"){
        applyForLoanClass = styles.active;
        applyForLoanTextClass = styles.menuOptionText;
        applyForLoanIcon = "https://cdn.builder.io/api/v1/image/assets/TEMP/04f8e527-5fc6-46cb-b4f6-2bf10605026b?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/04f8e527-5fc6-46cb-b4f6-2bf10605026b?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/04f8e527-5fc6-46cb-b4f6-2bf10605026b?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/04f8e527-5fc6-46cb-b4f6-2bf10605026b?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/04f8e527-5fc6-46cb-b4f6-2bf10605026b?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/04f8e527-5fc6-46cb-b4f6-2bf10605026b?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/04f8e527-5fc6-46cb-b4f6-2bf10605026b?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/04f8e527-5fc6-46cb-b4f6-2bf10605026b?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&";
    } else if (activeClass === "homeSearch"){
        homeSearchClass = styles.active;
        homeSearchTextClass = styles.menuOptionText;
        homeSearchIcon = "https://cdn.builder.io/api/v1/image/assets/TEMP/a1a63e00-f7e6-4b57-90f5-4151ec80068e?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/a1a63e00-f7e6-4b57-90f5-4151ec80068e?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a1a63e00-f7e6-4b57-90f5-4151ec80068e?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/a1a63e00-f7e6-4b57-90f5-4151ec80068e?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/a1a63e00-f7e6-4b57-90f5-4151ec80068e?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a1a63e00-f7e6-4b57-90f5-4151ec80068e?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/a1a63e00-f7e6-4b57-90f5-4151ec80068e?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/a1a63e00-f7e6-4b57-90f5-4151ec80068e?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&";
    } else if (activeClass === "resources"){
        resourcesClass = styles.active;
        resourcesTextClass = styles.menuOptionText;
        resourcesIcon = "https://cdn.builder.io/api/v1/image/assets/TEMP/f69bded2-1cd6-4705-8042-69f5f8e7c966?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/f69bded2-1cd6-4705-8042-69f5f8e7c966?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/f69bded2-1cd6-4705-8042-69f5f8e7c966?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/f69bded2-1cd6-4705-8042-69f5f8e7c966?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/f69bded2-1cd6-4705-8042-69f5f8e7c966?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/f69bded2-1cd6-4705-8042-69f5f8e7c966?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/f69bded2-1cd6-4705-8042-69f5f8e7c966?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/f69bded2-1cd6-4705-8042-69f5f8e7c966?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&";
    } else if (activeClass === "creditScore"){
        creditScoreClass = styles.active;
        creditScoreTextClass = styles.menuOptionText;
        creditScoreIcon = "https://cdn.builder.io/api/v1/image/assets/TEMP/162b2136-9c94-461a-afeb-3244b2146865?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/162b2136-9c94-461a-afeb-3244b2146865?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/162b2136-9c94-461a-afeb-3244b2146865?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/162b2136-9c94-461a-afeb-3244b2146865?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/162b2136-9c94-461a-afeb-3244b2146865?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/162b2136-9c94-461a-afeb-3244b2146865?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/162b2136-9c94-461a-afeb-3244b2146865?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/162b2136-9c94-461a-afeb-3244b2146865?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&";
    } else if (activeClass === "connectBank"){
        connectBankClass = styles.active;
        connectBnakTextClass = styles.menuOptionText;
        connectBankIcon = "https://cdn.builder.io/api/v1/image/assets/TEMP/b8af2d60-afe0-41b9-bc44-ed6d099ae797?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b8af2d60-afe0-41b9-bc44-ed6d099ae797?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b8af2d60-afe0-41b9-bc44-ed6d099ae797?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b8af2d60-afe0-41b9-bc44-ed6d099ae797?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b8af2d60-afe0-41b9-bc44-ed6d099ae797?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b8af2d60-afe0-41b9-bc44-ed6d099ae797?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b8af2d60-afe0-41b9-bc44-ed6d099ae797?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b8af2d60-afe0-41b9-bc44-ed6d099ae797?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&";
    } else if (activeClass === "chatWithLo"){
        chatLoClass = styles.active;
        chatLoTextClass = styles.menuOptionText;
        chatLoIcon = "https://cdn.builder.io/api/v1/image/assets/TEMP/1558b8ef-555e-4ef0-b6ec-7d58bd16ea3c?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/1558b8ef-555e-4ef0-b6ec-7d58bd16ea3c?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1558b8ef-555e-4ef0-b6ec-7d58bd16ea3c?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/1558b8ef-555e-4ef0-b6ec-7d58bd16ea3c?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/1558b8ef-555e-4ef0-b6ec-7d58bd16ea3c?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1558b8ef-555e-4ef0-b6ec-7d58bd16ea3c?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/1558b8ef-555e-4ef0-b6ec-7d58bd16ea3c?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/1558b8ef-555e-4ef0-b6ec-7d58bd16ea3c?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&";
    } else if (activeClass === "escrowCenter"){
        escrowClass = styles.active;
        escrowTextClass = styles.menuOptionText;
        escrowIcon = "https://cdn.builder.io/api/v1/image/assets/TEMP/5bd1ad56-0f71-4307-96ee-f746678e493b?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/5bd1ad56-0f71-4307-96ee-f746678e493b?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5bd1ad56-0f71-4307-96ee-f746678e493b?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/5bd1ad56-0f71-4307-96ee-f746678e493b?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/5bd1ad56-0f71-4307-96ee-f746678e493b?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5bd1ad56-0f71-4307-96ee-f746678e493b?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/5bd1ad56-0f71-4307-96ee-f746678e493b?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/5bd1ad56-0f71-4307-96ee-f746678e493b?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&";
    } else if (activeClass === "profile"){
        profileClass = styles.active;
        profileTextClass = styles.menuOptionText;
        profileIcon = "https://cdn.builder.io/api/v1/image/assets/TEMP/bfed7439-830f-4cad-a840-cafcce786718?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/bfed7439-830f-4cad-a840-cafcce786718?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/bfed7439-830f-4cad-a840-cafcce786718?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/bfed7439-830f-4cad-a840-cafcce786718?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/bfed7439-830f-4cad-a840-cafcce786718?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/bfed7439-830f-4cad-a840-cafcce786718?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/bfed7439-830f-4cad-a840-cafcce786718?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/bfed7439-830f-4cad-a840-cafcce786718?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&";
    } else if (activeClass === "settings"){
        settingsClass = styles.active;
        settingsTextClass = styles.menuOptionText;
        settingsIcon = "https://cdn.builder.io/api/v1/image/assets/TEMP/998614de-bc1e-4d6b-8329-bc028c1f32e5?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/998614de-bc1e-4d6b-8329-bc028c1f32e5?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/998614de-bc1e-4d6b-8329-bc028c1f32e5?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/998614de-bc1e-4d6b-8329-bc028c1f32e5?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/998614de-bc1e-4d6b-8329-bc028c1f32e5?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/998614de-bc1e-4d6b-8329-bc028c1f32e5?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/998614de-bc1e-4d6b-8329-bc028c1f32e5?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/998614de-bc1e-4d6b-8329-bc028c1f32e5?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&";
    }
  return (
    <div className={styles.sidebar}
    >
        <a className={dashboardClass} href="/">
            <div className={styles.menuOptionTextDiv}
            >
                <img
                loading="lazy"
                srcSet={dashboardIcon}
                className={styles.menuIcon}
                alt="home icon"
                />
                <div className={dashboardTextClass}
                >
                Dashboard
                </div>
            </div>
        </a>
        <a className={applyForLoanClass} href="/apply-for-loan">
            <div className={styles.menuOptionTextDiv}
            >
                <img
                loading="lazy"
                srcSet={applyForLoanIcon}
                className={styles.menuIcon}
                alt = "loan icon"
                />
                <div className={applyForLoanTextClass}
                >
                Apply for loan
                </div>
            </div>
        </a>
        <a className={homeSearchClass} href="/home-search">
            <div className={styles.menuOptionTextDiv}
            >
                <img
                loading="lazy"
                srcSet={homeSearchIcon}
                className={styles.menuIcon}
                alt = "search icon"
                />
                <div className={homeSearchTextClass}
                >
                Home Search
                </div>
            </div>
        </a>
        <a className={resourcesClass} href="/resources">
            <div className={styles.menuOptionTextDiv}
            >
                <img
                loading="lazy"
                srcSet={resourcesIcon}
                className={styles.menuIcon}
                alt = "resource icon"
                />
                <div className={resourcesTextClass}
                >
                Resources
                </div>
            </div>
        </a>
        <a className={creditScoreClass} href="/credit-score">
            <div className={styles.menuOptionTextDiv}
            >
                <img
                loading="lazy"
                srcSet={creditScoreIcon}
                className={styles.menuIcon}
                alt = "credit scor icon"
                />
                <div className={creditScoreTextClass}
                >
                Credit Score
                </div>
            </div>
        </a>
        <a className={connectBankClass} href="/connect-your-bank">
            <div className={styles.menuOptionTextDiv}
            >
                <img
                loading="lazy"
                srcSet={connectBankIcon}
                className={styles.menuIcon}
                alt = "bank icon"
                />
                <div className={connectBnakTextClass}
                >
                Connect your bank
                </div>
            </div>
        </a>
        <a className={chatLoClass} href="/chat-with-lo">
            <div className={styles.menuOptionTextDiv}
            >
                <img
                loading="lazy"
                srcSet={chatLoIcon}
                className={styles.menuIcon}
                alt = "chat icon"
                />
                <div className={chatLoTextClass}
                >
                Chat with LO
                </div>
            </div>
        </a>
        <a className={escrowClass} href="/escrow-center">
            <div className={styles.menuOptionTextDiv}
            >
                <img
                loading="lazy"
                srcSet={escrowIcon}
                className={styles.menuIcon}
                alt = "escrow icon"
                />
                <div className={escrowTextClass}
                >
                Escrow Center
                </div>
            </div>
        </a>
        <a className={profileClass} href="/profile">
            <div className={styles.menuOptionTextDiv}
            >
                <img
                loading="lazy"
                srcSet={profileIcon}
                className={styles.menuIcon}
                alt = "profile icon"
                />
                <div className={profileTextClass}
                >
                Profile
                </div>
            </div>
        </a>
        <a className={settingsClass} href="/settings">
            <div className={styles.menuOptionTextDiv}
            >
                <img
                loading="lazy"
                srcSet={settingsIcon} 
                className={styles.menuIcon}
                alt = "settings icon"
                />
                <div className={settingsTextClass}
                >
                Settings
                </div>
            </div>
        </a>
    </div>
  );
}

