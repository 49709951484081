import * as React from "react";
import SideMenuBar from "./SideMenuBar";
import NavBar from './NavBar';
import styles from './ApplyForLoan.module.css'
import YourLoanOfficer from "./YourLoanOfficer";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import actions from "../actions";
import helpers from "../helpers";

const deepClone = obj => {
  if (obj === null) return null;
  let clone = Object.assign({}, obj);
  Object.keys(clone).forEach(
    key =>
      (clone[key] =
        typeof obj[key] === 'object' ? deepClone(obj[key]) : obj[key])
  );
  if (Array.isArray(obj)) {
    clone.length = obj.length;
    return Array.from(clone);
  }
  return clone;
};

export default function AFLIncomeForm() {
  const navigate = useNavigate();
  
  const [coborrowerSelected, setCoborrowerSelected] = useState(false);

  const [incomeType, setIncomeType] = useState('Default');
  const [monthlyIncome, setMonthlyIncome] = useState('');

  const [coIncomeType, setCoIncomeType] = useState('Default');
  const [coMonthlyIncome, setCoMonthlyIncome] = useState('');

  const [borrowerTab, setBorrowerTab] = useState(styles.active);
  const [coBorrowerTab, setCoBorrowerTab] = useState(styles.a);
  const [borrowerDiv, setBorrowerDiv] = useState(styles.borrowerFormCardTabs);
  const [coBorrowerDiv, setCoBorrowerDiv] = useState(styles.borrowerFormCardTabsInvisible);

  //keep track of what the user has put in for income sources
  const [incomeInputs, setIncomeInputs] = useState([]);

  const [oldInputs, setOldInputs] = useState(false);

  //input validation useStates
  const [incomeTypeError, setIncomeTypeError] = useState("");
  const [monthlyIncomeError, setMonthlyIncomeError] = useState("");
  const [incomeInputsError, setIncomeInputsError] = useState("");

  //keep track of what the user has put in for income sources
  const [coIncomeInputs, setCoIncomeInputs] = useState([]);

  const [coOldInputs, setCoOldInputs] = useState(false);

  //input validation useStates
  const [coIncomeTypeError, setCoIncomeTypeError] = useState("");
  const [coMonthlyIncomeError, setCoMonthlyIncomeError] = useState("");
  const [coIncomeInputsError, setCoIncomeInputsError] = useState("");

  const authUser = useSelector((state) => state.users);
  const dispatch = useDispatch();

  useEffect(()=> {
    if(authUser[0].data.Profile.ApplyForLoanData.BorrowerNameForm) {
      setCoborrowerSelected(authUser[0].data.Profile.ApplyForLoanData.BorrowerNameForm.borrower.coborrowerSelected)
    }
    if(authUser[0].data.Profile.ApplyForLoanData.IncomeForm) {
      if(authUser[0].data.Profile.ApplyForLoanData.IncomeForm.borrower) {
        setOldInputs(true);
      }
      if(authUser[0].data.Profile.ApplyForLoanData.IncomeForm.coborrower) {
        setCoOldInputs(true);
      }
    }
  }, [authUser])

  useEffect(()=> {
    if(authUser[0].data.Profile.ApplyForLoanData.IncomeForm) {
      if(authUser[0].data.Profile.ApplyForLoanData.IncomeForm.borrower && oldInputs === true) {
        let oldInputVals = authUser[0].data.Profile.ApplyForLoanData.IncomeForm.borrower;
        for(let i = 0; i < oldInputVals.length; i++){
            addIncomeInitial(oldInputVals[i][0], oldInputVals[i][1], "list");
        }
      }
    }
  }, [oldInputs])

  useEffect(()=> {
    if(authUser[0].data.Profile.ApplyForLoanData.IncomeForm) {
      if(authUser[0].data.Profile.ApplyForLoanData.IncomeForm.coborrower && coOldInputs === true) {
        let oldInputVals = authUser[0].data.Profile.ApplyForLoanData.IncomeForm.coborrower;
        for(let i = 0; i < oldInputVals.length; i++){
            addIncomeInitial(oldInputVals[i][0], oldInputVals[i][1], "colist");
        }
      }
    }
  }, [coOldInputs])

  function updateBorrowerTab() {
    setBorrowerTab(styles.active)
    setCoBorrowerTab(styles.a)
    setBorrowerDiv(styles.borrowerFormCardTabs)
    setCoBorrowerDiv(styles.borrowerFormCardTabsInvisible)
  }

  function updateCoBorrowerTab() {
    setBorrowerTab(styles.a)
    setCoBorrowerTab(styles.active)
    setBorrowerDiv(styles.borrowerFormCardTabsInvisible)
    setCoBorrowerDiv(styles.borrowerFormCardTabs)
  }

  const handleForm = async (event) => {
    if(incomeInputs.length === 0){
      setIncomeInputsError("You must add a monthly income");
    } else if (coborrowerSelected && coIncomeInputs.length === 0){
      setIncomeInputsError("You must add a monthly income for your coborrower");
    }else {
      setIncomeInputsError("")
      await localStorage();
    }
  };

  const localStorage = async () => {
    //pass in the list of income inputs the user has added
    let formIncomeInputs = {
      borrower: incomeInputs,
      coborrower: coIncomeInputs,
      //borrower: [["Default", 2000]]
    }
    const clientId = authUser[0].id;
    const url = "https://uat-api.tlcengine.com/v3/api/mlspin-md/clients/" + clientId;
    const token = authUser[0].user.AccessToken

    let userData = await helpers.getProfile(url, token);
    let copyUserData = helpers.updateData(userData, "IncomeForm", formIncomeInputs)
    await helpers.updateProfile(copyUserData, url, token);

    let thisClient = deepClone(authUser[0]);
    thisClient.data = copyUserData;
    dispatch(actions.addClientPI(thisClient));
    navigate("/apply-for-loan/borrowerform8")
  }

  useEffect(()=> {
    //console.log(incomeInputs);
  }, [incomeInputs])

  const addIncomeInitial = (currentType, currentIncome, list) => {
    let errors = 0;

    try{
      helpers.validIncomeType(currentType);
      setIncomeTypeError("");
    } catch(e) {
      setIncomeTypeError(e.message);
      errors ++;
    }

    try{
      helpers.validInt(currentIncome, "Monthly income");
      setMonthlyIncomeError("");
    } catch(e) {
      setMonthlyIncomeError(e.message);
      errors ++;
    }

    if(errors === 0)
    {
      setIncomeInputsError("");
      let source = [currentType, currentIncome];
      if(list === "list"){
        setIncomeInputs(arr => [...arr, source]);
      } else {
        setCoIncomeInputs(arr => [...arr, source]);
      }

      let li = document.createElement("li");
      let text = document.createTextNode(currentType + " - $" + currentIncome);
      li.id = currentType + " " + currentIncome + " " + list;
      li.appendChild(text);

      let removeBtn = document.createElement("input")
      removeBtn.type = "button";
      removeBtn.value = "Remove";
      removeBtn.className = styles.removeButton
      removeBtn.addEventListener("click", function() {
        let elementToRemove = li.id.split(" ");
        if(elementToRemove[2] === "list"){
          let temp = [elementToRemove[0], elementToRemove[1]]
          setIncomeInputs(arr => arr.filter(income => !listsEqual(income, temp)))
        } else {
          let temp = [elementToRemove[0], elementToRemove[1]]
          setCoIncomeInputs(arr => arr.filter(income => !listsEqual(income, temp)))
        }
        li.remove();
      })
      li.appendChild(removeBtn)

      document.getElementById(list).appendChild(li);
    }
  }

  const listsEqual = (a, b) => {
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;

    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  const addIncome = (list) => {
    let errors = 0;

    if(list === "list"){
      try{
        helpers.validIncomeType(incomeType);
        setIncomeTypeError("");
      } catch(e) {
        setIncomeTypeError(e.message);
        errors ++;
      }

      try{
        helpers.validInt(monthlyIncome, "Monthly income");
        setMonthlyIncomeError("");
      } catch(e) {
        setMonthlyIncomeError(e.message);
        errors ++;
      }
    } else {
      try{
        helpers.validIncomeType(coIncomeType);
        setCoIncomeTypeError("");
      } catch(e) {
        setCoIncomeTypeError(e.message);
        errors ++;
      }

      try{
        helpers.validInt(coMonthlyIncome, "Monthly income");
        setCoMonthlyIncomeError("");
      } catch(e) {
        setCoMonthlyIncomeError(e.message);
        errors ++;
      }
    }

    if(errors === 0)
    {
      setIncomeInputsError("")
      let source = [];
      if(list == "list"){
        source = [incomeType, monthlyIncome]
        setIncomeInputs(arr => [...arr, source])
      } else {
        source = [coIncomeType, coMonthlyIncome]
        setCoIncomeInputs(arr => [...arr, source])
      }

      let li = document.createElement("li");
      let text = document.createTextNode(source[0] + " - $" + source[1]);
      li.id = source[0] + " " + source[1] + " " + list;
      li.appendChild(text);

      let removeBtn = document.createElement("input")
      removeBtn.type = "button";
      removeBtn.value = "Remove";
      removeBtn.className = styles.removeButton
      removeBtn.addEventListener("click", function() {
        let elementToRemove = li.id.split(" ");
        if(elementToRemove[2] === "list"){
          let temp = [elementToRemove[0], elementToRemove[1]]
          setIncomeInputs(arr => arr.filter(income => !listsEqual(income, temp)))
        } else {
          let temp = [elementToRemove[0], elementToRemove[1]]
          setCoIncomeInputs(arr => arr.filter(income => !listsEqual(income, temp)))
        }
        li.remove();
      })
      li.appendChild(removeBtn)

      document.getElementById(list).appendChild(li);
    }
  }

  return (
    <div className={styles.applyScreen}>
      <NavBar/>
      <div className={styles.applyContainer}>
        <div className={styles.applyContent}>
          <SideMenuBar activeClass={"applyForLoan"}/>
          <div className={styles.formContainer}>

            <div className={styles.rectangleTabs}/>
            <ul className={styles.tabs}>
              <li className={borrowerTab}><button onClick={updateBorrowerTab}>Borrower</button></li> 
              {coborrowerSelected && <li className={coBorrowerTab}><button onClick={updateCoBorrowerTab}>Co-Borrower</button></li> }
            </ul>

            <div id="borrower" className={borrowerDiv}>
              <div className={styles.borrowerFormCardDiv}>
              <div className={styles.borrowerFormCardTitleText}>
                <div className={styles.borrowerFormTitle}>
                  Borrower Monthly Income
                </div>
              </div>
              <div className={styles.errorAndInputDiv}>
                {incomeInputsError != "" && <div className={styles.errorDiv}>{incomeInputsError}</div>}
              </div>
              <div><br/></div>
              <div className={styles.inputsContainer}>
                <div className={styles.uldiv}>
                  <ul id="list"></ul>
                </div>
              </div>
              <div >
                <div className={styles.inputsContainer}>
                <div className={styles.errorAndInputDiv}>
                  {incomeTypeError != "" && <div className={styles.errorDiv}>{incomeTypeError}</div>}
                    <div className={styles.inputDiv}>
                        <div className={styles.dropdown}>
                            <select className={styles.selectBox}
                              onChange={(e) => setIncomeType(e.target.value)}
                            >
                                <option>Type of Income</option>
                                <option>Employee wages and salary income</option>
                                <option>Self-employed, freelance, and gig work income</option>
                                <option>Part-time income</option>
                                <option>Bonuses and commissions</option>
                                <option>Interest and dividend income</option>
                                <option>Retirement, government, and pension income</option>
                                <option>Social Security income</option>
                                <option>Disability payments</option>
                                <option>Leave payments</option>
                                <option>Foster care payments</option>
                                <option>Alimony and child support</option>
                                <option>Trust income</option>
                                <option>Unemployment benefits</option>
                                <option>Rental or investment income</option>
                                <option>VA benefits</option>
                                <option>Military income</option>
                                <option>Other</option>
                            </select>
                        </div>
                    </div>
                    </div>
                  <div className={styles.errorAndInputDiv}>
                    {monthlyIncomeError != "" && <div className={styles.errorDiv}>{monthlyIncomeError}</div>}
                    <div className={styles.inputDiv}>
                    <input
                        onChange={(e) => setMonthlyIncome(e.target.value)}
                        name="monthlyIncome"
                        type="text"
                        className={styles.formInput}
                        placeholder={"Monthly Income"}
                        value={monthlyIncome}
                    />
                  </div>
                  </div>
                </div>
            </div>
            <div>
              <button className={styles.newIncomeButton} onClick={() => addIncome("list")}>Add Another Source of Income</button>
            </div>
          </div>
            <div className={styles.nextButtonDiv}>
              <button className={styles.backButton} onClick={()=>navigate("/apply-for-loan/borrowerform6")}>Back</button>
              <button className={styles.nextButton} onClick={handleForm}>Next</button>
            </div>
          </div>


          <div id="coborrower" className={coBorrowerDiv}>
            <div className={styles.borrowerFormCardDiv}>
                <div className={styles.borrowerFormCardTitleText}>
                  <div className={styles.borrowerFormTitle}>
                    Co-Borrower Monthly Income
                  </div>
                </div>
                <div className={styles.errorAndInputDiv}>
                  {coIncomeInputsError != "" && <div className={styles.errorDiv}>{coIncomeInputsError}</div>}
                </div>
                <div className={styles.inputsContainer}>
                  <div className={styles.uldiv}>
                    <ul id="colist"></ul>
                  </div>
                </div>
                <div >
                  <div className={styles.inputsContainer}>
                    <div className={styles.errorAndInputDiv}>
                      {coIncomeTypeError != "" && <div className={styles.errorDiv}>{coIncomeTypeError}</div>}
                      <div className={styles.inputDiv}>
                          <div className={styles.dropdown}>
                              <select className={styles.selectBox}
                                onChange={(e) => setCoIncomeType(e.target.value)}
                              >
                                <option>Type of Income</option>
                                <option>Employee wages and salary income</option>
                                <option>Self-employed, freelance, and gig work income</option>
                                <option>Part-time income</option>
                                <option>Bonuses and commissions</option>
                                <option>Interest and dividend income</option>
                                <option>Retirement, government, and pension income</option>
                                <option>Social Security income</option>
                                <option>Disability payments</option>
                                <option>Leave payments</option>
                                <option>Foster care payments</option>
                                <option>Alimony and child support</option>
                                <option>Trust income</option>
                                <option>Unemployment benefits</option>
                                <option>Rental or investment income</option>
                                <option>VA benefits</option>
                                <option>Military income</option>
                                <option>Other</option>
                              </select>
                          </div>
                      </div>
                    </div>
                      <div className={styles.errorAndInputDiv}>
                        {coMonthlyIncomeError != "" && <div className={styles.errorDiv}>{coMonthlyIncomeError}</div>}
                        <div className={styles.inputDiv}>
                          <input
                              onChange={(e) => setCoMonthlyIncome(e.target.value)}
                              name="monthlyIncome"
                              type="text"
                              className={styles.formInput}
                              placeholder={"Monthly Income"}
                          />
                        </div>
                    </div>
                  </div>
              </div>
              <div>
                <button className={styles.newIncomeButton} onClick={() => addIncome("colist")}>Add Another Source of Income</button>
              </div>
            </div>
          </div>

        </div>




        <div className={styles.myLoanerContainer}>
             <div className={styles.myLoanerContent}>
              <YourLoanOfficer
                name={"Krishna Malyala"}
                email={"kmalyala@nexamortgage.com"}
                phone={"334-625-9252"}
                nmls1={"NMLS #1875937"}
                states={"NJ"}
                nmls2={"NMLS #1660690"}
              />
            </div>      
            
          </div>
      </div>
    </div>
  </div>
  );
}

