import * as React from "react";
import styles from "./UserAuthPages.module.css";
import { useCallback } from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import YourLoanOfficer from "./YourLoanOfficer";
import axios from "axios";
import NavBar from "./NavBar";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import actions from "../actions";

export default function SignIn() {
  const [passwordInputType, setPasswordInputType] = useState("password");
  const [eyeConImg, setEyeConImg] = useState("/eyeIcon.svg");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const getUserInfo = async (user) => {
    let userData;
    let thisClient = {};
    const clientId = user.Audience.Id;
    const url = `https://uat-api.tlcengine.com/v3/api/${user.Audience.MLSKey}/clients/${clientId}`;
    try {
      userData = await axios.get(url, {
        headers: { Authorization: `Bearer ${user.AccessToken}` },
      });
      thisClient.data = userData.data;
    } catch (e) {
      console.log("Could not get access to database");
    }

    if (thisClient.hasOwnProperty("data")) {
      thisClient.user = user;
      thisClient.name = user.Audience.FirstName + " " + user.Audience.LastName;
      thisClient.id = user.Audience.Id;
      console.log(thisClient)
      dispatch(actions.addClientPI(thisClient));
    }
    
  };

  const onEyeConClick = useCallback(() => {
    if (passwordInputType === "password") {
      setPasswordInputType("text");
      setEyeConImg("/closedEye.svg");
    } else {
      setPasswordInputType("password");
      setEyeConImg("/eyeIcon.svg");
    }
  });

  const handleForm = async (event) => {
    event.preventDefault();

    const { result, error } = await signIn(email, password);

    if (error) {
      return console.log(error);
    }
  };

  async function signIn(email, password) {
    let result = null,
      error = null;
    try {
      //console.log("1")
      const { data } = await axios.post(
        "https://uat-api.tlcengine.com/v3/api/mlspin-md/accesstokens/verifylogin",
        {
          Username: email,
          Password: password,
        }
      );
      //console.log(data);
      getUserInfo(data);
      navigate("/");
    } catch (e) {
      alert("Invalid username or password");
    }

    var form = document.getElementById("signInForm");
    form.reset();

    return { result, error };
  }

  return (
    <div className={styles.temp}>
      <NavBar />
      <div className={styles.temp14}>
        <div className={styles.temp15}>
          <div className={styles.loanOfficer}>
            <div className={styles.loanOfficerCard}>
              <YourLoanOfficer
                name={"Krishna Malyala"}
                email={"kmalyala@nexamortgage.com"}
                phone={"334-625-9252"}
                nmls1={"NMLS #1875937"}
                states={"NJ"}
                nmls2={"NMLS #1660690"}
              />
            </div>
          </div>
          <div className={styles.temp20}>
            <div className={styles.forgotPasswordCard}>
              <div className={styles.temp21}>
                <div className={styles.temp22}>
                  <div className={styles.forgotPassword}>Sign In</div>
                  <form onSubmit={handleForm} id="signInForm">
                    <div className={styles.loanOfficerInfo}>
                      <span>Don't Have an account? </span>
                      <Link to="/sign-up" className={styles.link}>
                        Sign Up
                      </Link>
                    </div>
                    <div className={styles.email}>Email</div>
                    <div className={styles.inputDiv}>
                      <input
                        onChange={(e) => setEmail(e.target.value)}
                        name="emailInput"
                        type="text"
                        className={styles.signInInput}
                      />
                    </div>
                    <div className={styles.email}>Password</div>
                    <div className={styles.pswdInputField}>
                      <div className={styles.inputDiv}>
                        <input
                          onChange={(e) => setPassword(e.target.value)}
                          name="pswdInput"
                          type={passwordInputType}
                          className={styles.signInInput}
                        />
                        <img
                          className={styles.eyeIcon}
                          alt=""
                          src={eyeConImg}
                          onClick={onEyeConClick}
                        />
                        <br />
                      </div>
                    </div>
                    <div className={styles.btnDiv}>
                      <button className={styles.signInBtn} type="submit">
                        Sign In
                      </button>
                    </div>
                  </form>
                </div>
                <div className={styles.forgotPasswordLink}>
                  <Link to="/forgot-password" className={styles.link}>
                    Forgot Password?
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
