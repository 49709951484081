import {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import {useSelector} from 'react-redux';
import styles from "./NavBar.module.css";
import {useDispatch} from 'react-redux';
import actions from '../actions';

export default function NavBar() {
  let navigate = useNavigate();
  const authUser = useSelector((state) => state.users);

  const [signedIn, setSignedIn] = useState(false);
  useEffect(() => {
    if (authUser && authUser[0]) {
      setSignedIn(true);
    } else {
      setSignedIn(false);
    }
  }, []);
  const dispatch = useDispatch();

  const unauthenticateUser = () => {
      if(authUser[0]){
        dispatch(actions.unauthenticateUser(authUser[0]));
      }
      setSignedIn(false)
  };

  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  useEffect(() => {
    // Update the current date and time every second
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => {
      clearInterval(intervalId); // Clean up the interval
    };
  }, []);


  const formattedTime = currentDateTime.toLocaleString("en-US", {
    hour: "numeric",
    minute: "2-digit",
  });
  
  const formattedDate = currentDateTime.toLocaleString("en-US", {
    weekday: "long",
    month: "long",
    day: "numeric",
    year: "numeric",
  });

  return (
    <div className={styles.NavBarDiv}>
      <div className={styles.NavBarContainer}>
        <div className={styles.NavBarContents}>
          <div className={styles.NavBarContent1}>
            <img
              loading="lazy"
              src="/TLCengineNewLogo.png"
              className={styles.logoImg}
              onClick={() => {
                navigate("/");
              }}
            />
            <div className={styles.timeAndDateContainer}>
              <div className={styles.timeContent}>{formattedTime}</div>
              <div className={styles.text2}>{formattedDate} </div>
            </div>
          </div>

          <div className={styles.NavBarContent2}>
            <div className={styles.text2}>Support</div>
            <img
              loading="lazy"
              srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/7f4d4aea-3d7c-4dec-880e-266c348d0125?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/7f4d4aea-3d7c-4dec-880e-266c348d0125?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/7f4d4aea-3d7c-4dec-880e-266c348d0125?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/7f4d4aea-3d7c-4dec-880e-266c348d0125?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/7f4d4aea-3d7c-4dec-880e-266c348d0125?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/7f4d4aea-3d7c-4dec-880e-266c348d0125?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/7f4d4aea-3d7c-4dec-880e-266c348d0125?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/7f4d4aea-3d7c-4dec-880e-266c348d0125?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&"
              className={styles.dropDownArrow}
            />
            {signedIn ? (
              <>
                <div className={styles.text2}>
                  <span>Hello </span>
                  <span>{authUser[0].name}</span>
                </div>
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/a9fccb0c-5311-41f5-8944-6b6dc44e07cf?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/a9fccb0c-5311-41f5-8944-6b6dc44e07cf?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a9fccb0c-5311-41f5-8944-6b6dc44e07cf?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/a9fccb0c-5311-41f5-8944-6b6dc44e07cf?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/a9fccb0c-5311-41f5-8944-6b6dc44e07cf?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/a9fccb0c-5311-41f5-8944-6b6dc44e07cf?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/a9fccb0c-5311-41f5-8944-6b6dc44e07cf?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/a9fccb0c-5311-41f5-8944-6b6dc44e07cf?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&"
                  className={styles.profileImg}
                />
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/d1e09e5d-b172-4b27-b9b1-3aba795f05f7?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/d1e09e5d-b172-4b27-b9b1-3aba795f05f7?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d1e09e5d-b172-4b27-b9b1-3aba795f05f7?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/d1e09e5d-b172-4b27-b9b1-3aba795f05f7?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/d1e09e5d-b172-4b27-b9b1-3aba795f05f7?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/d1e09e5d-b172-4b27-b9b1-3aba795f05f7?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/d1e09e5d-b172-4b27-b9b1-3aba795f05f7?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/d1e09e5d-b172-4b27-b9b1-3aba795f05f7?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&"
                  className={styles.signOutIcon}
                  onClick={unauthenticateUser}
                />
              </>
            ) : (
              <>
                <div className={styles.text2}>
                  <span onClick={()=>{navigate("/sign-in")}}>Sign In </span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
