import * as React from "react";
import styles from "./Home.module.css";
import SideMenuBar from "./SideMenuBar";
import NavBar from "./NavBar";
import YourLoanOfficer from "./YourLoanOfficer";

import { useSelector } from "react-redux";
import axios from "axios";
import { useDispatch } from "react-redux";
import actions from "../actions";
import { useState, useEffect } from "react";

export default function MyComponent() {
  return (
    <div className={styles.homeScreen}>
      <NavBar />
      <div className={styles.homeContainer}>
        <div className={styles.homeContent}>
          <SideMenuBar activeClass={"dashboard"} />
          <div className={styles.borrowerDashboardContainer}>
            <div className={styles.borrowerDashboardContent}>
              <div className={styles.borrowerDashboardLayout}>
                <div className={styles.borrowerDashboardInfoContainer}>
                  <div className={styles.borrowerDashboardInfoContents}>
                    <div className={styles.borrowerDashboardInfoContent1}>
                      <div className={styles.borrowerInfo1}>
                        <div className={styles.borrowerInfoContent1}>
                          <div className={styles.borrowerInfoItem1}>
                            <div className={styles.borrowerInfoItemBg}>
                              <div className={styles.borrowerPurchaseInfo}>
                                <img
                                  loading="lazy"
                                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/08313e3e-5a58-433f-a4a2-625a1ca9d61f?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/08313e3e-5a58-433f-a4a2-625a1ca9d61f?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/08313e3e-5a58-433f-a4a2-625a1ca9d61f?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/08313e3e-5a58-433f-a4a2-625a1ca9d61f?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/08313e3e-5a58-433f-a4a2-625a1ca9d61f?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/08313e3e-5a58-433f-a4a2-625a1ca9d61f?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/08313e3e-5a58-433f-a4a2-625a1ca9d61f?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/08313e3e-5a58-433f-a4a2-625a1ca9d61f?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&"
                                  className={styles.borrowerInfoItemImg}
                                />
                                <div
                                  className={styles.borrowerPurchaseInfoTitle}
                                >
                                  Purchase
                                </div>
                                <div
                                  className={styles.borrowerPurchaseInfoBody}
                                >
                                  123 Main street <br />
                                  Orlando, FL 32827
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.borrowerInfoContent2}>
                          <div className={styles.borrowerInfoItem2}>
                            <div className={styles.borrowerInfoItemBg2}>
                              <div className={styles.borrowerDocumentsInfo}>
                                <img
                                  loading="lazy"
                                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/4ec68a68-5474-4e80-b077-fea366f94b38?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/4ec68a68-5474-4e80-b077-fea366f94b38?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/4ec68a68-5474-4e80-b077-fea366f94b38?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/4ec68a68-5474-4e80-b077-fea366f94b38?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/4ec68a68-5474-4e80-b077-fea366f94b38?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/4ec68a68-5474-4e80-b077-fea366f94b38?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/4ec68a68-5474-4e80-b077-fea366f94b38?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/4ec68a68-5474-4e80-b077-fea366f94b38?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&"
                                  className={styles.borrowerInfoItemImg}
                                />
                                <div className={styles.borrowerDocumentsTitle}>
                                  Documents
                                </div>
                                <div className={styles.borrowerDocumentsBody}>
                                  <span>Files </span>
                                  <span>(23)</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.borrowerDashboardInfoContent2}>
                      <div className={styles.borrowerInfo1}>
                        <div className={styles.borrowerInfoContent1}>
                          <div className={styles.borrowerInfoItem3}>
                            <div className={styles.borrowerInfoItemBg3}>
                              <div className={styles.borrowerFavoritesInfo}>
                                <img
                                  loading="lazy"
                                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/2a85cedb-3fa4-4211-83b6-edbaca9dbc6f?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/2a85cedb-3fa4-4211-83b6-edbaca9dbc6f?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/2a85cedb-3fa4-4211-83b6-edbaca9dbc6f?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/2a85cedb-3fa4-4211-83b6-edbaca9dbc6f?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/2a85cedb-3fa4-4211-83b6-edbaca9dbc6f?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/2a85cedb-3fa4-4211-83b6-edbaca9dbc6f?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/2a85cedb-3fa4-4211-83b6-edbaca9dbc6f?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/2a85cedb-3fa4-4211-83b6-edbaca9dbc6f?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&"
                                  className={styles.borrowerInfoItemImg}
                                />
                                <div className={styles.borrowerFavoritesTitle}>
                                  Favorites
                                </div>
                                <div className={styles.borrowerFavoritesBody}>
                                  <span>Files </span>
                                  <span>(13)</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className={styles.borrowerInfoContent2}>
                          <div className={styles.borrowerInfoItem4}>
                            <div className={styles.borrowerInfoItemBg4}>
                              <div className={styles.borrowerResourcesInfo}>
                                <img
                                  loading="lazy"
                                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/49cd36dc-a91f-4ac2-a8a4-bae15c0bb229?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/49cd36dc-a91f-4ac2-a8a4-bae15c0bb229?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/49cd36dc-a91f-4ac2-a8a4-bae15c0bb229?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/49cd36dc-a91f-4ac2-a8a4-bae15c0bb229?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/49cd36dc-a91f-4ac2-a8a4-bae15c0bb229?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/49cd36dc-a91f-4ac2-a8a4-bae15c0bb229?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/49cd36dc-a91f-4ac2-a8a4-bae15c0bb229?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/49cd36dc-a91f-4ac2-a8a4-bae15c0bb229?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&"
                                  className={styles.borrowerInfoItemImg}
                                />
                                <div className={styles.borrowerResourcesTitle}>
                                  Resources
                                </div>
                                <div className={styles.borrowerResourcesBody}>
                                  <span>Files </span>
                                  <span>(73)</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <img
                      loading="lazy"
                      srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/0d3b4b12-0d33-4ee9-99a0-d9bb6e68d476?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/0d3b4b12-0d33-4ee9-99a0-d9bb6e68d476?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0d3b4b12-0d33-4ee9-99a0-d9bb6e68d476?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/0d3b4b12-0d33-4ee9-99a0-d9bb6e68d476?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/0d3b4b12-0d33-4ee9-99a0-d9bb6e68d476?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0d3b4b12-0d33-4ee9-99a0-d9bb6e68d476?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/0d3b4b12-0d33-4ee9-99a0-d9bb6e68d476?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/0d3b4b12-0d33-4ee9-99a0-d9bb6e68d476?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&"
                      className={styles.demoImg}
                    />
                  </div>
                </div>
                <div className={styles.notificationCenterContainer}>
                  <div className={styles.notificationCenterContent}>
                    <div className={styles.notificationsContainer}>
                      <div className={styles.notificationsContent}>
                        <div className={styles.notificationsHeader}>
                          <div className={styles.notificationsTitle}>
                            Notifications
                          </div>
                          <img
                            loading="lazy"
                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/4cde7b13-280f-4f82-ae14-1c1a49566fea?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/4cde7b13-280f-4f82-ae14-1c1a49566fea?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/4cde7b13-280f-4f82-ae14-1c1a49566fea?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/4cde7b13-280f-4f82-ae14-1c1a49566fea?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/4cde7b13-280f-4f82-ae14-1c1a49566fea?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/4cde7b13-280f-4f82-ae14-1c1a49566fea?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/4cde7b13-280f-4f82-ae14-1c1a49566fea?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/4cde7b13-280f-4f82-ae14-1c1a49566fea?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&"
                            className={styles.notificationsHeaderImg}
                          />
                        </div>
                        <div className={styles.notificationItem}>
                          <img
                            loading="lazy"
                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/c9a16a69-ba42-4f7a-b826-f163194bc847?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/c9a16a69-ba42-4f7a-b826-f163194bc847?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c9a16a69-ba42-4f7a-b826-f163194bc847?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/c9a16a69-ba42-4f7a-b826-f163194bc847?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/c9a16a69-ba42-4f7a-b826-f163194bc847?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/c9a16a69-ba42-4f7a-b826-f163194bc847?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/c9a16a69-ba42-4f7a-b826-f163194bc847?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/c9a16a69-ba42-4f7a-b826-f163194bc847?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&"
                            className={styles.notificationItemImg}
                          />
                          <div className={styles.notificationMsg}>
                            June submitted docs
                          </div>
                        </div>
                        <div className={styles.notificationTime}>5 min ago</div>
                        <div className={styles.notificationItem2}>
                          <img
                            loading="lazy"
                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/6bf7b41b-711d-4fe3-8291-4c2e516efa0c?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/6bf7b41b-711d-4fe3-8291-4c2e516efa0c?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6bf7b41b-711d-4fe3-8291-4c2e516efa0c?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/6bf7b41b-711d-4fe3-8291-4c2e516efa0c?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/6bf7b41b-711d-4fe3-8291-4c2e516efa0c?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/6bf7b41b-711d-4fe3-8291-4c2e516efa0c?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/6bf7b41b-711d-4fe3-8291-4c2e516efa0c?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/6bf7b41b-711d-4fe3-8291-4c2e516efa0c?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&"
                            className={styles.notificationItemImg2}
                          />
                          <div className={styles.notificationMsg2}>
                            New message from Boss
                          </div>
                        </div>
                        <div className={styles.notificationTime}>
                          12 min ago
                        </div>
                        <div className={styles.notificationItem2}>
                          <img
                            loading="lazy"
                            srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/bcb5bfed-c6c6-4f29-a1b6-9b809bf68f2e?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/bcb5bfed-c6c6-4f29-a1b6-9b809bf68f2e?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/bcb5bfed-c6c6-4f29-a1b6-9b809bf68f2e?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/bcb5bfed-c6c6-4f29-a1b6-9b809bf68f2e?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/bcb5bfed-c6c6-4f29-a1b6-9b809bf68f2e?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/bcb5bfed-c6c6-4f29-a1b6-9b809bf68f2e?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/bcb5bfed-c6c6-4f29-a1b6-9b809bf68f2e?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/bcb5bfed-c6c6-4f29-a1b6-9b809bf68f2e?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&"
                            className={styles.notificationItemImg2}
                          />
                          <div className={styles.notificationItem3}>
                            <div className={styles.notificationMsg3}>
                              Your post has recieved 1,000 likes!
                            </div>
                            <div className={styles.notificationTime2}>
                              16 min ago
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.notificationCenterDivider}>
                      <div className={styles.pendingTasksContainer}>
                        <div className={styles.pendingTasksContent}>
                          <div className={styles.pendingTasksTitle}>
                            Pending Tasks
                          </div>
                          <div className={styles.pendingTaskItem}>
                            Turn in docs
                          </div>
                          <div className={styles.pendingTaskItem}>Call LO</div>
                          <div className={styles.pendingTaskItem}>
                            Walk through
                          </div>
                          <div className={styles.pendingTaskItem}>
                            Order Appraisal
                          </div>
                          <div className={styles.pendingTaskItem}>
                            Schedule Closing
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.myLoanerContainer}>
            <div className={styles.myLoanerContent}>
              <YourLoanOfficer
                name={"Krishna Malyala"}
                email={"kmalyala@nexamortgage.com"}
                phone={"334-625-9252"}
                nmls1={"NMLS #1875937"}
                states={"NJ"}
                nmls2={"NMLS #1660690"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
