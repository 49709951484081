

const unauthenticateUser = (user) => ({
    type: 'UNAUTHENTICATE_USER',
    payload: {user: user}
})

const addClientPI = (client) => ({
    type: 'ADD_CLIENT_PI',
    payload: {client: client}
})
const reset = () => ({
    type: "RESET"
})
module.exports = {
    unauthenticateUser,
    addClientPI,
    reset
}