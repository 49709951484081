import * as React from "react";
import SideMenuBar from "./SideMenuBar";
import NavBar from "./NavBar";
import styles from "./ApplyForLoan.module.css";
import YourLoanOfficer from "./YourLoanOfficer";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import actions from "../actions";
import helpers from "../helpers";

const deepClone = (obj) => {
  if (obj === null) return null;
  let clone = Object.assign({}, obj);
  Object.keys(clone).forEach(
    (key) =>
      (clone[key] =
        typeof obj[key] === "object" ? deepClone(obj[key]) : obj[key])
  );
  if (Array.isArray(obj)) {
    clone.length = obj.length;
    return Array.from(clone);
  }
  return clone;
};

export default function AFLEmploymentForm() {
  const navigate = useNavigate();

  const [employerName, setEmployerName] = useState('');
  const [position, setPosition] = useState('');
  const [yearsAtJob, setYearsAtJob] = useState('');
  const [monthsAtJob, setMonthsAtJob] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('Alabama');
  const [zip, setZip] = useState('');
  const [coborrowerSelected, setCoborrowerSelected] = useState(false);

  const [employerNameError, setEmployerNameError] = useState('');
  const [positionError, setPositionError] = useState('');
  const [yearAndMonthsAtJobError, setYearAndMonthsAtJobError] = useState('');
  const [address1Error, setAddress1Error] = useState('');
  const [address2Error, setAddress2Error] = useState('');
  const [cityError, setCityError] = useState('');
  const [zipError, setZipError] = useState('');

  const [coEmployerName, setCoEmployerName] = useState('');
  const [coPosition, setCoPosition] = useState('');
  const [coYearsAtJob, setCoYearsAtJob] = useState('');
  const [coMonthsAtJob, setCoMonthsAtJob] = useState('');
  const [coAddress1, setCoAddress1] = useState('');
  const [coAddress2, setCoAddress2] = useState('');
  const [coCity, setCoCity] = useState('');
  const [coState, setCoState] = useState('Alabama');
  const [coZip, setCoZip] = useState('');

  const [coEmployerNameError, setCoEmployerNameError] = useState('');
  const [coPositionError, setCoPositionError] = useState('');
  const [coYearAndMonthsAtJobError, setCoYearAndMonthsAtJobError] = useState('');
  const [coAddress1Error, setCoAddress1Error] = useState('');
  const [coAddress2Error, setCoAddress2Error] = useState('');
  const [coCityError, setCoCityError] = useState('');
  const [coZipError, setCoZipError] = useState('');

  const [borrowerTab, setBorrowerTab] = useState(styles.active);
  const [coBorrowerTab, setCoBorrowerTab] = useState(styles.a);
  const [borrowerDiv, setBorrowerDiv] = useState(styles.borrowerFormCardTabs);
  const [coBorrowerDiv, setCoBorrowerDiv] = useState(
    styles.borrowerFormCardTabsInvisible
  );

  const authUser = useSelector((state) => state.users);
  const dispatch = useDispatch();

  function updateBorrowerTab() {
    setBorrowerTab(styles.active);
    setCoBorrowerTab(styles.a);
    setBorrowerDiv(styles.borrowerFormCardTabs);
    setCoBorrowerDiv(styles.borrowerFormCardTabsInvisible);
  }

  function updateCoBorrowerTab() {
    setBorrowerTab(styles.a);
    setCoBorrowerTab(styles.active);
    setBorrowerDiv(styles.borrowerFormCardTabsInvisible);
    setCoBorrowerDiv(styles.borrowerFormCardTabs);
  }

  useEffect(() => {
    if(authUser[0].data.Profile.ApplyForLoanData.BorrowerNameForm) {
      setCoborrowerSelected(authUser[0].data.Profile.ApplyForLoanData.BorrowerNameForm.borrower.coborrowerSelected)
    }
    if (authUser[0].data.Profile.ApplyForLoanData.EmploymentForm) {
      if (authUser[0].data.Profile.ApplyForLoanData.EmploymentForm.borrower) {
        if (
          authUser[0].data.Profile.ApplyForLoanData.EmploymentForm.borrower.employerName
        ) {
          setEmployerName(
            authUser[0].data.Profile.ApplyForLoanData.EmploymentForm
            .borrower.employerName
          );
        }
        if (authUser[0].data.Profile.ApplyForLoanData.EmploymentForm.borrower.position) {
          setPosition(
            authUser[0].data.Profile.ApplyForLoanData.EmploymentForm.borrower.position
          );
        }
        if (authUser[0].data.Profile.ApplyForLoanData.EmploymentForm.borrower.address1) {
          setAddress1(
            authUser[0].data.Profile.ApplyForLoanData.EmploymentForm.borrower.address1
          );
        }
        if (authUser[0].data.Profile.ApplyForLoanData.EmploymentForm.borrower.address2) {
          setAddress2(
            authUser[0].data.Profile.ApplyForLoanData.EmploymentForm.borrower.address2
          );
        }
        if (authUser[0].data.Profile.ApplyForLoanData.EmploymentForm.borrower.city) {
          setCity(
            authUser[0].data.Profile.ApplyForLoanData.EmploymentForm.borrower.city
          );
        }
        if (authUser[0].data.Profile.ApplyForLoanData.EmploymentForm.borrower.zip) {
          setZip(authUser[0].data.Profile.ApplyForLoanData.EmploymentForm.borrower.zip);
        }
        if (authUser[0].data.Profile.ApplyForLoanData.EmploymentForm.borrower.yearsAtJob) {
          setYearsAtJob(authUser[0].data.Profile.ApplyForLoanData.EmploymentForm.borrower.yearsAtJob);
        }
        if (authUser[0].data.Profile.ApplyForLoanData.EmploymentForm.borrower.monthsAtJob) {
          setMonthsAtJob(authUser[0].data.Profile.ApplyForLoanData.EmploymentForm.borrower.monthsAtJob);
        }
      }
    }
  }, [authUser]);

  const handleForm = async (event) => {
    event.preventDefault()
    let errors = 0;

    //employment validation
    try {
      helpers.validString(employerName, "Employer name");
      setEmployerNameError("");
    } catch (e) {
      setEmployerNameError(e.message);
      errors++;
    }

    try {
      helpers.validString(position, "Position");
      setPositionError("");
    } catch (e) {
      setPositionError(e.message);
      errors++;
    }

    try {
      helpers.validYearandMonth(yearsAtJob, monthsAtJob);
      setYearAndMonthsAtJobError("");
    } catch (e) {
      setYearAndMonthsAtJobError(e.message);
      errors++;
    }

    try {
      helpers.validAddress(address1);
      setAddress1Error("");
    } catch (e) {
      setAddress1Error(e.message);
      errors++;
    }

    try {
      helpers.validAddress2(address2);
      setAddress2Error("");
    } catch (e) {
      setAddress2Error(e.message);
      errors++;
    }

    try {
      helpers.validCity(city);
      setCityError("");
    } catch (e) {
      setCityError(e.message);
      errors++;
    }

    try {
      helpers.validZip(zip);
      setZipError("");
    } catch (e) {
      setZipError(e.message);
      errors++;
    }

    //coborrower employment validation
    if(coborrowerSelected) {
      try {
        helpers.validString(coEmployerName, "Employer name");
        setCoEmployerNameError("");
      } catch (e) {
        setCoEmployerNameError(e.message);
        errors++;
      }
  
      try {
        helpers.validString(coPosition, "Position");
        setCoPositionError("");
      } catch (e) {
        setCoPositionError(e.message);
        errors++;
      }
  
      try {
        helpers.validYearandMonth(coYearsAtJob, coMonthsAtJob);
        setCoYearAndMonthsAtJobError("");
      } catch (e) {
        setCoYearAndMonthsAtJobError(e.message);
        errors++;
      }
  
      try {
        helpers.validAddress(coAddress1);
        setCoAddress1Error("");
      } catch (e) {
        setCoAddress1Error(e.message);
        errors++;
      }
  
      try {
        helpers.validAddress2(coAddress2);
        setCoAddress2Error("");
      } catch (e) {
        setCoAddress2Error(e.message);
        errors++;
      }
  
      try {
        helpers.validCity(coCity);
        setCoCityError("");
      } catch (e) {
        setCoCityError(e.message);
        errors++;
      }
  
      try {
        helpers.validZip(coZip);
        setCoZipError("");
      } catch (e) {
        setCoZipError(e.message);
        errors++;
      }
    }

    if (errors == 0) {
      await localStorage();
    } 
  };

  const localStorage = async () => {
    let borrower = {
      employerName: employerName,
      position: position,
      yearsAtJob: yearsAtJob,
      monthsAtJob: monthsAtJob,
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      zip: zip
    }
    let coborrower = {
      employerName: coEmployerName,
      position: coPosition,
      yearsAtJob: coYearsAtJob,
      monthsAtJob: coMonthsAtJob,
      address1: coAddress1,
      address2: coAddress2,
      city: coCity,
      state: coState,
      zip: coZip
    }
    const clientId = authUser[0].id;
    const url =
      "https://uat-api.tlcengine.com/v3/api/mlspin-md/clients/" + clientId;
    const token = authUser[0].user.AccessToken;

    let userData = await helpers.getProfile(url, token);
    let copyUserData = helpers.updateData(userData, "EmploymentForm", {borrower, coborrower});
    await helpers.updateProfile(copyUserData, url, token);

    let thisClient = deepClone(authUser[0]);
    thisClient.data = copyUserData;
    dispatch(actions.addClientPI(thisClient));

    navigate("/apply-for-loan/borrowerform7")
  }

  return (
    <div className={styles.applyScreen}>
      <NavBar />
      <div className={styles.applyContainer}>
        <div className={styles.applyContent}>
          <SideMenuBar activeClass={"applyForLoan"} />
          <div className={styles.formContainer}>
            <div className={styles.rectangleTabs} />
            <ul className={styles.tabs}>
              <li className={borrowerTab}>
                <button onClick={updateBorrowerTab}>Borrower</button>
              </li>
              <li className={coBorrowerTab}>
                <button onClick={updateCoBorrowerTab}>Co-Borrower</button>
              </li>
            </ul>

            <div id="borrower" className={borrowerDiv}>
              <div className={styles.borrowerFormCardDiv}>
                <div className={styles.borrowerFormCardTitleText}>
                  <div className={styles.borrowerFormTitle}>
                    Borrower Employment
                  </div>
                </div>
                <div>
                  <div className={styles.inputsContainer}>
                  <div className={styles.errorAndInputDiv}>
                        {employerNameError != "" && (<div className={styles.errorDiv}>{employerNameError}</div> )}
                    <div className={styles.inputDiv}>
                      <input
                        onChange={(e) => setEmployerName(e.target.value)}
                        name="employerNameInput"
                        type="text"
                        className={styles.formInput}
                        placeholder={"Employer Name"}
                        value={employerName}
                      />
                    </div>
                    </div>
                    <div className={styles.errorAndInputDiv}>
                        {positionError != "" && (<div className={styles.errorDiv}>{positionError}</div> )}
                    <div className={styles.inputDiv}>
                      <input
                        onChange={(e) => setPosition(e.target.value)}
                        name="position"
                        type="text"
                        className={styles.formInput}
                        placeholder={"Position/Title"}
                        value={position}
                      />
                    </div>
                    </div>
                  </div>
                  <div className={styles.errorAndInputDiv}>
                        {yearAndMonthsAtJobError != "" && (<div className={styles.errorDiv}>{yearAndMonthsAtJobError}</div>)}
                  <br/>
                  <div className={styles.jobLabel}>
                          How long on this job?
                  </div>
                  <div className={styles.inputsContainer}>
                    
                      <div className={styles.yearsAtAddressDiv}>
                          <div className={styles.dropdown}>
                              <select className={styles.selectBox}
                                  value={yearsAtJob}
                                  onChange={(e) => setYearsAtJob(e.target.value)}
                              >
                                  <option>Years</option>
                                  <option>One</option>
                                  <option>Two</option>
                                  <option>Three</option>
                                  <option>Four</option>
                              </select>
                          </div>
                      </div>
                     
                      <div className={styles.yearsAtAddressDiv}>
                          <div className={styles.dropdown}>
                              <select className={styles.selectBox}
                                  value={monthsAtJob}
                                  onChange={(e) => setMonthsAtJob(e.target.value)}
                              >
                                  <option>Months</option>
                                  <option>One</option>
                                  <option>Two</option>
                                  <option>Three</option>
                                  <option>Four</option>
                                  <option>Five</option>
                                  <option>Six</option>
                                  <option>Seven</option>
                                  <option>Eight</option>
                                  <option>Nine</option>
                                  <option>Ten</option>
                                  <option>Eleven</option>
                                  <option>Twelve</option>
                              </select>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.inputsContainer}>
                  <div className={styles.errorAndInputDiv}>
                        {address1Error != "" && (<div className={styles.errorDiv}>{address1Error}</div>)}
                    <div className={styles.inputDiv}>
                      <input
                        onChange={(e) => setAddress1(e.target.value)}
                        name="addressInput"
                        type="text"
                        className={styles.formInput}
                        placeholder={"Street Address"}
                        value={address1}
                      />
                    </div>
                    </div>
                    <div className={styles.errorAndInputDiv}>
                        {address2Error != "" && (<div className={styles.errorDiv}>{address2Error}</div>)}
                    <div className={styles.inputDiv}>
                      <input
                        onChange={(e) => setAddress2(e.target.value)}
                        name="secondAddressInput"
                        type="text"
                        className={styles.formInput}
                        placeholder={"Street Address Second Line"}
                        value={address2}
                      />
                    </div>
                    </div>
                  </div>
                  <div className={styles.inputsContainer}>
                  <div className={styles.errorAndInputDiv}>
                        {cityError != "" && (<div className={styles.errorDiv}>{cityError}</div>)}
                    <div className={styles.inputDiv}>
                      <input
                        onChange={(e) => setCity(e.target.value)}
                        name="cityInput"
                        type="text"
                        className={styles.formInput}
                        placeholder={"City"}
                        value={city}
                      />
                    </div>
                    </div>
                    <div className={styles.inputDiv}>
                      <div className={styles.dropdown}>
                        <select
                          className={styles.selectBox}
                          onChange={(e) => setState(e.target.value)}
                        >
                          <option>Alabama</option>
                          <option>Alaska</option>
                          <option>Arizona</option>
                          <option>Arkansas</option>
                          <option>California</option>
                          <option>Colorado</option>
                          <option>Connecticut</option>
                          <option>Deleware</option>
                          <option>Florida</option>
                          <option>Georgia</option>
                          <option>Hawaii</option>
                          <option>Idaho</option>
                          <option>Illinois</option>
                          <option>Indiana</option>
                          <option>Iowa</option>
                          <option>Kansas</option>
                          <option>Kentucky</option>
                          <option>Louisiana</option>
                          <option>Maine</option>
                          <option>Maryland</option>
                          <option>Massachusetts</option>
                          <option>Michigan</option>
                          <option>Minnesota</option>
                          <option>Mississippi</option>
                          <option>Missouri</option>
                          <option>Montana</option>
                          <option>Nebraska</option>
                          <option>Nevada</option>
                          <option>New Hampshire</option>
                          <option>New Jersey</option>
                          <option>New Mexico</option>
                          <option>New York</option>
                          <option>North Carolina</option>
                          <option>North Dakota</option>
                          <option>Ohio</option>
                          <option>Oklahoma</option>
                          <option>Oregon</option>
                          <option>Pennsylvania</option>
                          <option>Rhode Island</option>
                          <option>South Carolina</option>
                          <option>South Dakota</option>
                          <option>Tennessee</option>
                          <option>Texas</option>
                          <option>Utah</option>
                          <option>Vermont</option>
                          <option>Virginia</option>
                          <option>Washington</option>
                          <option>West Virginia</option>
                          <option>Wisconsin</option>
                          <option>Wyoming</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className={styles.inputsContainer}>
                  <div className={styles.errorAndInputDiv}>
                        {zipError != "" && (<div className={styles.errorDiv}>{zipError}</div>)}
                    <div className={styles.inputDiv}>
                      <input
                          onChange={(e) => setZip(e.target.value)}
                          name="zipCodeInput"
                          type="text"
                          className={styles.formInput}
                          placeholder={"Zip Code"}
                          value={zip}
                      />
                    </div>
                </div>
              </div>
              </div>
            </div>
            <div className={styles.nextButtonDiv}>
                <button className={styles.backButton} onClick={()=>navigate("/apply-for-loan/borrowerform5")}>Back</button>
                <button className={styles.nextButton} onClick={handleForm}>Next</button>
            </div>
          </div>


          <div id="coborrower" className={coBorrowerDiv} >
          <div className={styles.borrowerFormCardDiv}>
              <div className={styles.borrowerFormCardTitleText}>
                <div className={styles.borrowerFormTitle}>
                  Co-Borrower Employment
                </div>
              </div>
              <div >
                <div className={styles.inputsContainer}>
                <div className={styles.errorAndInputDiv}>
                        {coEmployerNameError != "" && (<div className={styles.errorDiv}>{coEmployerNameError}</div> )}
                  <div className={styles.inputDiv}>
                    <input
                        onChange={(e) => setCoEmployerName(e.target.value)}
                        name="employerNameInput"
                        type="text"
                        className={styles.formInput}
                        placeholder={"Employer Name"}
                    />
                  </div>
                  </div>
                  <div className={styles.errorAndInputDiv}>
                        {coPositionError != "" && (<div className={styles.errorDiv}>{coPositionError}</div> )}
                  <div className={styles.inputDiv}>
                    <input
                        onChange={(e) => setCoPosition(e.target.value)}
                        name="position"
                        type="text"
                        className={styles.formInput}
                        placeholder={"Position/Title"}
                    />
                  </div>
                  </div>
                </div>
                <div className={styles.errorAndInputDiv}>
                        {coYearAndMonthsAtJobError != "" && (<div className={styles.errorDiv}>{coYearAndMonthsAtJobError}</div>)}
                  <br/>
                <div className={styles.jobLabel}>
                        How long on this job?
                </div>
                <div className={styles.inputsContainer}>
                    <div className={styles.yearsAtAddressDiv}>
                      <div className={styles.dropdown}>
                        <select
                          className={styles.selectBox}
                          onChange={(e) => setCoYearsAtJob(e.target.value)}
                        >
                          <option>Years</option>
                          <option>One</option>
                          <option>Two</option>
                          <option>Three</option>
                          <option>Four</option>
                        </select>
                      </div>
                    </div>
                    <div className={styles.yearsAtAddressDiv}>
                        <div className={styles.dropdown}>
                            <select className={styles.selectBox}
                                onChange={(e) => setCoMonthsAtJob(e.target.value)}
                            >
                                <option>Months</option>
                                <option>One</option>
                                <option>Two</option>
                                <option>Three</option>
                                <option>Four</option>
                                <option>Five</option>
                                <option>Six</option>
                                <option>Seven</option>
                                <option>Eight</option>
                                <option>Nine</option>
                                <option>Ten</option>
                                <option>Eleven</option>
                                <option>Twelve</option>
                            </select>
                        </div>
                    </div>
                </div>
                </div>
                <div className={styles.inputsContainer}>
                <div className={styles.errorAndInputDiv}>
                        {coAddress1Error != "" && (<div className={styles.errorDiv}>{coAddress1Error}</div>)}
                  <div className={styles.inputDiv}>
                    <input
                        onChange={(e) => setCoAddress1(e.target.value)}
                        name="addressInput"
                        type="text"
                        className={styles.formInput}
                        placeholder={"Street Address"}
                    />
                  </div>
                  </div>
                  <div className={styles.errorAndInputDiv}>
                        {coAddress2Error != "" && (<div className={styles.errorDiv}>{coAddress2Error}</div>)}
                  <div className={styles.inputDiv}>
                    <input
                        onChange={(e) => setCoAddress2(e.target.value)}
                        name="secondAddressInput"
                        type="text"
                        className={styles.formInput}
                        placeholder={"Street Address Second Line"}
                    />
                  </div>
                  </div>
                </div>
                <div className={styles.inputsContainer}>
                <div className={styles.errorAndInputDiv}>
                        {coCityError != "" && (<div className={styles.errorDiv}>{coCityError}</div>)}
                  <div className={styles.inputDiv}>
                    <input
                        onChange={(e) => setCoCity(e.target.value)}
                        name="cityInput"
                        type="text"
                        className={styles.formInput}
                        placeholder={"City"}
                    />
                  </div>
                  </div>
                  <div className={styles.inputDiv}>
                    <div className={styles.dropdown}>
                        <select className={styles.selectBox}
                            onChange={(e) => setCoState(e.target.value)}
                        >
                          <option>Alabama</option>
                          <option>Alaska</option>
                          <option>Arizona</option>
                          <option>Arkansas</option>
                          <option>California</option>
                          <option>Colorado</option>
                          <option>Connecticut</option>
                          <option>Deleware</option>
                          <option>Florida</option>
                          <option>Georgia</option>
                          <option>Hawaii</option>
                          <option>Idaho</option>
                          <option>Illinois</option>
                          <option>Indiana</option>
                          <option>Iowa</option>
                          <option>Kansas</option>
                          <option>Kentucky</option>
                          <option>Louisiana</option>
                          <option>Maine</option>
                          <option>Maryland</option>
                          <option>Massachusetts</option>
                          <option>Michigan</option>
                          <option>Minnesota</option>
                          <option>Mississippi</option>
                          <option>Missouri</option>
                          <option>Montana</option>
                          <option>Nebraska</option>
                          <option>Nevada</option>
                          <option>New Hampshire</option>
                          <option>New Jersey</option>
                          <option>New Mexico</option>
                          <option>New York</option>
                          <option>North Carolina</option>
                          <option>North Dakota</option>
                          <option>Ohio</option>
                          <option>Oklahoma</option>
                          <option>Oregon</option>
                          <option>Pennsylvania</option>
                          <option>Rhode Island</option>
                          <option>South Carolina</option>
                          <option>South Dakota</option>
                          <option>Tennessee</option>
                          <option>Texas</option>
                          <option>Utah</option>
                          <option>Vermont</option>
                          <option>Virginia</option>
                          <option>Washington</option>
                          <option>West Virginia</option>
                          <option>Wisconsin</option>
                          <option>Wyoming</option>
                        </select>
                    </div>
                  </div>
                </div>
                </div>
                <div className={styles.inputsContainer}>
                <div className={styles.errorAndInputDiv}>
                        {coZipError != "" && (<div className={styles.errorDiv}>{coZipError}</div>)}
                  <div className={styles.inputDiv}>
                    <input
                      onChange={(e) => setCoZip(e.target.value)}
                      name="zipCodeInput"
                      type="text"
                      className={styles.formInput}
                      placeholder={"Postal Code"}
                    />
                  </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.myLoanerContainer}>
             <div className={styles.myLoanerContent}>
              <YourLoanOfficer
                name={"Krishna Malyala"}
                email={"kmalyala@nexamortgage.com"}
                phone={"334-625-9252"}
                nmls1={"NMLS #1875937"}
                states={"NJ"}
                nmls2={"NMLS #1660690"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
