import * as React from "react";
import styles from "./UserAuthPages.module.css";
import { useCallback } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import YourLoanOfficer from "./YourLoanOfficer";
import NavBar from './NavBar';
import axios from "axios";

export default function SignUp() {
    const [passwordInputType, setPasswordInputType] = useState("password");
    const [eyeConImg, setEyeConImg] = useState("/eyeIcon.svg");
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const onEyeConClick = useCallback(() => {
        if (passwordInputType === "password") {
            setPasswordInputType("text");
            setEyeConImg("/closedEye.svg");
        } else {
            setPasswordInputType("password");
            setEyeConImg("/eyeIcon.svg");
        }
    });

    const handleForm = async (event) => {
        event.preventDefault()
    
        const { result, error } = await signUp(email, password);
    
        if (error) {
            return console.log(error)
        }
    
        // else successful
        console.log(result)
      }
    
      async function signUp(email, password) {
        let result = null,
            error = null;
        try {
            const {data} = await axios.post("https://uat-api.tlcengine.com/v3/api/mlspin-md/Base_URL/agents/31e2c293-4aa0-46d5-828b-aa9356fedfd6/clients",
                {
                    Firstname: firstname,
                    LastName: lastname,
                    Email: email,
                    CellPhone: phone,
                }
            );
            console.log(data)
        } catch (e) {
            alert("Invalid username or password")
        }
    
        var form = document.getElementById("signInForm");
        form.reset();
    
        return { result, error };
      }

  return (
    <div className={styles.temp}
    >
      <NavBar/>
      <div className={styles.temp14}
      >
        <div className={styles.temp15}
        >
          <div className={styles.loanOfficer}>
          <div className={styles.loanOfficerCard}>
            <YourLoanOfficer
              name={"Krishna Malyala"}
              email={"kmalyala@nexamortgage.com"}
              phone={"334-625-9252"}
              nmls1={"NMLS #1875937"}
              states={"NJ"}
              nmls2={"NMLS #1660690"}
            />
            </div>
          </div>
        <div className={styles.temp20}
        >
            <div className={styles.forgotPasswordCard}
            >
                <div className={styles.temp21}
                >
                    <div className={styles.temp22}
                    >
                    <div className={styles.forgotPassword}
                    >
                        Create Account
                    </div>
                    <form onSubmit={handleForm} id="signInForm">
                    <div className={styles.loanOfficerInfo}
                    >
                        <span>Already have an account? </span>
                        <Link to="/sign-in" className={styles.link}>Sign In</Link>
                    </div>
                    <div className={styles.email} htmlFor="pswdInput">
                        Choose your language
                    </div>
                    <div className={styles.inputDiv}>
                        <div className={styles.dropdown}>
                            <select className={styles.selectBox}>
                                <option>English</option>
                                <option>French</option>
                                <option>Spanish</option>
                            </select>
                        </div>
                    </div>
                    <div className={styles.email}>
                        First Name
                    </div>
                    <div className={styles.inputDiv}>
                        <input
                            onChange={(e) => setFirstname(e.target.value)}
                            name="emailInput"
                            type="text"
                            className={styles.signInInput}
                        />
                    </div>
                    <div className={styles.email}>
                        Last Name
                    </div>
                    <div className={styles.inputDiv}>
                        <input
                            onChange={(e) => setLastname(e.target.value)}
                            name="emailInput"
                            type="text"
                            className={styles.signInInput}
                        />
                    </div>
                    <div className={styles.email}>
                        Phone
                    </div>
                    <div className={styles.inputDiv}>
                        <input
                            onChange={(e) => setPhone(e.target.value)}
                            name="phoneInput"
                            type="text"
                            className={styles.signInInput}
                        />
                    </div>
                    <div className={styles.email}>
                        Email
                    </div>
                    <div className={styles.inputDiv}>
                        <input
                            onChange={(e) => setEmail(e.target.value)}
                            name="emailInput"
                            type="text"
                            className={styles.signInInput}
                        />
                    </div>
                    <div className={styles.email}>
                        Password
                    </div>
                    <div className={styles.pswdInputField}>
                    <div className={styles.inputDiv}>
                    <input
                        onChange={(e) => setPassword(e.target.value)}
                        name="pswdInput"
                        type={passwordInputType}
                        className={styles.signInInput}
                    />
                    <img
                        className={styles.eyeIcon}
                        alt=""
                        src={eyeConImg}
                        onClick={onEyeConClick}
                    />
                    <br/>
                    
                    </div>
                </div>
                <div className={styles.btnDiv}>
                    <button className={styles.signInBtn} type="submit">Sign Up</button>
                </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
  );
}