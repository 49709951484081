import * as React from "react";
import SideMenuBar from "./SideMenuBar";
import NavBar from "./NavBar";
import styles from "./ApplyForLoan.module.css";
import YourLoanOfficer from "./YourLoanOfficer";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import actions from "../actions";
import helpers from "../helpers";

const deepClone = (obj) => {
  if (obj === null) return null;
  let clone = Object.assign({}, obj);
  Object.keys(clone).forEach(
    (key) =>
      (clone[key] =
        typeof obj[key] === "object" ? deepClone(obj[key]) : obj[key])
  );
  if (Array.isArray(obj)) {
    clone.length = obj.length;
    return Array.from(clone);
  }
  return clone;
};

export default function AFLDownPaymentForm() {
  const navigate = useNavigate();

  const [downPayementSource, setDownPaymentSource] = useState("");

  const [downPayementSourceError, setDownPaymentSourceError] = useState("");

  const authUser = useSelector((state) => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    if (authUser[0].data.Profile.ApplyForLoanData.DownPaymentForm) {
      if (
        authUser[0].data.Profile.ApplyForLoanData.DownPaymentForm
          .downPayementSource
      ) {
        setDownPaymentSource(
          authUser[0].data.Profile.ApplyForLoanData.DownPaymentForm
            .downPayementSource
        );
      }
    }
  }, [authUser]);

  const handleForm = async (event) => {
    event.preventDefault();
    let errors = 0;

    try {
      helpers.validDownPaymentSource(downPayementSource);
      setDownPaymentSourceError("");
    } catch (e) {
      setDownPaymentSourceError(e.message);
      errors++;
    }

    var data = {
      downPayementSource: downPayementSource,
    };

    console.log(data);

    if (errors == 0) {
      await localStorage();
    } else {
      if (errors == 0) {
        await localStorage();
      }
    }
  };

  const localStorage = async () => {
    let data = {
      downPayementSource: downPayementSource,
    };

    const clientId = authUser[0].id;
    const url =
      "https://uat-api.tlcengine.com/v3/api/mlspin-md/clients/" + clientId;
    const token = authUser[0].user.AccessToken;

    let userData = await helpers.getProfile(url, token);
    let copyUserData = helpers.updateData(userData, "DownPaymentForm", data);

    await helpers.updateProfile(copyUserData, url, token);

    let thisClient = deepClone(authUser[0]);
    thisClient.data = copyUserData;
    dispatch(actions.addClientPI(thisClient));

    navigate("/apply-for-loan/borrowerform6");
  };

  return (
    <div className={styles.applyScreen}>
      <NavBar />
      <div className={styles.applyContainer}>
        <div className={styles.applyContent}>
          <SideMenuBar activeClass={"applyForLoan"} />
          <div className={styles.formContainer}>
            <div className={styles.rectangle} />
            <div className={styles.borrowerFormCard}>
              <div className={styles.borrowerFormCardDiv}>
                <div className={styles.borrowerFormCardTitleText}>
                  <div className={styles.borrowerFormTitle}>
                    Source of Down Payment
                  </div>
                </div>
                <div>
                  <div className={styles.inputsContainer}>
                    <div className={styles.ownerRadioButtons}>
                      <div className={styles.addressRadioButtons}>
                        <div className={styles.errorAndInputDiv}>
                          {downPayementSourceError != "" && (
                            <div className={styles.errorDiv}>
                              {downPayementSourceError}
                            </div>
                          )}
                          <br />
                          <div className={styles.useAddressDiv}>
                            What will be the source of you down payment?
                          </div>
                          <div className={styles.coborrowerRadio}>
                            <input
                              type="radio"
                              id="checking"
                              name="source"
                              checked={downPayementSource == "savings"}
                              onChange={(e) => setDownPaymentSource("savings")}
                            />
                            <label>Checking or savings account</label>
                          </div>
                          <br />
                          <div className={styles.coborrowerRadio}>
                            <input
                              type="radio"
                              id="equity"
                              name="source"
                              checked={downPayementSource == "equity"}
                              onChange={(e) => setDownPaymentSource("equity")}
                            />
                            <label>Equity from my property sale</label>
                          </div>
                          <br />
                          <div className={styles.coborrowerRadio}>
                            <input
                              type="radio"
                              id="gift"
                              name="source"
                              checked={downPayementSource == "gift"}
                              onChange={(e) => setDownPaymentSource("gift")}
                            />
                            <label>Gift</label>
                          </div>
                          <br />
                          <div className={styles.coborrowerRadio}>
                            <input
                              type="radio"
                              id="loan"
                              name="source"
                              checked={downPayementSource == "loan"}
                              onChange={(e) => setDownPaymentSource("loan")}
                            />
                            <label>Loan secured against my property</label>
                          </div>
                          <br />
                          <div className={styles.coborrowerRadio}>
                            <input
                              type="radio"
                              id="investment"
                              name="source"
                              checked={downPayementSource == "investment"}
                              onChange={(e) =>
                                setDownPaymentSource("investment")
                              }
                            />
                            <label>Funds from investment accounts</label>
                          </div>
                          <br />
                          <div className={styles.coborrowerRadio}>
                            <input
                              type="radio"
                              id="retirement"
                              name="source"
                              checked={downPayementSource == "retirement"}
                              onChange={(e) =>
                                setDownPaymentSource("retirement")
                              }
                            />
                            <label>Funds from retirement accounts</label>
                          </div>
                          <br />
                          <div className={styles.coborrowerRadio}>
                            <input
                              type="radio"
                              id="other"
                              name="source"
                              checked={downPayementSource == "other"}
                              onChange={(e) => setDownPaymentSource("other")}
                            />
                            <label>Other</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.nextButtonDiv}>
                <button
                  className={styles.backButton}
                  onClick={() => navigate("/apply-for-loan/borrowerform4")}
                >
                  Back
                </button>
                <button className={styles.nextButton} onClick={handleForm}>
                  Next
                </button>
              </div>
            </div>
          </div>
          <div className={styles.myLoanerContainer}>
            <div className={styles.myLoanerContent}>
              <YourLoanOfficer
                name={"Krishna Malyala"}
                email={"kmalyala@nexamortgage.com"}
                phone={"334-625-9252"}
                nmls1={"NMLS #1875937"}
                states={"NJ"}
                nmls2={"NMLS #1660690"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
