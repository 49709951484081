import * as React from "react";
import SideMenuBar from "./SideMenuBar";
import NavBar from './NavBar';
import styles from './ApplyForLoan.module.css'
import YourLoanOfficer from "./YourLoanOfficer";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import actions from "../actions";
import helpers from "../helpers";

const deepClone = obj => {
  if (obj === null) return null;
  let clone = Object.assign({}, obj);
  Object.keys(clone).forEach(
    key =>
      (clone[key] =
        typeof obj[key] === 'object' ? deepClone(obj[key]) : obj[key])
  );
  if (Array.isArray(obj)) {
    clone.length = obj.length;
    return Array.from(clone);
  }
  return clone;
};

export default function AFLGovtInfoForm2() {
  const navigate = useNavigate(); 

  const [sex, setSex] = useState('');
  const [coSex, setCoSex] = useState('');
  
  const [borrowerTab, setBorrowerTab] = useState(styles.active);
  const [coBorrowerTab, setCoBorrowerTab] = useState(styles.a);
  const [borrowerDiv, setBorrowerDiv] = useState(styles.borrowerFormCardTabs);
  const [coBorrowerDiv, setCoBorrowerDiv] = useState(styles.borrowerFormCardTabsInvisible);
  
  const authUser = useSelector((state) => state.users);
  const dispatch = useDispatch();

  function updateBorrowerTab() {
    setBorrowerTab(styles.active)
    setCoBorrowerTab(styles.a)
    setBorrowerDiv(styles.borrowerFormCardTabs)
    setCoBorrowerDiv(styles.borrowerFormCardTabsInvisible)
  }

  function updateCoBorrowerTab() {
    setBorrowerTab(styles.a)
    setCoBorrowerTab(styles.active)
    setBorrowerDiv(styles.borrowerFormCardTabsInvisible)
    setCoBorrowerDiv(styles.borrowerFormCardTabs)
  }

  useEffect(() => {
    if(authUser[0].data.Profile.ApplyForLoanData.GovernmentSexInfoForm) {
      if(authUser[0].data.Profile.ApplyForLoanData.GovernmentSexInfoForm.borrower) {
          if(authUser[0].data.Profile.ApplyForLoanData.GovernmentSexInfoForm.borrower.sex) {
              setSex(authUser[0].data.Profile.ApplyForLoanData.GovernmentSexInfoForm.borrower.sex)
          }
      }
    }
  }, authUser)

  const handleForm = async (event) => {
    event.preventDefault()

    await localStorage();
    navigate("/apply-for-loan/borrowerform15")
  };

  const localStorage = async () => {
    let borrower = {
      sex: sex,
    }

    const clientId = authUser[0].id;
    const url = "https://uat-api.tlcengine.com/v3/api/mlspin-md/clients/" + clientId;
    const token = authUser[0].user.AccessToken

    let userData = await helpers.getProfile(url, token);
    let copyUserData = helpers.updateData(userData, "GovernmentSexInfoForm", {borrower});
    await helpers.updateProfile(copyUserData, url, token);
    
    let thisClient = deepClone(authUser[0]);
    thisClient.data = copyUserData;
    dispatch(actions.addClientPI(thisClient));
  }

  return (
    <div className={styles.applyScreen}>
      <NavBar/>
      <div className={styles.applyContainer}>
        <div className={styles.applyContent}>
          <SideMenuBar activeClass={"applyForLoan"}/>
          <div className={styles.formContainer}>
          <div className={styles.rectangleTabs}/>
            <ul className={styles.tabs}>
              <li className={borrowerTab}><button onClick={updateBorrowerTab}>Borrower</button></li> 
              <li className={coBorrowerTab}><button onClick={updateCoBorrowerTab}>Co-Borrower</button></li> 
            </ul>

            <div id="borrower" className={borrowerDiv}>
              <div className={styles.borrowerFormCardDiv}>
                <div className={styles.borrowerFormCardTitleText}>
                  <div className={styles.borrowerFormTitle}>
                    Borrower info for Government
                  </div>
                </div>
                <div >
                  <div className={styles.inputsContainer}>
                      <div className={styles.coborrowerRadio}>
                          <div className={styles.declarationsDiv}>
                              <div className={styles.addressRadioButtons}>
                                  <div className={styles.labelEthnicity}>
                                      Borrower Sex
                                  </div>
                                  <br/>
                                  <div className={styles.coborrowerRadio}>
                                      <input type="radio" id="primaryResidence" name="sex" checked={sex=="Not Provided"} onChange={(e) => setSex("Not Provided")}/>
                                      <label>I do not wish to provide this information</label>
                                  </div>
                                  <br/>
                                  <div className={styles.coborrowerRadio}>
                                      <input type="radio" id="primaryResidence" name="sex" checked={sex=="Female"} onChange={(e) => setSex("Female")}/>
                                      <label>Female</label>
                                  </div>
                                  <br/>
                                  <div className={styles.coborrowerRadio}>
                                      <input type="radio" id="primaryResidence" name="sex" checked={sex=="Transgender"} onChange={(e) => setSex("Transgender")}/>
                                      <label>Transgender</label>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className={styles.coborrowerRadio}>
                          <div className={styles.declarationsDiv}>
                              <div className={styles.addressRadioButtons}>
                                <div className={styles.blankSpace17}/>
                                  <br/>
                                  <div className={styles.coborrowerRadio}>
                                      <input type="radio" id="primaryResidence" name="sex" checked={sex=="Male"} onChange={(e) => setSex("Male")}/>
                                      <label>Male</label>
                                  </div>
                                  <br/>
                                  <div className={styles.coborrowerRadio}>
                                      <input type="radio" id="primaryResidence" name="sex" checked={sex=="Non-binary"} onChange={(e) => setSex("Non-binary")}/>
                                      <label>Non-binary</label>
                                  </div>
                                  <br/>
                                  <div className={styles.coborrowerRadio}>
                                      <input type="radio" id="primaryResidence" name="sex" checked={sex!="Male" && sex!= "Non-binary" && sex!="Transgender" && sex!="Female" && sex!="Not Provided" && sex!==""} onChange={(e) => setSex("Other")}/>
                                      <label>Other</label>
                                  </div>
                                  <div className={styles.inputDivEthnicity}>
                                    <input
                                        onChange={(e) => setSex(e.target.value)}
                                        name="otherInput"
                                        type="text"
                                        value={(sex!="Male" && sex!= "Non-binary" && sex!="Transgender" && sex!="Female" && sex!="Not Provided") ? sex : ""}
                                        className={styles.formInputEthnicity}
                                        placeholder={"Other"}
                                    />
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div>
                </div>
              </div>
            </div>
            <div className={styles.nextButtonDiv}>
              <button className={styles.backButton} onClick={()=>navigate("/apply-for-loan/borrowerform13")}>Back</button>
              <button className={styles.nextButton} onClick={handleForm}>Next</button>
            </div>
          </div>

          <div id="coborrower" className={coBorrowerDiv}>
            <div className={styles.borrowerFormCardDiv}>
                <div className={styles.borrowerFormCardTitleText}>
                  <div className={styles.borrowerFormTitle}>
                    Co-Borrower info for Government
                  </div>
                </div>
                <div >
                  <div className={styles.inputsContainer}>
                      <div className={styles.coborrowerRadio}>
                          <div className={styles.declarationsDiv}>
                              <div className={styles.addressRadioButtons}>
                                  <div className={styles.labelEthnicity}>
                                      Borrower Sex
                                  </div>
                                  <br/>
                                  <div className={styles.coborrowerRadio}>
                                      <input type="radio" id="primaryResidence" name="sex" onChange={(e) => setCoSex("notProvided")}/>
                                      <label>I do not wish to provide this information</label>
                                  </div>
                                  <br/>
                                  <div className={styles.coborrowerRadio}>
                                      <input type="radio" id="primaryResidence" name="sex" onChange={(e) => setCoSex("Female")}/>
                                      <label>Female</label>
                                  </div>
                                  <br/>
                                  <div className={styles.coborrowerRadio}>
                                      <input type="radio" id="primaryResidence" name="sex" onChange={(e) => setCoSex("Transgender")}/>
                                      <label>Transgender</label>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className={styles.coborrowerRadio}>
                          <div className={styles.declarationsDiv}>
                              <div className={styles.addressRadioButtons}>
                                <div className={styles.blankSpace17}/>
                                  <br/>
                                  <div className={styles.coborrowerRadio}>
                                      <input type="radio" id="primaryResidence" name="sex" onChange={(e) => setCoSex("Male")}/>
                                      <label>Male</label>
                                  </div>
                                  <br/>
                                  <div className={styles.coborrowerRadio}>
                                      <input type="radio" id="primaryResidence" name="sex" onChange={(e) => setCoSex("Non-binary")}/>
                                      <label>Non-binary</label>
                                  </div>
                                  <br/>
                                  <div className={styles.coborrowerRadio}>
                                      <input type="radio" id="primaryResidence" name="sex" onChange={(e) => setCoSex("Other")}/>
                                      <label>Other</label>
                                  </div>
                                  <div className={styles.inputDivEthnicity}>
                                    <input
                                        onChange={(e) => setCoSex(e.target)}
                                        name="otherInput"
                                        type="text"
                                        className={styles.formInputEthnicity}
                                        placeholder={"Other"}
                                    />
                                </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <div>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className={styles.myLoanerContainer}>
             <div className={styles.myLoanerContent}>
              <YourLoanOfficer
                name={"Krishna Malyala"}
                email={"kmalyala@nexamortgage.com"}
                phone={"334-625-9252"}
                nmls1={"NMLS #1875937"}
                states={"NJ"}
                nmls2={"NMLS #1660690"}
              />
            </div>      
            
          </div>
      </div>
    </div>
  </div>
  );
}

