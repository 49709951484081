import * as React from "react";
import SideMenuBar from "./SideMenuBar";
import NavBar from './NavBar';
import styles from './ApplyForLoan.module.css'
import YourLoanOfficer from "./YourLoanOfficer";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import actions from "../actions";
import helpers from "../helpers";

const deepClone = obj => {
  if (obj === null) return null;
  let clone = Object.assign({}, obj);
  Object.keys(clone).forEach(
    key =>
      (clone[key] =
        typeof obj[key] === 'object' ? deepClone(obj[key]) : obj[key])
  );
  if (Array.isArray(obj)) {
    clone.length = obj.length;
    return Array.from(clone);
  }
  return clone;
};

export default function AFLliabilitiesForm() {
  const navigate = useNavigate(); 

  const [coborrowerSelected, setCoborrowerSelected] = useState(false);

  const [companyName, setCompanyName] = useState('');
  const [liabilityType, setLiabilityType] = useState('');
  const [monthlyPayment, setMonthlyPayment] = useState('');
  const [unpaidBalance, setUnpaidBalance] = useState('');
  const [monthsToPay, setMonthsToPay] = useState('');

  const [liabilitiesInputs, setLiabilitiesInputs] = useState([]);
  const [coLiabilitiesInputs, setCoLiabilitiesInputs] = useState([]);

  const [oldInputs, setOldInputs] = useState(false);
  const [coOldInputs, setCoOldInputs] = useState(false);

  const [liabilitiesInputsError, setLiabilitiesInputsError] = useState("");
  const [coLiabilitiesInputsError, setCoLiabilitiesInputsError] = useState("");

  const [companyNameError, setCompanyNameError] = useState('');
  const [liabilityTypeError, setLiabilityTypeError] = useState('');
  const [monthlyPaymentError, setMonthlyPaymentError] = useState('');
  const [unpaidBalanceError, setUnpaidBalanceError] = useState('');
  const [monthsToPayError, setMonthsToPayError] = useState('');

  const [coCompanyNameError, setCoCompanyNameError] = useState('');
  const [coLiabilityTypeError, setCoLiabilityTypeError] = useState('');
  const [coMonthlyPaymentError, setCoMonthlyPaymentError] = useState('');
  const [coUnpaidBalanceError, setCoUnpaidBalanceError] = useState('');
  const [coMonthsToPayError, setCoMonthsToPayError] = useState('');

  const [coCompanyName, setCoCompanyName] = useState('');
  const [coLiabilityType, setCoLiabilityType] = useState('');
  const [coMonthlyPayment, setCoMonthlyPayment] = useState('');
  const [coUnpaidBalance, setCoUnpaidBalance] = useState('');
  const [coMonthsToPay, setCoMonthsToPay] = useState('');

  const [borrowerTab, setBorrowerTab] = useState(styles.active);
  const [coBorrowerTab, setCoBorrowerTab] = useState(styles.a);
  const [borrowerDiv, setBorrowerDiv] = useState(styles.borrowerFormCardTabs);
  const [coBorrowerDiv, setCoBorrowerDiv] = useState(styles.borrowerFormCardTabsInvisible);

  const authUser = useSelector((state) => state.users);
  const dispatch = useDispatch();

  function updateBorrowerTab() {
    setBorrowerTab(styles.active)
    setCoBorrowerTab(styles.a)
    setBorrowerDiv(styles.borrowerFormCardTabs)
    setCoBorrowerDiv(styles.borrowerFormCardTabsInvisible)
  }

  function updateCoBorrowerTab() {
    setBorrowerTab(styles.a)
    setCoBorrowerTab(styles.active)
    setBorrowerDiv(styles.borrowerFormCardTabsInvisible)
    setCoBorrowerDiv(styles.borrowerFormCardTabs)
  }

  useEffect(() => {
    if(authUser[0].data.Profile.ApplyForLoanData.BorrowerNameForm) {
      setCoborrowerSelected(authUser[0].data.Profile.ApplyForLoanData.BorrowerNameForm.borrower.coborrowerSelected)
    }
    if(authUser[0].data.Profile.ApplyForLoanData.LiabilitiesForm) {
      if(authUser[0].data.Profile.ApplyForLoanData.LiabilitiesForm.borrower) {
        setOldInputs(true);
      }
      if(authUser[0].data.Profile.ApplyForLoanData.LiabilitiesForm.coborrower) {
        setCoOldInputs(true);
      }
    }
  }, [authUser])

  useEffect(()=> {
    if(authUser[0].data.Profile.ApplyForLoanData.LiabilitiesForm) {
      if(authUser[0].data.Profile.ApplyForLoanData.LiabilitiesForm.borrower && oldInputs === true) {
        let oldInputVals = authUser[0].data.Profile.ApplyForLoanData.LiabilitiesForm.borrower;
        for(let i = 0; i < oldInputVals.length; i++){
          addLiabilitiesInitial(oldInputVals[i][0], oldInputVals[i][1], oldInputVals[i][2], oldInputVals[i][3], oldInputVals[i][4], "list");
        }
      }
    }
  }, [oldInputs])

  useEffect(()=> {
    if(authUser[0].data.Profile.ApplyForLoanData.LiabilitiesForm) {
      if(authUser[0].data.Profile.ApplyForLoanData.LiabilitiesForm.coborrower && coOldInputs === true) {
        let oldInputVals = authUser[0].data.Profile.ApplyForLoanData.LiabilitiesForm.coborrower;
        for(let i = 0; i < oldInputVals.length; i++){
          addLiabilitiesInitial(oldInputVals[i][0], oldInputVals[i][1], oldInputVals[i][2], oldInputVals[i][3], oldInputVals[i][4], "colist");
        }
      }
    }
  }, [coOldInputs])

  const addLiabilitiesInitial = (currenCompanyName, currentLiabilityType, currentMonthlyPayment, currentUnpaidBalance, currentMonthsToPay, list) => {
    let errors = 0;

    if(errors === 0)
    {
      setLiabilitiesInputsError("");
      setCoLiabilitiesInputsError("");
      let source = [currenCompanyName, currentLiabilityType, currentMonthlyPayment, currentUnpaidBalance, currentMonthsToPay];
      if(list === "list"){
        setLiabilitiesInputs(arr => [...arr, source]);
      } else {
        setCoLiabilitiesInputs(arr => [...arr, source]);
      }

      let li = document.createElement("li");
      let text = document.createTextNode(source[0] + " - " + source[1] + " - " + source[2] + " - " + source[3] + " - " + source[4]);
      li.id = source[0] + " " + source[1] + " " + source[2] + " " + source[3] + " " + source[4] + " " + list;
      li.appendChild(text);

      let removeBtn = document.createElement("input")
      removeBtn.type = "button";
      removeBtn.value = "Remove";
      removeBtn.className = styles.removeButton
      removeBtn.addEventListener("click", function() {
        let elementToRemove = li.id.trim().split(" ");
        if(elementToRemove[elementToRemove.length - 1] === "list"){
          setLiabilitiesInputs(arr => arr.filter(liability => !liabilitiesEqual(liability, li.id, list)))
        } else {
          setCoLiabilitiesInputs(arr => arr.filter(liability => !liabilitiesEqual(liability, li.id, list)))
        }
        li.remove();
      })
      li.appendChild(removeBtn)

      document.getElementById(list).appendChild(li);
    }
  }

  const addLiabilities = (list) => {
    let errors = 0;

    if(list === "list"){
      try{
        helpers.validString(companyName, "Liability Name");
        setCompanyNameError("");
      } catch(e) {
        setCompanyNameError(e.message);
        errors ++;
      }

      try{
        helpers.validLiability(liabilityType);
        setLiabilityTypeError("");
      } catch(e) {
        setLiabilityTypeError(e.message);
        errors ++;
      }

      try{
        helpers.validInt(monthlyPayment, "Monthly Payment");
        setMonthlyPaymentError("");
      } catch(e) {
        setMonthlyPaymentError(e.message);
        errors ++;
      }

      try{
        helpers.validInt(unpaidBalance, "Unpaid Balance");
        setUnpaidBalanceError("");
      } catch(e) {
        setUnpaidBalanceError(e.message);
        errors ++;
      }

      try{
        helpers.validInt(monthsToPay, "Months left to pay");
        setMonthsToPayError("");
      } catch(e) {
        setMonthsToPayError(e.message);
        errors ++;
      }
    } else {
      try{
        helpers.validString(coCompanyName, "Liability Name");
        setCoCompanyNameError("");
      } catch(e) {
        setCoCompanyNameError(e.message);
        errors ++;
      }

      try{
        helpers.validLiabilityType(coLiabilityType);
        setCoLiabilityTypeError("");
      } catch(e) {
        setCoLiabilityTypeError(e.message);
        errors ++;
      }

      try{
        helpers.validInt(coMonthlyPayment, "Monthly Payment");
        setCoMonthlyPaymentError("");
      } catch(e) {
        setCoMonthlyPaymentError(e.message);
        errors ++;
      }

      try{
        helpers.validInt(coUnpaidBalance, "Unpain Balance");
        setCoUnpaidBalanceError("");
      } catch(e) {
        setCoUnpaidBalanceError(e.message);
        errors ++;
      }

      try{
        helpers.validInt(coMonthsToPay, "Months left to pay");
        setCoMonthsToPayError("");
      } catch(e) {
        setCoMonthsToPayError(e.message);
        errors ++;
      }
    }

    if(errors === 0)
    {
      setLiabilitiesInputsError("");
      let source = []
      if(list === "list"){
        source = [companyName, liabilityType, monthlyPayment, unpaidBalance, monthsToPay];
        setLiabilitiesInputs(arr => [...arr, source]);
      } else {
        source = [coCompanyName, coLiabilityType, coMonthlyPayment, coUnpaidBalance, coMonthsToPay];
        setCoLiabilitiesInputs(arr => [...arr, source]);
      }

      let li = document.createElement("li");
      let text = document.createTextNode(source[0] + " - " + source[1] + " - " + source[2] + " - " + source[3] + " - " + source[4]);
      li.id = source[0] + " " + source[1] + " " + source[2] + " " + source[3] + " " + source[4] + " " + list;
      li.appendChild(text);

      let removeBtn = document.createElement("input")
      removeBtn.type = "button";
      removeBtn.value = "Remove";
      removeBtn.className = styles.removeButton
      removeBtn.addEventListener("click", function() {
        let elementToRemove = li.id.trim().split(" ");
        if(elementToRemove[elementToRemove.length - 1] === "list"){
          setLiabilitiesInputs(arr => arr.filter(liability => !liabilitiesEqual(liability, li.id, list)))
        } else {
          setCoLiabilitiesInputs(arr => arr.filter(liability => !liabilitiesEqual(liability, li.id, list)))
        }
        li.remove();
      })
      li.appendChild(removeBtn)

      document.getElementById(list).appendChild(li);
    }
  }

  const liabilitiesEqual = (a, b, list) => {
    let temp = a[0] + " " + a[1] + " " + a[2] + " " + a[3] + " " + a[4] + " " + list;
    if(temp === b ){
      return true;
    } else {
      return false
    }
  }

  const handleForm = async (event) => {
    event.preventDefault()


    if(liabilitiesInputs.length === 0){
      setLiabilitiesInputsError("You must add at least one liability");
    } else if (coborrowerSelected && coLiabilitiesInputs.length === 0){
      setLiabilitiesInputsError("You must add liabilities for your coborrower");
    }else {
      console.log(coborrowerSelected + " " + coLiabilitiesInputs)
      setLiabilitiesInputsError("")
      await localStorage();
    }
  };

  const localStorage = async () => {
    let liabilitiesInfo = {
      borrower: liabilitiesInputs,
      coborrower: coLiabilitiesInputs
    }
    const clientId = authUser[0].id;
    const url = "https://uat-api.tlcengine.com/v3/api/mlspin-md/clients/" + clientId;
    const token = authUser[0].user.AccessToken

    let userData = await helpers.getProfile(url, token);
    let copyUserData = helpers.updateData(userData, "LiabilitiesForm", liabilitiesInfo);
    await helpers.updateProfile(copyUserData, url, token);

    let thisClient = deepClone(authUser[0]);
    thisClient.data = copyUserData;
    dispatch(actions.addClientPI(thisClient));

    navigate("/apply-for-loan/borrowerform10")
  }

  return (
    <div className={styles.applyScreen}>
      <NavBar/>
      <div className={styles.applyContainer}>
        <div className={styles.applyContent}>
          <SideMenuBar activeClass={"applyForLoan"}/>
          <div className={styles.formContainer}>

            <div className={styles.rectangleTabs}/>
            <ul className={styles.tabs}>
              <li className={borrowerTab}><button onClick={updateBorrowerTab}>Borrower</button></li> 
              {coborrowerSelected && <li className={coBorrowerTab}><button onClick={updateCoBorrowerTab}>Co-Borrower</button></li>}
            </ul>

            <div id="borrower" className={borrowerDiv}>
              <div className={styles.borrowerFormCardDiv}>
                <div className={styles.borrowerFormCardTitleText}>
                  <div className={styles.borrowerFormTitle}>
                    Borrower Liabilities
                  </div>
                </div>
                <div className={styles.errorAndInputDiv}>
                  {liabilitiesInputsError != "" && <div className={styles.errorDiv}>{liabilitiesInputsError}</div>}
                </div>
                <div><br/></div>
                <div className={styles.inputsContainer}>
                  <div className={styles.uldiv}>
                    <ul id="list"></ul>
                  </div>
                </div>
                <div >
                  <div className={styles.inputsContainer}>
                    <div className={styles.errorAndInputDiv}>
                      {companyNameError != "" && <div className={styles.errorDiv}>{companyNameError}</div>}
                      <div className={styles.inputDiv}>
                          <input
                              onChange={(e) => setCompanyName(e.target.value)}
                              name="companyNameInput"
                              type="text"
                              className={styles.formInput}
                              placeholder={"Company Name"}
                              value={companyName}
                          />
                      </div>
                    </div>
                    <div className={styles.errorAndInputDiv}>
                      {liabilityTypeError != "" && <div className={styles.errorDiv}>{liabilityTypeError}</div>}
                      <div className={styles.inputDiv}>
                          <div className={styles.dropdown}>
                              <select className={styles.selectBox}
                                onChange={(e) => setLiabilityType(e.target.value)}
                              >
                                  <option>Kind of Liability</option>
                                  <option>Accounts Payable</option>
                                  <option>Principle & interest payable</option>
                                  <option>Short-term loans</option>
                                  <option>Taxes payable</option>
                                  <option>Accrued expenses</option>
                                  <option>Unearned revenue</option>
                                  <option>Notes payable</option>
                                  <option>Mortgage payable</option>
                                  <option>Bonds payable</option>
                                  <option>Deferred tax liabilities</option>
                                  <option>Capital leases</option>
                              </select>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.inputsContainer}>
                    <div className={styles.errorAndInputDiv}>
                      {monthlyPaymentError != "" && <div className={styles.errorDiv}>{monthlyPaymentError}</div>}
                      <div className={styles.inputDiv}>
                        <input
                            onChange={(e) => setMonthlyPayment(e.target.value)}
                            name="monthlyPaymentInput"
                            type="text"
                            className={styles.formInput}
                            placeholder={"$ Monthly Payment"}
                            value={monthlyPayment}
                        />
                      </div>
                    </div>
                    <div className={styles.errorAndInputDiv}>
                      {unpaidBalanceError != "" && <div className={styles.errorDiv}>{unpaidBalanceError}</div>}
                      <div className={styles.inputDiv}>
                        <input
                            onChange={(e) => setUnpaidBalance(e.target.value)}
                            name="balanceInput"
                            type="text"
                            className={styles.formInput}
                            placeholder={"$ Unpaid Balance"}
                            value={unpaidBalance}
                        />
                      </div>
                    </div>
                    </div>
                  </div>
                  <div className={styles.inputsContainer}>
                    <div className={styles.errorAndInputDiv}>
                      {monthsToPayError != "" && <div className={styles.errorDiv}>{monthsToPayError}</div>}
                      <div className={styles.inputDiv}>
                        <input
                            onChange={(e) => setMonthsToPay(e.target.value)}
                            name="monthsToPayInput"
                            type="text"
                            className={styles.formInput}
                            placeholder={"Months Left to Pay"}
                            value={monthsToPay}
                        />
                      </div>
                    </div>
                    
                  </div>
                  <div>
                    <button className={styles.newIncomeButton} onClick={() => addLiabilities("list")}>Add Another Liability</button>
                  </div>
                  <div>
              </div>
              </div>
              <div className={styles.nextButtonDiv}>
                <button className={styles.backButton} onClick={()=>navigate("/apply-for-loan/borrowerform8")}>Back</button>
                <button className={styles.nextButton} onClick={handleForm}>Next</button>
              </div>
            </div>
            
          </div>

          <div id="coborrower" className={coBorrowerDiv}>
              <div className={styles.borrowerFormCardDiv}>
                <div className={styles.borrowerFormCardTitleText}>
                  <div className={styles.borrowerFormTitle}>
                    Co-Borrower Liabilities
                  </div>
                </div>
                <div className={styles.errorAndInputDiv}>
                  {liabilitiesInputsError != "" && <div className={styles.errorDiv}>{liabilitiesInputsError}</div>}
                </div>
                <div><br/></div>
                <div className={styles.inputsContainer}>
                  <div className={styles.uldiv}>
                    <ul id="colist"></ul>
                  </div>
                </div>
                <div >
                  <div className={styles.inputsContainer}>
                    <div className={styles.errorAndInputDiv}>
                      {coCompanyNameError != "" && <div className={styles.errorDiv}>{coCompanyNameError}</div>}
                      <div className={styles.inputDiv}>
                          <input
                              onChange={(e) => setCoCompanyName(e.target.value)}
                              name="companyNameInput"
                              type="text"
                              className={styles.formInput}
                              placeholder={"Company Name"}
                          />
                      </div>
                    </div>
                    <div className={styles.errorAndInputDiv}>
                      {coLiabilityTypeError != "" && <div className={styles.errorDiv}>{coLiabilityTypeError}</div>}
                      <div className={styles.inputDiv}>
                          <div className={styles.dropdown}>
                              <select className={styles.selectBox}
                                onChange={(e) => setCoLiabilityType(e.target.value)}
                              >
                                  <option>Type of Liability</option>
                                  <option>Accounts Payable</option>
                                  <option>Principle & interest payable</option>
                                  <option>Short-term loans</option>
                                  <option>Taxes payable</option>
                                  <option>Accrued expenses</option>
                                  <option>Unearned revenue</option>
                                  <option>Notes payable</option>
                                  <option>Mortgage payable</option>
                                  <option>Bonds payable</option>
                                  <option>Deferred tax liabilities</option>
                                  <option>Capital leases</option>
                              </select>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.inputsContainer}>
                    <div className={styles.errorAndInputDiv}>
                      {coMonthlyPaymentError != "" && <div className={styles.errorDiv}>{coMonthlyPaymentError}</div>}
                      <div className={styles.inputDiv}>
                        <input
                            onChange={(e) => setCoMonthlyPayment(e.target.value)}
                            name="monthlyPaymentInput"
                            type="text"
                            className={styles.formInput}
                            placeholder={"$ Monthly Payment"}
                        />
                      </div>
                    </div>
                    <div className={styles.errorAndInputDiv}>
                      {coUnpaidBalanceError != "" && <div className={styles.errorDiv}>{coUnpaidBalanceError}</div>}
                      <div className={styles.inputDiv}>
                        <input
                            onChange={(e) => setCoUnpaidBalance(e.target.value)}
                            name="balanceInput"
                            type="text"
                            className={styles.formInput}
                            placeholder={"$ Unpaid Balance"}
                        />
                      </div>
                    </div>
                    </div>
                  </div>
                  <div className={styles.inputsContainer}>
                    <div className={styles.errorAndInputDiv}>
                      {coMonthsToPayError != "" && <div className={styles.errorDiv}>{coMonthsToPayError}</div>}
                      <div className={styles.inputDiv}>
                        <input
                            onChange={(e) => setCoMonthsToPay(e.target.value)}
                            name="monthsToPayInput"
                            type="text"
                            className={styles.formInput}
                            placeholder={"Months Left to Pay"}
                        />
                      </div>
                    </div>
                    <button className={styles.newIncomeButton} onClick={() => addLiabilities("colist")}>Add Another Liability</button>
                  </div>
                  <div>
                </div>
              </div>
            </div>
            <div className={styles.myLoanerContainer}>
              <div className={styles.myLoanerContent}>
                <YourLoanOfficer
                  name={"Krishna Malyala"}
                  email={"kmalyala@nexamortgage.com"}
                  phone={"334-625-9252"}
                  nmls1={"NMLS #1875937"}
                  states={"NJ"}
                  nmls2={"NMLS #1660690"}
                />
              </div>      
              
            </div>
            </div>
        </div>
      </div>
  //   </div>
  // </div>
  );
}





