import * as React from "react";
import SideMenuBar from "./SideMenuBar";
import NavBar from './NavBar';
import styles from './ApplyForLoan.module.css'
import YourLoanOfficer from "./YourLoanOfficer";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import actions from "../actions";
import helpers from "../helpers";
import CSConfirmInfo from "./CSConfirmInfo";

const deepClone = obj => {
  if (obj === null) return null;
  let clone = Object.assign({}, obj);
  Object.keys(clone).forEach(
    key =>
      (clone[key] =
        typeof obj[key] === 'object' ? deepClone(obj[key]) : obj[key])
  );
  if (Array.isArray(obj)) {
    clone.length = obj.length;
    return Array.from(clone);
  }
  return clone;
};

export default function CreditScore() {
  const navigate = useNavigate(); 

  const [firstName, setFirstName] = useState('');
  const [address, setAddress] = useState('');
  const [lastName, setLastName] = useState('');
  const [address2, setAddress2] = useState('');
  const [suffix, setSuffix] = useState('');
  const [city, setCity] = useState('');
  const [dob, setDob] = useState('');
  const [state, setState] = useState('');
  const [social, setSocial] = useState('');
  const [socialConfirm, setSocialConfirm] = useState('');
  const [zip1, setZip1] = useState('');
  const [zip2, setZip2] = useState('');

  const [firstNameError, setFirstNameError] = useState('');
  const [addressError, setAddressError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [address2Error, setAddress2Error] = useState('');
  const [suffixError, setSuffixError] = useState('');
  const [cityError, setCityError] = useState('');
  const [dobError, setDobError] = useState('');
  const [stateError, setStateError] = useState('');
  const [zip1Error, setZip1Error] = useState('');
  const [zip2Error, setZip2Error] = useState('');

  const authUser = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const deepClone = obj => {
    if (obj === null) return null;
    let clone = Object.assign({}, obj);
    Object.keys(clone).forEach(
      key =>
        (clone[key] =
          typeof obj[key] === 'object' ? deepClone(obj[key]) : obj[key])
    );
    if (Array.isArray(obj)) {
      clone.length = obj.length;
      return Array.from(clone);
    }
    return clone;
  };

  const localStorage = async (event) => {
    var data = {
      firstName: firstName,
      address: address,
      lastName: lastName,
      address2: address2,
      suffix: suffix,
      city: city,
      state: state, 
      dob: dob,
      zip1: zip1,
      zip2: zip2,
      social: social,
      socialConfirm: socialConfirm
    }

    let thisClient = deepClone(authUser[0])
    let changes = 0;

    if (!authUser[0].data.Profile.hasOwnProperty("CreditScoreData")) {
      thisClient.data.Profile.CreditScoreData = {}
    }

    if(data){
      thisClient.data.Profile.CreditScoreData = data;
      changes++;
    }

    if (changes) {
      dispatch(actions.addClientPI(thisClient));
    }

    console.log("here")

    navigate("/credit-score/confirminfo");
  }

  const handleForm = async (event) => {
    event.preventDefault();

    let errors = 0;

    try{
      helpers.validName(firstName);
      setFirstNameError("");
    } catch(e) {
      setFirstNameError(e.message);
      errors ++;
    }

    try{
      helpers.validAddress(address);
      setAddressError("");
    } catch(e) {
      setAddressError(e.message);
      errors ++;
    }

    try{
      helpers.validName(lastName);
      setLastNameError("");
    } catch(e) {
      setLastNameError(e.message);
      errors ++;
    }

    try{
      helpers.validCity(city);
      setCityError("");
    } catch(e) {
      setCityError(e.message);
      errors ++;
    }

    try{
      helpers.validBirthday
      (dob);
      setDobError("");
    } catch(e) {
      setDobError(e.message);
      errors ++;
    }

    try{
      helpers.validString(state, "State");
      setStateError("");
    } catch(e) {
      setStateError(e.message);
      errors ++;
    }

    try{
      helpers.validZip(zip1);
      setZip1Error("");
    } catch(e) {
      setZip1Error(e.message);
      errors ++;
    }

    try{
      helpers.validZip(zip2);
      setZip2Error("");
    } catch(e) {
      setZip2Error(e.message);
      errors ++;
    }

    console.log(errors)

    if(errors == 0){
      await localStorage();
    }

    /*if (data.firstName) {
      thisClient.credit_score_first_name = data.firstName;
      changes++;
    }
    if (data.lastName) {
      thisClient.credit_score_last_name = data.lastName;
      changes++;
    }
    if (data.suffix) {
      thisClient.apply_for_loan_suffix = data.suffix;
      changes++;
    }
    if (data.dob) {
      thisClient.credit_score_dob = data.dob;
      changes++;
    }
    if (data.social) {
      thisClient.credit_score_social = data.social;
      changes++;
    }
    if (data.address) {
      thisClient.credit_score_address = data.address;
      changes++;
    }
    if (data.address2) {
      thisClient.credit_score_address2 = data.address2;
      changes++;
    }
    if (data.city) {
      thisClient.credit_score_city = data.city;
      changes++;
    }
    if (data.state) {
      thisClient.credit_score_state = data.state;
      changes++;
    }
    if (data.zip1) {
      thisClient.credit_score_zip1 = data.zip1;
      changes++;
    }
    if (data.zip2) {
      thisClient.credit_score_zip2 = data.zip2;
      changes++;
    }*/
  };

  return (
    <div className={styles.applyScreen}>
      <NavBar/>
      <div className={styles.applyContainer}>
        <div className={styles.applyContent}>
          <SideMenuBar activeClass={"creditScore"}/>
          <div className={styles.formContainer}>
            <div className={styles.rectangle}/>
            <div className={styles.borrowerFormCardTabs}>
              <div >
                <div className={styles.inputsContainer}>
                  <div className={styles.creditScoreTextLabel}>
                    Legal Name
                  </div>
                  <div className={styles.creditScoreTextLabel}>
                    Current US Address
                  </div>
                </div>
                <div className={styles.inputsContainer}>
                  <div className={styles.errorAndInputDiv}>
                    {firstNameError != "" && <div className={styles.errorDiv}>{firstNameError}</div>}
                    <div className={styles.inputDiv}>
                      <input
                        onChange={(e) => setFirstName(e.target.value)}
                        name="firstNameInput"
                        type="text"
                        className={styles.formInput}
                        placeholder={"First Name"}
                      />
                    </div>
                  </div>
                  <div className={styles.errorAndInputDiv}>
                    {addressError != "" && <div className={styles.errorDiv}>{addressError}</div>}
                    <div className={styles.inputDiv}>
                      <input
                        onChange={(e) => setAddress(e.target.value)}
                        name="addressInput"
                        type="text"
                        className={styles.formInput}
                        placeholder={"Address"}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.inputsContainer}>
                  <div className={styles.errorAndInputDiv}>
                    {lastNameError != "" && <div className={styles.errorDiv}>{lastNameError}</div>}
                    <div className={styles.inputDiv}>
                      <input
                          onChange={(e) => setLastName(e.target.value)}
                          name="lastNameInput"
                          type="text"
                          className={styles.formInput}
                          placeholder={"Last Name"}
                      />
                    </div>
                  </div>
                  <div className={styles.errorAndInputDiv}>
                    <div className={styles.inputDiv}>
                      <input
                          onChange={(e) => setAddress2(e.target.value)}
                          name="addressInput"
                          type="text"
                          className={styles.formInput}
                          placeholder={"Address Line 2"}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.inputsContainer}>
                  <div className={styles.inputDiv}>
                    <input
                        onChange={(e) => setSuffix(e.target.value)}
                        name="suffixInput"
                        type="text"
                        className={styles.formInput}
                        placeholder={"Suffix"}
                    />
                  </div>
                  <div className={styles.errorAndInputDiv}>
                    {cityError != "" && <div className={styles.errorDiv}>{cityError}</div>}
                    <div className={styles.inputDiv}>
                      <input
                          onChange={(e) => setCity(e.target.value)}
                          name="cityInput"
                          type="text"
                          className={styles.formInput}
                          placeholder={"City"}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.inputsContainer}>
                  <div className={styles.errorAndInputDiv}>
                    {dobError != "" && <div className={styles.errorDiv}>{dobError}</div>}
                    <div className={styles.inputDiv}>
                      <input
                          onChange={(e) => setDob(e.target.value)}
                          name="dobInput"
                          type="text"
                          className={styles.formInput}
                          placeholder={"Birthday MM/DD/YYYY"}
                      />
                    </div>
                  </div>
                  <div className={styles.errorAndInputDiv}>
                    {stateError != "" && <div className={styles.errorDiv}>{stateError}</div>}
                    <div className={styles.inputDiv}>
                      <input
                          onChange={(e) => setState(e.target.value)}
                          name="stateInput"
                          type="text"
                          className={styles.formInput}
                          placeholder={"State"}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.inputsContainer}>
                  <div className={styles.inputDiv}>
                    <input
                        onChange={(e) => setSocial(e.target.value)}
                        name="socialInput"
                        type="text"
                        className={styles.formInput}
                        placeholder={"Social Security Number 123-45-6789"}
                    />
                  </div>
                  <div className={styles.errorAndInputDiv}>
                    {zip1Error != "" && <div className={styles.errorDiv}>{zip1Error}</div>}
                    {zip1Error == "" && zip2Error != "" && <div className={styles.errorDiv}>{zip2Error}</div>}
                    <div className={styles.inputDiv}>
                      <input
                          onChange={(e) => setZip1(e.target.value)}
                          name="zipInput"
                          type="text"
                          className={styles.formInput}
                          placeholder={"Zip (First 5)"}
                      />
                      <br/>
                      <input
                          onChange={(e) => setZip2(e.target.value)}
                          name="zipInput"
                          type="text"
                          className={styles.formInput}
                          placeholder={"Zip (Last 4)"}
                      />
                  </div>
                  </div>
              </div>
              <div className={styles.inputsContainer}>
                  <div className={styles.inputDiv}>
                    <input
                        onChange={(e) => setSocialConfirm(e.target.value)}
                        name="socialInput"
                        type="text"
                        className={styles.formInput}
                        placeholder={"Social Confirmation"}
                    />
                  </div>
                </div>
                
            </div>

            <div className={styles.nextButtonDiv}>
              <button className={styles.nextButton} onClick={handleForm}>Next</button>
            </div>
            
          </div>
        
        </div>
        <div className={styles.myLoanerContainer}>
             <div className={styles.myLoanerContent}>
              <YourLoanOfficer
                name={"Krishna Malyala"}
                email={"kmalyala@nexamortgage.com"}
                phone={"334-625-9252"}
                nmls1={"NMLS #1875937"}
                states={"NJ"}
                nmls2={"NMLS #1660690"}
              />
            </div>      
            
          </div>
      </div>
    </div>
  </div>
  );
}


