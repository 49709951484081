import * as React from "react";
import SideMenuBar from "./SideMenuBar";
import NavBar from './NavBar';
import styles from './ApplyForLoan.module.css'
import YourLoanOfficer from "./YourLoanOfficer";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import actions from "../actions";
import helpers from "../helpers";

const deepClone = obj => {
  if (obj === null) return null;
  let clone = Object.assign({}, obj);
  Object.keys(clone).forEach(
    key =>
      (clone[key] =
        typeof obj[key] === 'object' ? deepClone(obj[key]) : obj[key])
  );
  if (Array.isArray(obj)) {
    clone.length = obj.length;
    return Array.from(clone);
  }
  return clone;
};

export default function AFLDeclarationsForm() {
  const navigate = useNavigate(); 

  const [delinquent, setDelinquent] = useState('');
  const [primaryResidence, setPrimaryResidence] = useState('');
  const [borrowedDown, setBorrowedDown] = useState('');
  const [ownershipInterest, setOwnershipInterest] = useState('');
  const [comaker, setComaker] = useState('');
  const [usCitizen, setUsCitizen] = useState('');

  const [delinquentError, setDelinquentError] = useState('');
  const [primaryResidenceError, setPrimaryResidenceError] = useState('');
  const [borrowedDownError, setBorrowedDownError] = useState('');
  const [ownershipInterestError, setOwnershipInterestError] = useState('');
  const [comakerError, setComakerError] = useState('');
  const [usCitizenError, setUsCitizenError] = useState('');

  const [coborrowerSelected, setCoborrowerSelected] = useState(false);

  const [coDelinquent, setCoDelinquent] = useState('');
  const [coPrimaryResidence, setCoPrimaryResidence] = useState('');
  const [coBorrowedDown, setCoBorrowedDown] = useState('');
  const [coOwnershipInterest, setCoOwnershipInterest] = useState('');
  const [coComaker, setCoComaker] = useState('');
  const [coUsCitizen, setCoUsCitizen] = useState('');

  const [coDelinquentError, setCoDelinquentError] = useState('');
  const [coPrimaryResidenceError, setCoPrimaryResidenceError] = useState('');
  const [coBorrowedDownError, setCoBorrowedDownError] = useState('');
  const [coOwnershipInterestError, setCoOwnershipInterestError] = useState('');
  const [coComakerError, setCoComakerError] = useState('');
  const [coUsCitizenError, setCoUsCitizenError] = useState('');

  const [borrowerTab, setBorrowerTab] = useState(styles.active);
  const [coBorrowerTab, setCoBorrowerTab] = useState(styles.a);
  const [borrowerDiv, setBorrowerDiv] = useState(styles.borrowerFormCardTabs);
  const [coBorrowerDiv, setCoBorrowerDiv] = useState(styles.borrowerFormCardTabsInvisible);

  const authUser = useSelector((state) => state.users);
  const dispatch = useDispatch();

  function updateBorrowerTab() {
    setBorrowerTab(styles.active)
    setCoBorrowerTab(styles.a)
    setBorrowerDiv(styles.borrowerFormCardTabs)
    setCoBorrowerDiv(styles.borrowerFormCardTabsInvisible)
  }

  function updateCoBorrowerTab() {
    setBorrowerTab(styles.a)
    setCoBorrowerTab(styles.active)
    setBorrowerDiv(styles.borrowerFormCardTabsInvisible)
    setCoBorrowerDiv(styles.borrowerFormCardTabs)
  }

  useEffect(() => {
    if(authUser[0].data.Profile.ApplyForLoanData.BorrowerNameForm) {
      setCoborrowerSelected(authUser[0].data.Profile.ApplyForLoanData.BorrowerNameForm.borrower.coborrowerSelected)
    }
    if(authUser[0].data.Profile.ApplyForLoanData.DeclarationsForm) {
      if(authUser[0].data.Profile.ApplyForLoanData.DeclarationsForm.borrower) {
        if(authUser[0].data.Profile.ApplyForLoanData.DeclarationsForm.borrower.delinquent){
          setDelinquent(authUser[0].data.Profile.ApplyForLoanData.DeclarationsForm.borrower.delinquent)
        }
        if(authUser[0].data.Profile.ApplyForLoanData.DeclarationsForm.borrower.primaryResidence){
          setPrimaryResidence(authUser[0].data.Profile.ApplyForLoanData.DeclarationsForm.borrower.primaryResidence)
        }
        if(authUser[0].data.Profile.ApplyForLoanData.DeclarationsForm.borrower.borrowedDown){
          setBorrowedDown(authUser[0].data.Profile.ApplyForLoanData.DeclarationsForm.borrower.borrowedDown)
        }
        if(authUser[0].data.Profile.ApplyForLoanData.DeclarationsForm.borrower.ownershipInterest){
          setOwnershipInterest(authUser[0].data.Profile.ApplyForLoanData.DeclarationsForm.borrower.ownershipInterest)
        }
        if(authUser[0].data.Profile.ApplyForLoanData.DeclarationsForm.borrower.comaker){
          setComaker(authUser[0].data.Profile.ApplyForLoanData.DeclarationsForm.borrower.comaker)
        }
        if(authUser[0].data.Profile.ApplyForLoanData.DeclarationsForm.borrower.usCitizen){
          setUsCitizen(authUser[0].data.Profile.ApplyForLoanData.DeclarationsForm.borrower.usCitizen)
        }
      }
    }
  }, [authUser])
  const handleForm = async (event) => {
    event.preventDefault()
    let errors = 0;

    //declaration validation
    try {
      helpers.validYesOrNo(delinquent);
      setDelinquentError("");
    } catch (e) {
      setDelinquentError(e.message);
      errors++;
    }

    try {
      helpers.validYesOrNo(primaryResidence);
      setPrimaryResidenceError("");
    } catch (e) {
      setPrimaryResidenceError(e.message);
      errors++;
    }

    try {
      helpers.validYesOrNo(borrowedDown);
      setBorrowedDownError("");
    } catch (e) {
      setBorrowedDownError(e.message);
      errors++;
    }

    try {
      helpers.validYesOrNo(ownershipInterest);
      setOwnershipInterestError("");
    } catch (e) {
      setOwnershipInterestError(e.message);
      errors++;
    }

    try {
      helpers.validYesOrNo(comaker);
      setComakerError("");
    } catch (e) {
      setComakerError(e.message);
      errors++;
    }

    try {
      helpers.validUSCitizen(usCitizen);
      setUsCitizenError("");
    } catch (e) {
      setUsCitizenError(e.message);
      errors++;
    }

    //coborrower declaration validation
    if(coborrowerSelected) {
      try {
        helpers.validYesOrNo(coDelinquent);
        setCoDelinquentError("");
      } catch (e) {
        setCoDelinquentError(e.message);
        errors++;
      }
  
      try {
        helpers.validYesOrNo(coPrimaryResidence);
        setCoPrimaryResidenceError("");
      } catch (e) {
        setCoPrimaryResidenceError(e.message);
        errors++;
      }
  
      try {
        helpers.validYesOrNo(coBorrowedDown);
        setCoBorrowedDownError("");
      } catch (e) {
        setCoBorrowedDownError(e.message);
        errors++;
      }
  
      try {
        helpers.validYesOrNo(coOwnershipInterest);
        setCoOwnershipInterestError("");
      } catch (e) {
        setCoOwnershipInterestError(e.message);
        errors++;
      }
  
      try {
        helpers.validYesOrNo(coComaker);
        setCoComakerError("");
      } catch (e) {
        setCoComakerError(e.message);
        errors++;
      }
  
      try {
        helpers.validUSCitizen(coUsCitizen);
        setCoUsCitizenError("");
      } catch (e) {
        setCoUsCitizenError(e.message);
        errors++;
      }
  
    }
    
    if (errors == 0) {
      await localStorage();
    } 
    };
  const localStorage = async () => {

    let borrower = {
      delinquent: delinquent,
      primaryResidence: primaryResidence,
      borrowedDown: borrowedDown,
      ownershipInterest: ownershipInterest,
      comaker: comaker,
      usCitizen: usCitizen
    }
    let coborrower = {
      delinquent: coDelinquent,
      primaryResidence: coPrimaryResidence,
      borrowedDown: coBorrowedDown,
      ownershipInterest: coOwnershipInterest,
      comaker: coComaker,
      usCitizen: coUsCitizen
    }

    const clientId = authUser[0].id;
    const url = "https://uat-api.tlcengine.com/v3/api/mlspin-md/clients/" + clientId;
    const token = authUser[0].user.AccessToken

    let userData = await helpers.getProfile(url, token);
    let copyUserData = helpers.updateData(userData, "DeclarationsForm", {borrower, coborrower});
    await helpers.updateProfile(copyUserData, url, token);
    
    let thisClient = deepClone(authUser[0]);
    thisClient.data = copyUserData;
    dispatch(actions.addClientPI(thisClient))

    navigate("/apply-for-loan/borrowerform12")
  }

  return (
    <div className={styles.applyScreen}>
      <NavBar/>
      <div className={styles.applyContainer}>
        <div className={styles.applyContent}>
          <SideMenuBar activeClass={"applyForLoan"}/>
          <div className={styles.formContainer}>

            <div className={styles.rectangleTabs}/>
            <ul className={styles.tabs}>
              <li className={borrowerTab}><button onClick={updateBorrowerTab}>Borrower</button></li> 
              <li className={coBorrowerTab}><button onClick={updateCoBorrowerTab}>Co-Borrower</button></li> 
            </ul>

            <div id="borrower" className={borrowerDiv}>
              <div className={styles.borrowerFormCardDiv}>
                <div className={styles.borrowerFormCardTitleText}>
                  <div className={styles.borrowerFormTitle}>
                    Borrower Declarations
                  </div>
                </div>
                <div >
                  <div className={styles.inputsContainer}>
                  <div className={styles.errorAndInputDiv}>
                    {delinquentError != "" && (<div className={styles.errorDiv}>{delinquentError}</div>)}
                      <div className={styles.coborrowerRadio}>
                          <div className={styles.declarationsDiv}>
                              <div className={styles.label}>
                                  Are you presently delinquent or in deault on any Federal debt or any other loan, mortgage, financial obligation, band, or loan guarantee?
                              </div>
                              <div className={styles.blankSpace7}/>
                              <input type="radio" id="other" name="delinquent" checked={delinquent=="Yes"} onChange={(e) => setDelinquent("Yes")}/>
                              <label>Yes   </label>
                              <input type="radio" id="other" name="delinquent" checked={delinquent=="No"}onChange={(e) => setDelinquent("No")}/>
                              <label>No</label>
                          </div>
                          </div>

                      </div>
                      <div className={styles.coborrowerRadio}>
                      <div className={styles.errorAndInputDiv}>
                    {primaryResidenceError != "" && (<div className={styles.errorDiv}>{primaryResidenceError}</div>)}
                          <div className={styles.declarationsDiv}>
                              <div className={styles.label}>
                                  Do you intend to occupy this propery as you primary residence? 
                              </div>
                              <div className={styles.blankSpace7}/>
                              <input type="radio" id="other" name="primary" checked={primaryResidence=="Yes"} onChange={(e) => setPrimaryResidence("Yes")}/>
                              <label>Yes   </label>
                              <input type="radio" id="other" name="primary" checked={primaryResidence=="No"} onChange={(e) => setPrimaryResidence("No")}/>
                              <label>No</label>
                          </div>
                          </div>
                      </div>
                  </div>
                  <div className={styles.inputsContainer}>
                  <div className={styles.coborrowerRadio}>
                  <br/>
                  <div className={styles.errorAndInputDiv}>
                    {borrowedDownError != "" && (<div className={styles.errorDiv}>{borrowedDownError}</div>)}
                          <div className={styles.declarationsDiv}>
                              <div className={styles.label}>
                                Is any part of the down payment borrowed?
                              </div>
                              <div className={styles.blankSpace7}/>
                              <input type="radio" id="other" name="borrowed" checked={borrowedDown=="Yes"} onChange={(e) => setBorrowedDown("Yes")}/>
                              <label>Yes   </label>
                              <input type="radio" id="other" name="borrowed" checked={borrowedDown=="No"} onChange={(e) => setBorrowedDown("No")}/>
                              <label>No</label>
                          </div>
                          </div>
                      </div>
                      <div className={styles.coborrowerRadio}>
                      <div className={styles.errorAndInputDiv}>
                    {ownershipInterestError != "" && (<div className={styles.errorDiv}>{ownershipInterestError}</div>)}
                          <div className={styles.declarationsDiv}>
                              <div className={styles.label}>
                                  Have you ever had ownership interest in a property in the last three years?
                              </div>
                              <div className={styles.blankSpace7}/>
                              <input type="radio" id="other" name="ownership" checked={ownershipInterest=="Yes"} onChange={(e) => setOwnershipInterest("Yes")}/>
                              <label>Yes   </label>
                              <input type="radio" id="other" name="ownership" checked={ownershipInterest=="No"} onChange={(e) => setOwnershipInterest("No")}/>
                              <label>No</label>
                          </div>
                          </div>
                      </div>
                  </div>
                  <div className={styles.inputsContainer}>
                      <div className={styles.coborrowerRadio}>
                        <br/>
                      <div className={styles.errorAndInputDiv}>
                    {comakerError != "" && (<div className={styles.errorDiv}>{comakerError}</div>)}
                          <div className={styles.declarationsDiv}>
                              <div className={styles.label}>
                                  Are you a co-maker or endorser on a note?
                              </div>
                              <div className={styles.blankSpace7}/>
                              <input type="radio" id="other" name="comaker" checked={comaker=="Yes"} onChange={(e) => setComaker("Yes")}/>
                              <label>Yes   </label>
                              <input type="radio" id="other" name="comaker" checked={comaker=="No"} onChange={(e) => setComaker("No")}/>
                              <label>No</label>
                          </div>
                          </div>
                      </div>
                  </div>
                  <div className={styles.inputsContainer}>
                      <div className={styles.coborrowerRadio}>
                        <br/>
                      <div className={styles.errorAndInputDiv}>
                          {usCitizenError != "" && (<div className={styles.errorDiv}>{usCitizenError}</div>)}
                          <div className={styles.declarationsDiv}>
                              <div className={styles.label}>
                                  Are you a U.S. citizen or alien?
                              </div>
                              <div className={styles.blankSpace7}/>
                              <div>
                                  <input type="radio" id="other" name="citizen" checked={usCitizen=="US Citizen"} onChange={(e) => setUsCitizen("US Citizen")}/>
                                  <label>U.S. Citizen</label>
                              </div>
                              <div>
                                  <input type="radio" id="other" name="citizen" checked={usCitizen=="Permanent Alien"}onChange={(e) => setUsCitizen("Permanent Alien")}/>
                                  <label>Permanent resident alien</label>
                              </div>
                              <div>
                                  <input type="radio" id="other" name="citizen" checked={usCitizen=="Nonpermanent Alien"}onChange={(e) => setUsCitizen("Nonpermanent Alien")}/>
                                  <label>Non-permanent resident alien</label>
                              </div>
                          </div>
                          </div>
                      </div>
                  </div>
                  <div>
                </div>
              </div>
            </div>
            <div className={styles.nextButtonDiv}>
                <button className={styles.backButton} onClick={()=>navigate("/apply-for-loan/borrowerform10")}>Back</button>
                <button className={styles.nextButton} onClick={handleForm}>Next</button>
              </div>
          </div>

          <div id="coborrower" className={coBorrowerDiv}>
            <div className={styles.borrowerFormCardDiv}>
                <div className={styles.borrowerFormCardTitleText}>
                  <div className={styles.borrowerFormTitle}>
                    Co-Borrower Declarations
                  </div>
                </div>
                <div >
                  <div className={styles.inputsContainer}>
                      <div className={styles.coborrowerRadio}>
                      <br/>
                      <div className={styles.errorAndInputDiv}>
                    {coDelinquentError != "" && (<div className={styles.errorDiv}>{coDelinquentError}</div>)}
                          <div className={styles.declarationsDiv}>
                              <div className={styles.label}>
                                  Are you presently delinquent or in deault on any Federal debt or any other loan, mortgage, financial obligation, band, or loan guarantee?
                              </div>
                              <div className={styles.blankSpace7}/>
                              <input type="radio" id="other" name="delinquent" onChange={(e) => setCoDelinquent("Yes")}/>
                              <label>Yes   </label>
                              <input type="radio" id="other" name="delinquent" onChange={(e) => setCoDelinquent("No")}/>
                              <label>No</label>
                          </div>
                          </div>
                      </div>
                      <div className={styles.coborrowerRadio}>
                      <br/>
                      <div className={styles.errorAndInputDiv}>
                        {coPrimaryResidenceError != "" && (<div className={styles.errorDiv}>{coPrimaryResidenceError}</div>)}
                          <div className={styles.declarationsDiv}>
                              <div className={styles.label}>
                                  Do you intend to occupy this propery as you primary residence? 
                              </div>
                              <div className={styles.blankSpace7}/>
                              <input type="radio" id="other" name="primary" onChange={(e) => setCoPrimaryResidence("Yes")}/>
                              <label>Yes   </label>
                              <input type="radio" id="other" name="primary" onChange={(e) => setCoPrimaryResidence("No")}/>
                              <label>No</label>
                          </div>
                        </div>
                      </div>
                  </div>
                  <div className={styles.inputsContainer}>
                  <div className={styles.coborrowerRadio}>
                  <br/>
                      <div className={styles.errorAndInputDiv}>
                        {coBorrowedDownError != "" && (<div className={styles.errorDiv}>{coBorrowedDownError}</div>)}
                          <div className={styles.declarationsDiv}>
                              <div className={styles.label}>
                                Is any part of the down payment borrowed?
                              </div>
                              <div className={styles.blankSpace7}/>
                              <input type="radio" id="other" name="borrowed" onChange={(e) => setCoBorrowedDown("Yes")}/>
                              <label>Yes   </label>
                              <input type="radio" id="other" name="borrowed" onChange={(e) => setCoBorrowedDown("No")}/>
                              <label>No</label>
                          </div>
                          </div>
                      </div>
                      <div className={styles.coborrowerRadio}>
                      <br/>
                      <div className={styles.errorAndInputDiv}>
                        {coOwnershipInterestError != "" && (<div className={styles.errorDiv}>{coOwnershipInterestError}</div>)}
                          <div className={styles.declarationsDiv}>
                              <div className={styles.label}>
                                  Have you ever had ownership interest in a property in the last three years?
                              </div>
                              <div className={styles.blankSpace7}/>
                              <input type="radio" id="other" name="ownership" onChange={(e) => setCoOwnershipInterest("Yes")}/>
                              <label>Yes   </label>
                              <input type="radio" id="other" name="ownership" onChange={(e) => setCoOwnershipInterest("No")}/>
                              <label>No</label>
                            </div>
                          </div>
                      </div>
                  </div>
                  <div className={styles.inputsContainer}>
                      <div className={styles.coborrowerRadio}>
                      <br/>
                      <div className={styles.errorAndInputDiv}>
                        {coComakerError != "" && (<div className={styles.errorDiv}>{coComakerError}</div>)}
                          <div className={styles.declarationsDiv}>
                              <div className={styles.label}>
                                  Are you a co-maker or endorser on a note?
                              </div>
                              <div className={styles.blankSpace7}/>
                              <input type="radio" id="other" name="ownership" onChange={(e) => setCoComaker("Yes")}/>
                              <label>Yes   </label>
                              <input type="radio" id="other" name="ownership" onChange={(e) => setCoComaker("No")}/>
                              <label>No</label>
                          </div>
                          </div>
                      </div>
                  </div>
                  <div className={styles.inputsContainer}>
                      <div className={styles.coborrowerRadio}>
                      <br/>
                      <div className={styles.errorAndInputDiv}>
                        {coUsCitizenError != "" && (<div className={styles.errorDiv}>{coUsCitizenError}</div>)}
                          <div className={styles.declarationsDiv}>
                              <div className={styles.label}>
                                  Are you a U.S. citizen or alien?
                              </div>
                              <div className={styles.blankSpace7}/>
                              <div>
                                  <input type="radio" id="other" name="citizen" onChange={(e) => setCoUsCitizen("USCitizen")}/>
                                  <label>U.S. Citizen</label>
                              </div>
                              <div>
                                  <input type="radio" id="other" name="citizen" onChange={(e) => setCoUsCitizen("PermanentAlien")}/>
                                  <label>Permanent resident alien</label>
                              </div>
                              <div>
                                  <input type="radio" id="other" name="citizen" onChange={(e) => setCoUsCitizen("NonpermanentAlien")}/>
                                  <label>Non-permanent resident alien</label>
                              </div>
                          </div>
                          </div>
                      </div>
                  </div>
                  <div>
              </div>
              </div>
            </div>
          </div>

        </div>
        <div className={styles.myLoanerContainer}>
             <div className={styles.myLoanerContent}>
              <YourLoanOfficer
                name={"Krishna Malyala"}
                email={"kmalyala@nexamortgage.com"}
                phone={"334-625-9252"}
                nmls1={"NMLS #1875937"}
                states={"NJ"}
                nmls2={"NMLS #1660690"}
              />
            </div>      
            
          </div>
      </div>
    </div>
  </div>
  );
}

