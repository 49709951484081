import * as React from "react";
import SideMenuBar from "./SideMenuBar";
import NavBar from "./NavBar";
import styles from "./ApplyForLoan.module.css";
import YourLoanOfficer from "./YourLoanOfficer";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import helpers from "../helpers";

export default function AFLConfirmationForm() {
  const navigate = useNavigate();

  const [borrowerTab, setBorrowerTab] = useState(styles.active);
  const [coBorrowerTab, setCoBorrowerTab] = useState(styles.a);
  const [borrowerDiv, setBorrowerDiv] = useState(styles.borrowerFormCardTabs);
  const [coBorrowerDiv, setCoBorrowerDiv] = useState(
    styles.borrowerFormCardTabsInvisible
  );

  const authUser = useSelector((state) => state.users);
  const [userData, setUserData] = useState("");

  const [personalInfoItems, setPersonalInfoItems] = useState("");
  const [personalInfo2Items, setPersonalInfo2Items] = useState("");
  const [propertyItems, setPropertyItems] = useState("");
  const [addressItems, setAddressItems] = useState("");
  const [downPaymentItems, setDownPaymentItems] = useState("");
  const [employmentItems, setEmploymentItems] = useState("");
  const [incomeItems, setIncomeItems] = useState("");
  const [assetsItems, setAssestsItems] = useState("");
  const [liabilitiesItems, setLiabilitiesItems] = useState("");
  const [realEstateItems, setRealEstateItems] = useState("");
  const [declarationItems, setDeclarationItems] = useState("");
  const [raceAndEthnicityItems, setRaceAndEthnicityItems] = useState("");
  const [sexInfoItems, setSexInfoItems] = useState("");

  function updateBorrowerTab() {
    setBorrowerTab(styles.active);
    setCoBorrowerTab(styles.a);
    setBorrowerDiv(styles.borrowerFormCardTabs);
    setCoBorrowerDiv(styles.borrowerFormCardTabsInvisible);
  }

  function updateCoBorrowerTab() {
    setBorrowerTab(styles.a);
    setCoBorrowerTab(styles.active);
    setBorrowerDiv(styles.borrowerFormCardTabsInvisible);
    setCoBorrowerDiv(styles.borrowerFormCardTabs);
  }

  const handleForm = async (event) => {
    event.preventDefault();

    navigate("/");
  };

  const getUserData = async () => {
    const clientId = authUser[0].id;
    const url =
      "https://uat-api.tlcengine.com/v3/api/mlspin-md/clients/" + clientId;
    const token = authUser[0].user.AccessToken;
    const user = await helpers.getProfile(url, token);
    setUserData(user.data);
  };

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (userData) {
      setAddressItems(
        getDataAsListItems(userData.Profile.ApplyForLoanData.AddressForm)
      );
      setPersonalInfoItems(
        getDataAsListItems(userData.Profile.ApplyForLoanData.BorrowerNameForm.borrower)
      );
      setPersonalInfo2Items(
        getDataAsListItems(
          userData.Profile.ApplyForLoanData.BorrowerHomeInfoForm
        )
      );
      setPropertyItems(
        getDataAsListItems(userData.Profile.ApplyForLoanData.PropertyInfoForm)
      );
      setDownPaymentItems(
        getDataAsListItems(userData.Profile.ApplyForLoanData.DownPaymentForm)
      );
      setEmploymentItems(
        getDataAsListItems(userData.Profile.ApplyForLoanData.EmploymentForm.borrower)
      );
      setIncomeItems(
        getDataAsListItems(userData.Profile.ApplyForLoanData.IncomeForm.borrower)
      );
      setAssestsItems(
        getDataAsListItems(userData.Profile.ApplyForLoanData.AssetsForm.borrower)
      );
      setLiabilitiesItems(
        getDataAsListItems(userData.Profile.ApplyForLoanData.LiabilitiesForm.borrower)
      );
      setRealEstateItems(
        getDataAsListItems(userData.Profile.ApplyForLoanData.RealEstateForm.borrower)
      );
      setDeclarationItems(
        getDataAsListItems(userData.Profile.ApplyForLoanData.DeclarationsForm.borrower)
      );
      setRaceAndEthnicityItems(
        getDataAsListItems(
          userData.Profile.ApplyForLoanData.GovernmentRaceInfoForm.borrower
        )
      );
      setSexInfoItems(
        getDataAsListItems(
          userData.Profile.ApplyForLoanData.GovernmentSexInfoForm.borrower
        )
      );
    }
  }, [userData]);

  const getDataAsListItems = (form) => {
    if (userData) {
      return Object.keys(form).map((key) => {
        if (key === "coborrower") {
          return
        }
        else if (key === "ssn") {
          return (
            <li key={key}>
              {key}:{" xxx-xx-"}
              {form[key].substring(form[key].length - 4)}
            </li>
          );
        } else {
          return (
            <li key={key}>
              {key}: {form[key]}
            </li>
          );
        }
      });
    }
  };

  return (
    <div className={styles.applyScreen}>
      <NavBar />
      <div className={styles.applyContainer}>
        <div className={styles.applyContent}>
          <SideMenuBar activeClass={"applyForLoan"} />
          <div className={styles.formContainer}>
            <div className={styles.rectangleTabs} />
            <ul className={styles.tabs}>
              <li className={borrowerTab}>
                <button onClick={updateBorrowerTab}>Borrower</button>
              </li>
              <li className={coBorrowerTab}>
                <button onClick={updateCoBorrowerTab}>Co-Borrower</button>
              </li>
            </ul>

            <div id="borrower" className={borrowerDiv}>
              <div className={styles.borrowerFormCardDiv}>
                <div className={styles.borrowerFormCardTitleText}>
                  <div className={styles.borrowerFormTitle}>
                    Borrower Confirmation of Information
                  </div>
                  <div className={styles.blankSpaceConfirmInfo}>
                    <h2>Personal Information</h2>
                    <ul>{personalInfoItems}</ul>
                    <ul>{personalInfo2Items}</ul>
                    <h2>Address Information</h2>
                    <ul>{addressItems}</ul>
                    <h2>Property Information</h2>
                    <ul>{propertyItems}</ul>
                    <h2>Downpayment Information</h2>
                    <ul>{downPaymentItems}</ul>
                    <h2>Employment Information</h2>
                    <ul>{employmentItems}</ul>
                    <h2>Income Information</h2>
                    <ul>{incomeItems}</ul>
                    <h2>Assets Information</h2>
                    <ul>{assetsItems}</ul>
                    <h2>Liabilities Information</h2>
                    <ul>{liabilitiesItems}</ul>
                    <h2>Real Estate Information</h2>
                    <ul>{realEstateItems}</ul>
                    <h2>Declarations Information</h2>
                    <ul>{declarationItems}</ul>
                    <h2>Race/Ethnicity Information</h2>
                    <ul>{raceAndEthnicityItems}</ul>
                    <h2>Sex Information</h2>
                    <ul>{sexInfoItems}</ul>
                  </div>
                </div>
              </div>
              <div className={styles.nextButtonDiv}>
                <button
                  className={styles.backButton}
                  onClick={() => navigate("/apply-for-loan/borrowerform14")}
                >
                  Back
                </button>
                <button className={styles.nextButton} onClick={handleForm}>
                  Next
                </button>
              </div>
            </div>

            <div id="coborrower" className={coBorrowerDiv}>
              <div className={styles.borrowerFormCardDiv}>
                <div className={styles.borrowerFormCardTitleText}>
                  <div className={styles.borrowerFormTitle}>
                    Co-Borrower Confirmation of Information
                  </div>
                  <div className={styles.blankSpaceConfirmInfo} />
                </div>
              </div>
            </div>
          </div>
          <div className={styles.myLoanerContainer}>
            <div className={styles.myLoanerContent}>
              <YourLoanOfficer
                name={"Krishna Malyala"}
                email={"kmalyala@nexamortgage.com"}
                phone={"334-625-9252"}
                nmls1={"NMLS #1875937"}
                states={"NJ"}
                nmls2={"NMLS #1660690"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
