import * as React from "react";
import SideMenuBar from "./SideMenuBar";
import NavBar from './NavBar';
import styles from './ApplyForLoan.module.css'
import YourLoanOfficer from "./YourLoanOfficer";
import { Link, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import actions from "../actions";
import helpers from "../helpers";

const deepClone = obj => {
  if (obj === null) return null;
  let clone = Object.assign({}, obj);
  Object.keys(clone).forEach(
    key =>
      (clone[key] =
        typeof obj[key] === 'object' ? deepClone(obj[key]) : obj[key])
  );
  if (Array.isArray(obj)) {
    clone.length = obj.length;
    return Array.from(clone);
  }
  return clone;
};

export default function AFLRealEstateForm() {
  const navigate = useNavigate(); 

  const [coborrowerSelected, setCoborrowerSelected] = useState(false);

  const [estateInputsError, setEstateInputsError] = useState("");
  const [coEstateInputsError, setCoEstateInputsError] = useState("");

  const [addressError, setAddressError] = useState('');
  const [statusError, setStatusError] = useState('');
  const [valueError, setValueError] = useState('');
  const [mortgageError, setMortgageError] = useState('');
  const [paymentsError, setPaymentsError] = useState('');
  const [insuranceError, setInsuranceError] = useState('');
  const [grossRentalError, setGrossRentalError] = useState('');

  const [coAddressError, setCoAddressError] = useState('');
  const [coStatusError, setCoStatusError] = useState('');
  const [coValueError, setCoValueError] = useState('');
  const [coMortgageError, setCoMortgageError] = useState('');
  const [coPaymentsError, setCoPaymentsError] = useState('');
  const [coInsuranceError, setCoInsuranceError] = useState('');
  const [coGrossRentalError, setCoGrossRentalError] = useState('');

  const [estateInputs, setEstateInputs] = useState([]);
  const [oldInputs, setOldInputs] = useState(false);

  const [coEstateInputs, setCoEstateInputs] = useState([]);
  const [coOldInputs, setCoOldInputs] = useState(false);

  const [address, setAddress] = useState('');
  const [status, setStatus] = useState('');
  const [value, setValue] = useState('');
  const [mortgage, setMortgage] = useState('');
  const [payments, setPayments] = useState('');
  const [insurance, setInsurance] = useState('');
  const [grossRental, setGrossRental] = useState('');

  const [coAddress, setCoAddress] = useState('');
  const [coStatus, setCoStatus] = useState('');
  const [coValue, setCoValue] = useState('');
  const [coMortgage, setCoMortgage] = useState('');
  const [coPayments, setCoPayments] = useState('');
  const [coInsurance, setCoInsurance] = useState('');
  const [coGrossRental, setCoGrossRental] = useState('');

  const [borrowerTab, setBorrowerTab] = useState(styles.active);
  const [coBorrowerTab, setCoBorrowerTab] = useState(styles.a);
  const [borrowerDiv, setBorrowerDiv] = useState(styles.borrowerFormCardTabs);
  const [coBorrowerDiv, setCoBorrowerDiv] = useState(styles.borrowerFormCardTabsInvisible);

  const authUser = useSelector((state) => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    if(authUser[0].data.Profile.ApplyForLoanData.BorrowerNameForm) {
      setCoborrowerSelected(authUser[0].data.Profile.ApplyForLoanData.BorrowerNameForm.borrower.coborrowerSelected)
    }
    if(authUser[0].data.Profile.ApplyForLoanData.RealEstateForm) {
      if(authUser[0].data.Profile.ApplyForLoanData.RealEstateForm.borrower) {
        setOldInputs(true);
      }
      if(authUser[0].data.Profile.ApplyForLoanData.RealEstateForm.coborrower) {
        setCoOldInputs(true);
      }
    }
  }, [authUser])

  useEffect(()=> {
    if(authUser[0].data.Profile.ApplyForLoanData.RealEstateForm) {
      if(authUser[0].data.Profile.ApplyForLoanData.RealEstateForm.borrower && oldInputs === true) {
        let oldInputVals = authUser[0].data.Profile.ApplyForLoanData.RealEstateForm.borrower;
        for(let i = 0; i < oldInputVals.length; i++){
          addEstatesInitial(oldInputVals[i][0], oldInputVals[i][1], oldInputVals[i][2], oldInputVals[i][3], oldInputVals[i][4], oldInputVals[i][5], oldInputVals[i][6], "list");
        }
      }
    }
  }, [oldInputs])

  useEffect(()=> {
    if(authUser[0].data.Profile.ApplyForLoanData.RealEstateForm) {
      if(authUser[0].data.Profile.ApplyForLoanData.RealEstateForm.coborrower && coOldInputs === true) {
        let oldInputVals = authUser[0].data.Profile.ApplyForLoanData.RealEstateForm.coborrower;
        for(let i = 0; i < oldInputVals.length; i++){
          addEstatesInitial(oldInputVals[i][0], oldInputVals[i][1], oldInputVals[i][2], oldInputVals[i][3], oldInputVals[i][4], oldInputVals[i][5], oldInputVals[i][6], "colist");
        }
      }
    }
  }, [coOldInputs])

  const addEstatesInitial = (currentAddress, currentStatus, currentValue, currentMortgage, currentPayments, currentInsurance, currentGrossRental, list) => {
    let errors = 0;

    if(errors === 0)
    {
      setEstateInputsError("");
      setCoEstateInputsError("");
      let source = [currentAddress, currentStatus, currentValue, currentMortgage, currentPayments, currentInsurance, currentGrossRental];
      if(list === "list"){
        setEstateInputs(arr => [...arr, source]);
      } else {
        setCoEstateInputs(arr => [...arr, source]);
      }

      let li = document.createElement("li");
      let text = document.createTextNode(currentAddress + " - " + currentStatus + " - " + currentValue + " - " + currentMortgage + " " + currentPayments + " " + currentInsurance + " " + currentGrossRental);
      li.id = currentAddress + " " + currentStatus + " " + currentValue + " " + currentMortgage + " " + currentPayments + " " + currentInsurance + " " + currentGrossRental + " " + list;
      li.appendChild(text);

      let removeBtn = document.createElement("input")
      removeBtn.type = "button";
      removeBtn.value = "Remove";
      removeBtn.className = styles.removeButton
      removeBtn.addEventListener("click", function() {
        let elementToRemove = li.id.trim().split(" ");
        if(elementToRemove[elementToRemove.length - 1] === "list"){
          setEstateInputs(arr => arr.filter(estate => !estatesEqual(estate, li.id, list)))
        } else {
          setCoEstateInputs(arr => arr.filter(estate => !estatesEqual(estate, li.id, list)))
        }
        li.remove();
      })
      li.appendChild(removeBtn)

      document.getElementById(list).appendChild(li);
    }
  }

  useEffect(()=> {
    console.log(estateInputs);
  }, [estateInputs])

  const addEstates = (list) => {
    let errors = 0;

    if(list === "list"){
      try{
        helpers.validString(address, "Address");
        setAddressError("");
      } catch(e) {
        setAddressError(e.message);
        errors ++;
      }

      try{
        helpers.validRealEstateStatus(status);
        setStatusError("");
      } catch(e) {
        setStatusError(e.message);
        errors ++;
      }

      try{
        helpers.validInt(value, "Market Value");
        setValueError("");
      } catch(e) {
        setValueError(e.message);
        errors ++;
      }

      try{
        helpers.validInt(mortgage, "Mortgage Amount");
        setMortgageError("");
      } catch(e) {
        setMortgageError(e.message);
        errors ++;
      }

      try{
        helpers.validInt(payments, "Payments");
        setPaymentsError("");
      } catch(e) {
        setPaymentsError(e.message);
        errors ++;
      }

      try{
        helpers.validInt(insurance, "Insurance");
        setInsuranceError("");
      } catch(e) {
        setInsuranceError(e.message);
        errors ++;
      }

      try{
        helpers.validInt(grossRental, "Gross Rental");
        setGrossRentalError("");
      } catch(e) {
        setGrossRentalError(e.message);
        errors ++;
      }
    } else {
      try{
        helpers.validString(coAddress, "Address");
        setCoAddressError("");
      } catch(e) {
        setCoAddressError(e.message);
        errors ++;
      }

      try{
        helpers.validRealEstateStatus(coStatus);
        setCoStatusError("");
      } catch(e) {
        setCoStatusError(e.message);
        errors ++;
      }

      try{
        helpers.validInt(coValue, "Market Value");
        setCoValueError("");
      } catch(e) {
        setCoValueError(e.message);
        errors ++;
      }

      try{
        helpers.validInt(coMortgage, "Mortgage Amount");
        setCoMortgageError("");
      } catch(e) {
        setCoMortgageError(e.message);
        errors ++;
      }

      try{
        helpers.validInt(coPayments, "Payments");
        setCoPaymentsError("");
      } catch(e) {
        setCoPaymentsError(e.message);
        errors ++;
      }

      try{
        helpers.validInt(coInsurance, "Insurance");
        setCoInsuranceError("");
      } catch(e) {
        setCoInsuranceError(e.message);
        errors ++;
      }

      try{
        helpers.validInt(coGrossRental, "Gross Rental");
        setCoGrossRentalError("");
      } catch(e) {
        setCoGrossRentalError(e.message);
        errors ++;
      }
    }

    if(errors === 0)
    {
      setEstateInputsError("");
      let source = []
      if(list === "list"){
        source = [address, status, value, mortgage, payments, insurance, grossRental];
        setEstateInputs(arr => [...arr, source]);
      } else {
        source = [coAddress, coStatus, coValue, coMortgage, coPayments, coInsurance, coGrossRental];
        setCoEstateInputs(arr => [...arr, source]);
      }

      let li = document.createElement("li");
      let text = document.createTextNode(source[0] + " - " + source[1] + " - " + source[2] + " - " + source[3] + " - " + source[4] + " - " + source[5] + " - " + source[6]);
      li.id = source[0] + " " + source[1] + " " + source[2] + " " + source[3] + " " + source[4] + " " + source[5] + " " + source[6] + " " + list;
      li.appendChild(text);

      let removeBtn = document.createElement("input")
      removeBtn.type = "button";
      removeBtn.value = "Remove";
      removeBtn.className = styles.removeButton
      removeBtn.addEventListener("click", function() {
        let elementToRemove = li.id.trim().split(" ");
        if(elementToRemove[elementToRemove.length - 1] === "list"){
          setEstateInputs(arr => arr.filter(estate => !estatesEqual(estate, li.id, list)))
        } else {
          setCoEstateInputs(arr => arr.filter(estate => !estatesEqual(estate, li.id, list)))
        }
        li.remove();
      })
      li.appendChild(removeBtn)

      document.getElementById(list).appendChild(li);
    }
  }

  const estatesEqual = (a, b, list) => {
    let temp = a[0] + " " + a[1] + " " + a[2] + " " + a[3] + " " + a[4] + " " + a[5] + " " + a[6] + " " + list;
    if(temp === b ){
      return true;
    } else {
      return false
    }
  }

  function updateBorrowerTab() {
    setBorrowerTab(styles.active)
    setCoBorrowerTab(styles.a)
    setBorrowerDiv(styles.borrowerFormCardTabs)
    setCoBorrowerDiv(styles.borrowerFormCardTabsInvisible)
  }

  function updateCoBorrowerTab() {
    setBorrowerTab(styles.a)
    setCoBorrowerTab(styles.active)
    setBorrowerDiv(styles.borrowerFormCardTabsInvisible)
    setCoBorrowerDiv(styles.borrowerFormCardTabs)
  }

  useEffect(() => {
    if(authUser[0].data.Profile.ApplyForLoanData.RealEstateForm) {
      if(authUser[0].data.Profile.ApplyForLoanData.RealEstateForm.address) {
        setAddress(authUser[0].data.Profile.ApplyForLoanData.RealEstateForm.address)
      }
      if(authUser[0].data.Profile.ApplyForLoanData.RealEstateForm.value) {
        setValue(authUser[0].data.Profile.ApplyForLoanData.RealEstateForm.value)
      }
      if(authUser[0].data.Profile.ApplyForLoanData.RealEstateForm.mortgage) {
        setMortgage(authUser[0].data.Profile.ApplyForLoanData.RealEstateForm.mortgage)
      }
      if(authUser[0].data.Profile.ApplyForLoanData.RealEstateForm.payments) {
        setPayments(authUser[0].data.Profile.ApplyForLoanData.RealEstateForm.payments)
      }
      if(authUser[0].data.Profile.ApplyForLoanData.RealEstateForm.insurance) {
        setInsurance(authUser[0].data.Profile.ApplyForLoanData.RealEstateForm.insurance)
      }
      if(authUser[0].data.Profile.ApplyForLoanData.RealEstateForm.grossRental) {
        setGrossRental(authUser[0].data.Profile.ApplyForLoanData.RealEstateForm.grossRental);
      }
    }
  }, [authUser])

  const handleForm = async (event) => {
    event.preventDefault()

    if(estateInputs.length === 0){
      setEstateInputsError("You must add at real estate info");
    } else if (coborrowerSelected && coEstateInputs.length === 0){
      setEstateInputsError("You must add real estate info for your coborrower");
    }else {
      setEstateInputsError("")
      await localStorage();
    }
  };
  
  const localStorage = async () => {
    let estateInfo = {
      borrower: estateInputs,
      coborrower: coEstateInputs
    }

    const clientId = authUser[0].id;
    const url = "https://uat-api.tlcengine.com/v3/api/mlspin-md/clients/" + clientId;
    const token = authUser[0].user.AccessToken;

    let userData = await helpers.getProfile(url, token);
    let copyUserData = helpers.updateData(userData, "RealEstateForm", estateInfo);
    await helpers.updateProfile(copyUserData, url, token);

    let thisClient = deepClone(authUser[0]);
    thisClient.data = copyUserData;
    dispatch(actions.addClientPI(thisClient));

    navigate("/apply-for-loan/borrowerform11")
  }

  return (
    <div className={styles.applyScreen}>
      <NavBar/>
      <div className={styles.applyContainer}>
        <div className={styles.applyContent}>
          <SideMenuBar activeClass={"applyForLoan"}/>
          <div className={styles.formContainer}>
          <div className={styles.rectangleTabs}/>
            <ul className={styles.tabs}>
              <li className={borrowerTab}><button onClick={updateBorrowerTab}>Borrower</button></li> 
              {coborrowerSelected && <li className={coBorrowerTab}><button onClick={updateCoBorrowerTab}>Co-Borrower</button></li>}
            </ul>

            <div id="borrower" className={borrowerDiv}>
              <div className={styles.borrowerFormCardDiv}>
                <div className={styles.borrowerFormCardTitleText}>
                  <div className={styles.borrowerFormTitle}>
                    Borrower Real Estate Owned
                  </div>
                  <br/>
                </div>
                <div className={styles.errorAndInputDiv}>
                  {estateInputsError != "" && <div className={styles.errorDiv}>{estateInputsError}</div>}
                </div>
                <div className={styles.inputsContainer}>
                  <div className={styles.uldiv}>
                    <ul id="list"></ul>
                  </div>
                </div>
                <div >
                  <div className={styles.inputsContainer}>
                    <div className={styles.errorAndInputDiv}>
                      {addressError != "" && <div className={styles.errorDiv}>{addressError}</div>}
                      <div className={styles.inputDiv}>
                          <input
                              onChange={(e) => setAddress(e.target.value)}
                              name="addressInput"
                              type="text"
                              className={styles.formInput}
                              placeholder={"Property Address"}
                              value={address}
                          />
                      </div>
                    </div>
                    <div className={styles.errorAndInputDiv}>
                      {statusError != "" && <div className={styles.errorDiv}>{statusError}</div>}
                      <div className={styles.inputDiv}>
                          <div className={styles.dropdown}>
                              <select className={styles.selectBox}
                                onChange={(e) => setStatus(e.target.value)}
                              >
                                  <option>Property Status</option>
                                  <option>Active</option>
                                  <option>Active with Bump</option>
                                  <option>Active without Bump</option>
                                  <option>Active with First Right of Refusal</option>
                                  <option>Contingent</option>
                                  <option>Pending</option>
                                  <option>Sold</option>
                                  <option>Coming Soon</option>
                                  <option>Other</option>
                              </select>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.inputsContainer}>
                    <div className={styles.errorAndInputDiv}>
                      {valueError != "" && <div className={styles.errorDiv}>{valueError}</div>}
                      <div className={styles.inputDiv}>
                        <input
                            onChange={(e) => setValue(e.target.value)}
                            name="valueInput"
                            type="text"
                            className={styles.formInput}
                            placeholder={"$ Market Value"}
                            value={value}
                        />
                      </div>
                    </div>
                    <div className={styles.errorAndInputDiv}>
                      {mortgageError != "" && <div className={styles.errorDiv}>{mortgageError}</div>}
                      <div className={styles.inputDiv}>
                        <input
                            onChange={(e) => setMortgage(e.target.value)}
                            name="mortgageInput"
                            type="text"
                            className={styles.formInput}
                            placeholder={"$ Amount of Mortgage & Liens"}
                            value={mortgage}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.inputsContainer}>
                    <div className={styles.errorAndInputDiv}>
                      {paymentsError != "" && <div className={styles.errorDiv}>{paymentsError}</div>}
                      <div className={styles.inputDiv}>
                        <input
                            onChange={(e) => setPayments(e.target.value)}
                            name="paymentsInput"
                            type="text"
                            className={styles.formInput}
                            placeholder={"$ Mortgage Payments"}
                            value={payments}
                        />
                      </div>
                    </div>
                    <div className={styles.errorAndInputDiv}>
                      {insuranceError != "" && <div className={styles.errorDiv}>{insuranceError}</div>}
                      <div className={styles.inputDiv}>
                        <input
                            onChange={(e) => setInsurance(e.target.value)}
                            name="insuranceInput"
                            type="text"
                            className={styles.formInput}
                            placeholder={"$ Insurance, Taxes, Misc"}
                            value={insurance}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.inputsContainer}>
                    <div className={styles.errorAndInputDiv}>
                      {grossRentalError != "" && <div className={styles.errorDiv}>{grossRentalError}</div>}
                      <div className={styles.inputDiv}>
                        <input
                            onChange={(e) => setGrossRental(e.target.value)}
                            name="paymentsInput"
                            type="text"
                            className={styles.formInput}
                            placeholder={"$ Gross Rental Income"}
                            value={grossRental}
                        />
                      </div>
                    </div>
                    <button className={styles.newIncomeButton} onClick={() => addEstates("list")}>Add Another Real Estate Property</button>
                  </div>
                  <div>
                </div>
              </div>
              <div className={styles.nextButtonDiv}>
                <button className={styles.backButton} onClick={()=>navigate("/apply-for-loan/borrowerform9")}>Back</button>
                <button className={styles.nextButton} onClick={handleForm}>Next</button>
              </div>
            </div>
          </div>

          <div id="coborrower" className={coBorrowerDiv}>
            <div className={styles.borrowerFormCardDiv}>
                <div className={styles.borrowerFormCardTitleText}>
                  <div className={styles.borrowerFormTitle}>
                    Co-Borrower Real Estate Owned
                  </div>
                </div>
                <div className={styles.errorAndInputDiv}>
                  {estateInputsError != "" && <div className={styles.errorDiv}>{estateInputsError}</div>}
                </div>
                <div><br/></div>
                <div className={styles.inputsContainer}>
                  <div className={styles.uldiv}>
                    <ul id="colist"></ul>
                  </div>
                </div>
                <div >
                  <div className={styles.inputsContainer}>
                    <div className={styles.errorAndInputDiv}>
                      {coAddressError != "" && <div className={styles.errorDiv}>{coAddressError}</div>}
                      <div className={styles.inputDiv}>
                          <input
                              onChange={(e) => setCoAddress(e.target.value)}
                              name="addressInput"
                              type="text"
                              className={styles.formInput}
                              placeholder={"Property Address"}
                          />
                      </div>
                    </div>
                    <div className={styles.errorAndInputDiv}>
                      {coStatusError != "" && <div className={styles.errorDiv}>{coStatusError}</div>}
                      <div className={styles.inputDiv}>
                          <div className={styles.dropdown}>
                              <select className={styles.selectBox}
                                onChange={(e) => setCoStatus(e.target.value)}
                              >
                                 <option>Property Status</option>
                                  <option>Active</option>
                                  <option>Active with Bump</option>
                                  <option>Active without Bump</option>
                                  <option>Active with First Right of Refusal</option>
                                  <option>Contingent</option>
                                  <option>Pending</option>
                                  <option>Sold</option>
                                  <option>Coming Soon</option>
                                  <option>Other</option>
                              </select>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.inputsContainer}>
                    <div className={styles.errorAndInputDiv}>
                      {coValueError != "" && <div className={styles.errorDiv}>{coValueError}</div>}
                      <div className={styles.inputDiv}>
                        <input
                            onChange={(e) => setCoValue(e.target.value)}
                            name="valueInput"
                            type="text"
                            className={styles.formInput}
                            placeholder={"$ Market Value"}
                        />
                      </div>
                    </div>
                    <div className={styles.errorAndInputDiv}>
                      {coMortgageError != "" && <div className={styles.errorDiv}>{coMortgageError}</div>}
                      <div className={styles.inputDiv}>
                        <input
                            onChange={(e) => setCoMortgage(e.target.value)}
                            name="mortgageInput"
                            type="text"
                            className={styles.formInput}
                            placeholder={"$ Amount of Mortgage & Liens"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.inputsContainer}>
                    <div className={styles.errorAndInputDiv}>
                      {coPaymentsError != "" && <div className={styles.errorDiv}>{coPaymentsError}</div>}
                      <div className={styles.inputDiv}>
                        <input
                            onChange={(e) => setCoPayments(e.target.value)}
                            name="paymentsInput"
                            type="text"
                            className={styles.formInput}
                            placeholder={"$ Mortgage Payments"}
                        />
                      </div>
                    </div>
                    <div className={styles.errorAndInputDiv}>
                      {coInsuranceError != "" && <div className={styles.errorDiv}>{coInsuranceError}</div>}
                      <div className={styles.inputDiv}>
                        <input
                            onChange={(e) => setCoInsurance(e.target.value)}
                            name="insuranceInput"
                            type="text"
                            className={styles.formInput}
                            placeholder={"$ Insurance, Taxes, Misc"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.inputsContainer}>
                    <div className={styles.errorAndInputDiv}>
                      {coGrossRentalError != "" && <div className={styles.errorDiv}>{coGrossRentalError}</div>}
                      <div className={styles.inputDiv}>
                        <input
                            onChange={(e) => setCoGrossRental(e.target.value)}
                            name="paymentsInput"
                            type="text"
                            className={styles.formInput}
                            placeholder={"$ Gross Rental Income"}
                        />
                      </div>
                    </div>
                    <button className={styles.newIncomeButton} onClick={() => addEstates("colist")}>Add Another Real Estate Property</button>
                  </div>
                  <div>
              </div>
              </div>
            </div>
          </div>


        </div>
        <div className={styles.myLoanerContainer}>
             <div className={styles.myLoanerContent}>
              <YourLoanOfficer
                name={"Krishna Malyala"}
                email={"kmalyala@nexamortgage.com"}
                phone={"334-625-9252"}
                nmls1={"NMLS #1875937"}
                states={"NJ"}
                nmls2={"NMLS #1660690"}
              />
            </div>      
            
            </div>
        </div>
      </div>
    </div>
    );
  }

