import styles from "./yourLoanOfficer.module.css"

export default function YourLoanOfficer({
    name, 
    email, 
    phone, 
    nmls1,
    states,
    nmls2}) 
    {
    return (
        <>
              <div className={styles.loanOfficerCardContent}
              >
                <div className={styles.yourLoanOfficer}
                >
                  Your Loan Officer
                </div>
                <div className={styles.loanOfficerInfo}
                >
                  {name} <br />
                  Originator
                  <br />
                  {email}
                  <br />
                  {phone}
                  <br />
                  {nmls1}
                </div>
                <div className={styles.loanOfficerInfo}
                >
                  NEXA Mortgage Lender
                </div>
                <div className={styles.loanOfficerInfo}
                >
                  {states} <br />
                  {nmls2}
                </div>
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/8050e5a6-dbae-43b5-ad02-d08051cdeb1d?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/8050e5a6-dbae-43b5-ad02-d08051cdeb1d?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/8050e5a6-dbae-43b5-ad02-d08051cdeb1d?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/8050e5a6-dbae-43b5-ad02-d08051cdeb1d?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/8050e5a6-dbae-43b5-ad02-d08051cdeb1d?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/8050e5a6-dbae-43b5-ad02-d08051cdeb1d?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/8050e5a6-dbae-43b5-ad02-d08051cdeb1d?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/8050e5a6-dbae-43b5-ad02-d08051cdeb1d?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&"
                  className={styles.homeLogo}
                />
              </div>
              <div className={styles.callAndEmail}
              >
                <div
                  className={styles.callAndEmailButton}
                >
                  <div className={styles.call}
                  >
                    Call
                  </div>
                </div>
                <div className={styles.callAndEmailButton}
                    >
                    <div className={styles.call}
                    >
                        Email
                    </div>
                </div>
            </div>
    </>
    );
}
