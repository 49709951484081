import * as React from "react";
import SideMenuBar from "./SideMenuBar";
import NavBar from './NavBar';

export default function Profile() {
  return (
    <div>
        <NavBar/>
        <SideMenuBar activeClass={"profile"}/>
    </div>
  );
}
