import * as React from "react";
import SideMenuBar from "./SideMenuBar";
import NavBar from './NavBar';
import styles from './ApplyForLoan.module.css'
import YourLoanOfficer from "./YourLoanOfficer";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import actions from "../actions";

export default function CSConfirmInfo() {
  const navigate = useNavigate(); 

  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const authUser = useSelector((state) => state.users)
  const clientState = useSelector((state) => state.client);

  //console.log(clientState)
  
  let firstName = "";
  let lastName = "";
  let dob = "";
  let address = "";
  let city = "";
  let state = "";
  let zip1 = "";
  let social = "";

  if(authUser[0].data.Profile.CreditScoreData) {
    firstName = authUser[0].data.Profile.CreditScoreData.firstName;
    lastName = authUser[0].data.Profile.CreditScoreData.lastName;
    dob = authUser[0].data.Profile.CreditScoreData.dob;
    address = authUser[0].data.Profile.CreditScoreData.address;
    city = authUser[0].data.Profile.CreditScoreData.city;
    state = authUser[0].data.Profile.CreditScoreData.state;
    zip1 = authUser[0].data.Profile.CreditScoreData.zip1;
    social = authUser[0].data.Profile.CreditScoreData.social;
  }

  const handleForm = async (event) => {
    event.preventDefault()

    var data = {
      email: email,
      phone: phone,
    }

    console.log(data);

    navigate("/credit-score/confirmchoice")
  };

  return (
    <div className={styles.applyScreen}>
      <NavBar/>
      <div className={styles.applyContainer}>
        <div className={styles.applyContent}>
          <SideMenuBar activeClass={"creditScore"}/>
          <div className={styles.formContainer}>
            <div className={styles.rectangle}/>
            <div className={styles.borrowerFormCard}>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/60c78b5b-549d-4212-9133-2c665550b970?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&"
                className={styles.imgLogo}
              />
              <div className={styles.welcomeMessageDiv}>Welcome, {firstName}</div>
              <div className={styles.miniCardContainer}>
                <div className={styles.miniCard}>
                  <div className={styles.infoCardTitle}>
                  Information you have provided
                  </div>
                  <div className={styles.miniCardContainerSplit}>
                    <div className={styles.inputDivMiniCard}>
                      <div>
                        <div className={styles.infoCardTextLabel}>
                          Name
                        </div>
                        <div className={styles.infoCardText}>
                          {firstName} {lastName}
                        </div>
                      </div>
                    </div>
                    <div className={styles.inputDivMiniCard}>
                      <div>
                        <div className={styles.infoCardTextLabel}>
                          Date of Birth
                        </div>
                        <div className={styles.infoCardText}>
                          {dob}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.miniCardContainerSplit}>
                    <div className={styles.inputDivMiniCard}>
                      <div>
                        <div className={styles.infoCardTextLabel}>
                          Current Address
                        </div>
                        <div className={styles.infoCardText}>
                          {address},
                        </div>
                        <div className={styles.infoCardText}>
                          {city},
                        </div>
                        <div className={styles.infoCardText}>
                          {state} {zip1}
                        </div> 
                      </div>
                    </div>
                    <div className={styles.inputDivMiniCard}>
                      <div>
                        <div className={styles.infoCardTextLabel}>
                          SSN
                        </div>
                        <div className={styles.infoCardText}>
                          {social}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.miniCard}>
                  <div className={styles.infoCardTitle}>
                    Additional Information Required
                  </div>
                  <div className={styles.inputsContainerMiniCard}>
                    <div className={styles.inputDiv}>
                      <input
                        onChange={(e) => setEmail(e.target.value)}
                        name="emailInput"
                        type="text"
                        className={styles.formInput}
                        placeholder={"Email Address"}
                      />
                    </div>
                  </div>
                  <div className={styles.inputsContainerMiniCard}>
                    <div className={styles.inputsDivMiniCard}>
                      <input
                        onChange={(e) => setPhone(e.target.value)}
                        name="phoneNumberInput"
                        type="text"
                        className={styles.formInput}
                        placeholder={"Phone Number"}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.carButtonDiv}>
                <button className={styles.cardButton} onClick={handleForm}>Continue</button>
              </div>
            </div>
          </div>
          <div className={styles.myLoanerContainer}>
             <div className={styles.myLoanerContent}>
              <YourLoanOfficer
                name={"Krishna Malyala"}
                email={"kmalyala@nexamortgage.com"}
                phone={"334-625-9252"}
                nmls1={"NMLS #1875937"}
                states={"NJ"}
                nmls2={"NMLS #1660690"}
              />
            </div>  
          </div>
      </div>
    </div>
  </div>
  );
}

