import * as React from "react";
import styles from "./Resources.module.css"
import SideMenuBar from "./SideMenuBar";
import NavBar from './NavBar';
import YourLoanOfficer from "./YourLoanOfficer";
import {useNavigate } from "react-router-dom";

export default function Resources() {
  const navigate = useNavigate(); 

  const handleForm = async (event) => {
    event.preventDefault()

    navigate("/resources/calculators")
  };
  const handleFormGuide = async (event) => {
    event.preventDefault()

    navigate("/resources/homebuyersguide")
  };
  const handleFormCourse = async (event) => {
    event.preventDefault()

    navigate("/resources/homebuyerscourse")
  };

  return (
    <>
      <div className={styles.div}>
        <NavBar/>
        <div className={styles.div2}>
        <div className={styles.sideBar}>
          <SideMenuBar activeClass={"resources"}/>
          </div>
          <div className={styles.column}>
            <br></br>
            <div className={styles.div3}>
            <button onClick={handleForm} className={styles.resButton}>
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/fc4c65b8-2bcc-41bb-9adb-d49886cea639?apiKey=0bfd1ce19fd24219afeed2675179684b&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/fc4c65b8-2bcc-41bb-9adb-d49886cea639?apiKey=0bfd1ce19fd24219afeed2675179684b&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/fc4c65b8-2bcc-41bb-9adb-d49886cea639?apiKey=0bfd1ce19fd24219afeed2675179684b&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/fc4c65b8-2bcc-41bb-9adb-d49886cea639?apiKey=0bfd1ce19fd24219afeed2675179684b&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/fc4c65b8-2bcc-41bb-9adb-d49886cea639?apiKey=0bfd1ce19fd24219afeed2675179684b&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/fc4c65b8-2bcc-41bb-9adb-d49886cea639?apiKey=0bfd1ce19fd24219afeed2675179684b&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/fc4c65b8-2bcc-41bb-9adb-d49886cea639?apiKey=0bfd1ce19fd24219afeed2675179684b&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/fc4c65b8-2bcc-41bb-9adb-d49886cea639?apiKey=0bfd1ce19fd24219afeed2675179684b&"
                className={styles.img}
              />
              </button>
              <div className={styles.div4}>Calculators</div>
              <button onClick={handleFormGuide} className={styles.resButton}>
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/bb508dfc-cad0-43c5-bddd-400a1d756bf8?apiKey=0bfd1ce19fd24219afeed2675179684b&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/bb508dfc-cad0-43c5-bddd-400a1d756bf8?apiKey=0bfd1ce19fd24219afeed2675179684b&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/bb508dfc-cad0-43c5-bddd-400a1d756bf8?apiKey=0bfd1ce19fd24219afeed2675179684b&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/bb508dfc-cad0-43c5-bddd-400a1d756bf8?apiKey=0bfd1ce19fd24219afeed2675179684b&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/bb508dfc-cad0-43c5-bddd-400a1d756bf8?apiKey=0bfd1ce19fd24219afeed2675179684b&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/bb508dfc-cad0-43c5-bddd-400a1d756bf8?apiKey=0bfd1ce19fd24219afeed2675179684b&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/bb508dfc-cad0-43c5-bddd-400a1d756bf8?apiKey=0bfd1ce19fd24219afeed2675179684b&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/bb508dfc-cad0-43c5-bddd-400a1d756bf8?apiKey=0bfd1ce19fd24219afeed2675179684b&"
                className={styles.img2}
              />
              </button> 
              <div className={styles.div5}>First Time Home Buyer’s Guide</div>
            </div>
          </div>
          <div className={styles.column2}>
            <br></br>
            <div className={styles.div6}>
            <button onClick={handleForm} className={styles.resButton}>
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/1e41ae50-65d1-41ad-9a55-abf5953c5721?apiKey=0bfd1ce19fd24219afeed2675179684b&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/1e41ae50-65d1-41ad-9a55-abf5953c5721?apiKey=0bfd1ce19fd24219afeed2675179684b&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1e41ae50-65d1-41ad-9a55-abf5953c5721?apiKey=0bfd1ce19fd24219afeed2675179684b&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/1e41ae50-65d1-41ad-9a55-abf5953c5721?apiKey=0bfd1ce19fd24219afeed2675179684b&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/1e41ae50-65d1-41ad-9a55-abf5953c5721?apiKey=0bfd1ce19fd24219afeed2675179684b&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/1e41ae50-65d1-41ad-9a55-abf5953c5721?apiKey=0bfd1ce19fd24219afeed2675179684b&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/1e41ae50-65d1-41ad-9a55-abf5953c5721?apiKey=0bfd1ce19fd24219afeed2675179684b&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/1e41ae50-65d1-41ad-9a55-abf5953c5721?apiKey=0bfd1ce19fd24219afeed2675179684b&"
                className={styles.img}
              />
              </button>
              <div className={styles.div7}>Calendar</div>
              <button onClick={handleFormCourse} className={styles.resButton}>
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/fba6ec14-c205-492d-894e-fb4e463ac822?apiKey=0bfd1ce19fd24219afeed2675179684b&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/fba6ec14-c205-492d-894e-fb4e463ac822?apiKey=0bfd1ce19fd24219afeed2675179684b&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/fba6ec14-c205-492d-894e-fb4e463ac822?apiKey=0bfd1ce19fd24219afeed2675179684b&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/fba6ec14-c205-492d-894e-fb4e463ac822?apiKey=0bfd1ce19fd24219afeed2675179684b&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/fba6ec14-c205-492d-894e-fb4e463ac822?apiKey=0bfd1ce19fd24219afeed2675179684b&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/fba6ec14-c205-492d-894e-fb4e463ac822?apiKey=0bfd1ce19fd24219afeed2675179684b&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/fba6ec14-c205-492d-894e-fb4e463ac822?apiKey=0bfd1ce19fd24219afeed2675179684b&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/fba6ec14-c205-492d-894e-fb4e463ac822?apiKey=0bfd1ce19fd24219afeed2675179684b&"
                className={styles.img2}
              />
              </button>
              <div className={styles.div8}>First Time Home Buyer’s Course</div>
            </div>
          </div>
          <div className={styles.column2}>
            <br></br>
            <div className={styles.div9}>
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/ff794b9a-bf6c-4897-a001-13176243bf36?apiKey=0bfd1ce19fd24219afeed2675179684b&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/ff794b9a-bf6c-4897-a001-13176243bf36?apiKey=0bfd1ce19fd24219afeed2675179684b&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ff794b9a-bf6c-4897-a001-13176243bf36?apiKey=0bfd1ce19fd24219afeed2675179684b&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/ff794b9a-bf6c-4897-a001-13176243bf36?apiKey=0bfd1ce19fd24219afeed2675179684b&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/ff794b9a-bf6c-4897-a001-13176243bf36?apiKey=0bfd1ce19fd24219afeed2675179684b&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/ff794b9a-bf6c-4897-a001-13176243bf36?apiKey=0bfd1ce19fd24219afeed2675179684b&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/ff794b9a-bf6c-4897-a001-13176243bf36?apiKey=0bfd1ce19fd24219afeed2675179684b&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/ff794b9a-bf6c-4897-a001-13176243bf36?apiKey=0bfd1ce19fd24219afeed2675179684b&"
                className={styles.img}
              />
              <div className={styles.div10}>Dictionary</div>
              <img
                loading="lazy"
                srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/5eca75a1-0a58-4332-bec7-4d678d63ed61?apiKey=0bfd1ce19fd24219afeed2675179684b&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/5eca75a1-0a58-4332-bec7-4d678d63ed61?apiKey=0bfd1ce19fd24219afeed2675179684b&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5eca75a1-0a58-4332-bec7-4d678d63ed61?apiKey=0bfd1ce19fd24219afeed2675179684b&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/5eca75a1-0a58-4332-bec7-4d678d63ed61?apiKey=0bfd1ce19fd24219afeed2675179684b&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/5eca75a1-0a58-4332-bec7-4d678d63ed61?apiKey=0bfd1ce19fd24219afeed2675179684b&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/5eca75a1-0a58-4332-bec7-4d678d63ed61?apiKey=0bfd1ce19fd24219afeed2675179684b&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/5eca75a1-0a58-4332-bec7-4d678d63ed61?apiKey=0bfd1ce19fd24219afeed2675179684b&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/5eca75a1-0a58-4332-bec7-4d678d63ed61?apiKey=0bfd1ce19fd24219afeed2675179684b&"
                className={styles.img2}
              />
              <div className={styles.div11}>News Feed</div>
            </div>
          </div>
          <div className={styles.myLoanerContainer}>
             <div className={styles.myLoanerContent}>
              <YourLoanOfficer
                name={"Krishna Malyala"}
                email={"kmalyala@nexamortgage.com"}
                phone={"334-625-9252"}
                nmls1={"NMLS #1875937"}
                states={"NJ"}
                nmls2={"NMLS #1660690"}
              />
            </div>      
          </div>
        </div>
      </div>
    </>

  );
}
