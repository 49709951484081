import * as React from "react";
import SideMenuBar from "./SideMenuBar";
import NavBar from './NavBar';
import styles from './HomeBuyersGuide.module.css'
import YourLoanOfficer from "./YourLoanOfficer";
import {useNavigate } from "react-router-dom";

export default function HomeBuyersGuide() {

  return (
      <div className={styles.div}>
         <NavBar/>
         {/* <div className={styles.mainDiv}>
          <div className={styles.sideBar}>
          <SideMenuBar activeClass={"Resources"}/>
          </div> */}
        <div className={styles.div2}>
            <br/>
          <input type="text" className={styles.formInput} placeholder={"Search"}/>
          A B C D E F G H I J K L M N O P Q R S T U V W X Y Z{" "}
            
        </div>
        <div className={styles.div3}>
          <div className={styles.div4 }>
            <div className={styles.column}>
              <div className={styles.div5}>
                <div className={styles.div6}>FAQ’s</div>
                <div className={styles.div7}>Who pays the Realtor Fees?</div>
                <div className={styles.div8}>What is a short sale?</div>
                <div className={styles.div9}>Where can I source my down payment?</div>
                <div className={styles.div10}>
                  What happens after I put my deposit down?
                </div>
              </div>
            </div>
            <div className={styles.column2}>
              <div className={styles.div11}>
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/44bd8e28-9d4d-4474-8e53-6d087e7ce011?apiKey=0bfd1ce19fd24219afeed2675179684b&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/44bd8e28-9d4d-4474-8e53-6d087e7ce011?apiKey=0bfd1ce19fd24219afeed2675179684b&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/44bd8e28-9d4d-4474-8e53-6d087e7ce011?apiKey=0bfd1ce19fd24219afeed2675179684b&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/44bd8e28-9d4d-4474-8e53-6d087e7ce011?apiKey=0bfd1ce19fd24219afeed2675179684b&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/44bd8e28-9d4d-4474-8e53-6d087e7ce011?apiKey=0bfd1ce19fd24219afeed2675179684b&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/44bd8e28-9d4d-4474-8e53-6d087e7ce011?apiKey=0bfd1ce19fd24219afeed2675179684b&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/44bd8e28-9d4d-4474-8e53-6d087e7ce011?apiKey=0bfd1ce19fd24219afeed2675179684b&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/44bd8e28-9d4d-4474-8e53-6d087e7ce011?apiKey=0bfd1ce19fd24219afeed2675179684b&"
                  className={styles.img}
                />
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/175e80f3-8824-42aa-a34f-5908c1bd61a5?apiKey=0bfd1ce19fd24219afeed2675179684b&"
                  className={styles.img2}
                />
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>


  );
}

 