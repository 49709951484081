import * as React from "react";
import styles from "./UserAuthPages.module.css";
import { Link } from "react-router-dom";
import YourLoanOfficer from "./YourLoanOfficer";
import NavBar from './NavBar';

export default function ForgotPasswordSent() {
    return (
      <div className={styles.temp}
      >
        <NavBar/>
        <div className={styles.temp14}
        >
          <div className={styles.temp15}
          >
            <div className={styles.loanOfficer}>
              <YourLoanOfficer
              name={"Krishna Malyala"}
              email={"kmalyala@nexamortgage.com"}
              phone={"334-625-9252"}
              nmls1={"NMLS #1875937"}
              states={"NJ"}
              nmls2={"NMLS #1660690"}
            />
          </div>
            <div className={styles.temp20}
            >
              <div className={styles.forgotPasswordCard}
              >
                <div className={styles.temp21}
                >
                  <div className={styles.temp22}
                  >
                    <div className={styles.forgotPassword}
                    >
                      Forgot Password
                    </div>
                    <div className={styles.loanOfficerInfo}
                    >
                      <span>Go back to </span>
                      <Link to="/sign-in" className={styles.link}>Sign In</Link>
                    </div>
                    <div className={
                      styles.forgotPasswordInfo
                    }
                    >
                      Check your email for further instructions.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }