import * as React from "react";
import styles from "./UserAuthPages.module.css";
import { Link } from "react-router-dom";
import YourLoanOfficer from "./YourLoanOfficer";
import NavBar from './NavBar';

export default function ForgotPassword() {
  return (
    <div className={styles.temp}
    >
      <NavBar/>
      <div className={styles.temp14}
      >
        <div className={styles.temp15}
        >
          <div className={styles.loanOfficer}>
            <YourLoanOfficer
              name={"Krishna Malyala"}
              email={"kmalyala@nexamortgage.com"}
              phone={"334-625-9252"}
              nmls1={"NMLS #1875937"}
              states={"NJ"}
              nmls2={"NMLS #1660690"}
            />
          </div>
          <div className={styles.temp20}
          >
            <div className={styles.forgotPasswordCard}
            >
              <div className={styles.temp21}
              >
                <div className={styles.temp22}
                >
                  <div className={styles.forgotPassword}
                  >
                    Forgot Password
                  </div>
                  <div className={styles.loanOfficerInfo}
                    >
                        <span>Go back to </span>
                        <Link to="/sign-in" className={styles.link}>Sign In</Link>
                    </div>
                  <div className={
                    styles.forgotPasswordInfo
                  }
                  >
                    Enter your registered email address below for which the
                    password need to be reset. We will send a secure link to
                    reset your password
                  </div>
                  <div className={styles.email}
                  >
                    Email
                  </div>
                  <div className={styles.inputDiv}>
                    <input
                        name="emailInput"
                        type="text"
                        className={styles.signInInput}
                    />
                </div>
                </div>
                <div className={styles.btnDiv}>
                    <button className={styles.signInBtn} type="submit">Send Reset Password Link</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}