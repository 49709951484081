import * as React from "react";
import SideMenuBar from "./SideMenuBar";
import NavBar from "./NavBar";
import styles from "./ApplyForLoan.module.css";
import YourLoanOfficer from "./YourLoanOfficer";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import actions from "../actions";
import helpers from "../helpers";

const deepClone = obj => {
  if (obj === null) return null;
  let clone = Object.assign({}, obj);
  Object.keys(clone).forEach(
    key =>
      (clone[key] =
        typeof obj[key] === 'object' ? deepClone(obj[key]) : obj[key])
  );
  if (Array.isArray(obj)) {
    clone.length = obj.length;
    return Array.from(clone);
  }
  return clone;
};

export default function AFLBorrowerHomeInfoForm() {
  const navigate = useNavigate();

  const [maritalStatus, setMaritalStatus] = useState("");
  const [dependents, setDependents] = useState("");
  const [yearsSchool, setYearsSchool] = useState("");
  const [degree, setDegree] = useState("");

  const [maritalStatusError, setMaritalStatusError] = useState("");
  const [dependentsError, setDependentsError] = useState("");
  const [yearsSchoolError, setYearsSchoolError] = useState("");
  const [degreeError, setDegreeError] = useState("");

  const authUser = useSelector((state) => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    if (authUser[0].data.Profile.ApplyForLoanData.BorrowerHomeInfoForm) {
      if (authUser[0].data.Profile.ApplyForLoanData.BorrowerHomeInfoForm.maritalStatus) {
        setMaritalStatus(authUser[0].data.Profile.ApplyForLoanData.BorrowerHomeInfoForm.maritalStatus);
      }
      if (authUser[0].data.Profile.ApplyForLoanData.BorrowerHomeInfoForm.dependents) {
        setDependents(authUser[0].data.Profile.ApplyForLoanData.BorrowerHomeInfoForm.dependents);
      }
      if (authUser[0].data.Profile.ApplyForLoanData.BorrowerHomeInfoForm.yearsSchool) {
        setYearsSchool(
          authUser[0].data.Profile.ApplyForLoanData.BorrowerHomeInfoForm.yearsSchool
        );
      }
      if (authUser[0].data.Profile.ApplyForLoanData.BorrowerHomeInfoForm.degree) {
        setDegree(authUser[0].data.Profile.ApplyForLoanData.BorrowerHomeInfoForm.degree);
      }
    }
  }, [authUser]);
  const handleForm = async (event) => {
    event.preventDefault();

    let errors = 0;

    try{
      helpers.validMaritalStatus(maritalStatus);
      setMaritalStatusError("");
    } catch(e) {
      setMaritalStatusError(e.message);
      errors ++;
    }

    try{
      helpers.validInt(dependents, "Dependents");
      setDependentsError("");
    } catch(e) {
      setDependentsError(e.message);
      errors ++;
    }

    try{
      helpers.validInt(yearsSchool, "Years of School");
      setYearsSchoolError("");
    } catch(e) {
      setYearsSchoolError(e.message);
      errors ++;
    }

    try{
      helpers.validString(degree, "Degree");
      setDegreeError("");
    } catch(e) {
      setDegreeError(e.message);
      errors ++;
    }

    if(errors == 0){
      await localStorage();
    }
  };

  const localStorage = async () => {

    let data = {
      maritalStatus: maritalStatus,
      dependents: dependents,
      yearsSchool: yearsSchool,
      degree: degree,
    };

    const clientId = authUser[0].id;
    const url = "https://uat-api.tlcengine.com/v3/api/mlspin-md/clients/" + clientId;
    const token = authUser[0].user.AccessToken;

    let userData = await helpers.getProfile(url, token);
    let copyUserData = helpers.updateData(userData,"BorrowerHomeInfoForm", data);
    await helpers.updateProfile(copyUserData, url, token);

    let thisClient = deepClone(authUser[0]);
    thisClient.data = copyUserData;
    dispatch(actions.addClientPI(thisClient));

    navigate("/apply-for-loan/borrowerform3");
  }

  return (
    <div className={styles.applyScreen}>
      <NavBar />
      <div className={styles.applyContainer}>
        <div className={styles.applyContent}>
          <SideMenuBar activeClass={"applyForLoan"} />
          <div className={styles.formContainer}>
            <div className={styles.rectangle} />
            <div className={styles.borrowerFormCard}>
              <div className={styles.borrowerFormCardDiv}>
                <div className={styles.borrowerFormCardTitleText}>
                  <div className={styles.borrowerFormTitle}>
                    Borrower Information
                  </div>
                </div>
                <div>
                  <div className={styles.inputsContainer}>
                    <div className={styles.errorAndInputDiv}>
                      {maritalStatusError != "" && <div className={styles.errorDiv}>{maritalStatusError}</div>}
                      <div className={styles.inputDiv}>
                        <div className={styles.dropdown}>
                          <select
                            className={styles.selectBox}
                            onChange={(e) => setMaritalStatus(e.target.value)}
                            value={maritalStatus}
                          >
                            <option value={""}>--Marital status--</option>
                            <option value={"married"}>Married</option>
                            <option value={"single"}>Single</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className={styles.errorAndInputDiv}>
                      {dependentsError != "" && <div className={styles.errorDiv}>{dependentsError}</div>}
                      <div className={styles.inputDiv}>
                        <input
                          onChange={(e) => setDependents(e.target.value)}
                          name="dependentsInput"
                          type="text"
                          className={styles.formInput}
                          placeholder={"Dependents"}
                          value={dependents}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={styles.inputsContainer}>
                    <div className={styles.errorAndInputDiv}>
                      {yearsSchoolError != "" && <div className={styles.errorDiv}>{yearsSchoolError}</div>}
                      <div className={styles.inputDiv}>
                        <input
                          onChange={(e) => setYearsSchool(e.target.value)}
                          name="schoolInput"
                          type="text"
                          className={styles.formInput}
                          placeholder={"Years of School"}
                          value={yearsSchool}
                        />
                      </div>
                    </div>
                    <div className={styles.errorAndInputDiv}>
                      {degreeError != "" && <div className={styles.errorDiv}>{degreeError}</div>}
                      <div className={styles.inputDiv}>
                        <input
                          onChange={(e) => setDegree(e.target.value)}
                          name="degreeInput"
                          type="text"
                          className={styles.formInput}
                          placeholder={"Degree"}
                          value={degree}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles.nextButtonDiv}>
                <button
                  className={styles.backButton}
                  onClick={() => navigate("/apply-for-loan/borrowerform1")}
                >
                  Back
                </button>
                <button className={styles.nextButton} onClick={handleForm}>
                  Next
                </button>
              </div>
            </div>
          </div>
          <div className={styles.myLoanerContainer}>
            <div className={styles.myLoanerContent}>
              <YourLoanOfficer
                name={"Krishna Malyala"}
                email={"kmalyala@nexamortgage.com"}
                phone={"334-625-9252"}
                nmls1={"NMLS #1875937"}
                states={"NJ"}
                nmls2={"NMLS #1660690"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

