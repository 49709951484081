import * as React from "react";
import SideMenuBar from "./SideMenuBar";
import NavBar from './NavBar';
import styles from './ApplyForLoan.module.css'
import YourLoanOfficer from "./YourLoanOfficer";
import { useNavigate } from "react-router-dom";

export default function AFLGovtMonitoringForm() {
  const navigate = useNavigate(); 

  const handleForm = async (event) => {
    event.preventDefault()

    navigate("/apply-for-loan/borrowerform13")
  };

  return (
    <div className={styles.applyScreen}>
      <NavBar/>
      <div className={styles.applyContainer}>
        <div className={styles.applyContent}>
          <SideMenuBar activeClass={"applyForLoan"}/>
          <div className={styles.formContainer}>
            <div className={styles.rectangle}/>
            <div className={styles.borrowerFormCard}>
              <div className={styles.borrowerFormCardDiv}>
                <div className={styles.borrowerFormCardTitleText}>
                  <div className={styles.borrowerFormTitle}>
                    Information for Government Monitoring Purposes
                  </div>
                  </div>
                  <div className={styles.govtWarningDiv}>
                      The following informaiton is requested by the Federal
                      Government to monitor the lender's compliance
                      with equal credit opportunity, fair housing, and home
                      mortgage disclosure laws. You are not required to 
                      furnish this information, but are encouraged to do 
                      so. The law provides that a lender may not 
                      discriminate on the basis of the informaiton,
                      or on whether you choose to furnish it. 
                  </div>
              </div>
            <div className={styles.nextButtonDiv}>
                <button className={styles.backButton} onClick={()=>navigate("/apply-for-loan/borrowerform11")}>Back</button>
                <button className={styles.nextButton} onClick={handleForm}>Next</button>
            </div>
            </div>
        </div>
        <div className={styles.myLoanerContainer}>
             <div className={styles.myLoanerContent}>
              <YourLoanOfficer
                name={"Krishna Malyala"}
                email={"kmalyala@nexamortgage.com"}
                phone={"334-625-9252"}
                nmls1={"NMLS #1875937"}
                states={"NJ"}
                nmls2={"NMLS #1660690"}
              />
            </div>      
            
          </div>
      </div>
    </div>
  </div>
  );
}

