import * as React from "react";
import SideMenuBar from "./SideMenuBar";
import NavBar from './NavBar';

export default function EscrowCenter() {
  return (
    <div>
      <NavBar/>
        <SideMenuBar activeClass={"escrowCenter"}/>
    </div>
  );
}
