import axios from "axios";

const helpers = {
    getProfile: async function getProfile(url, token) {
        let userData;
        try{
            userData = await axios.get(url,
                {
                    headers: { Authorization: `Bearer ${token}` }
                }
            );
            return userData;
        } catch(e) {
            throw new Error("Could not get access to database");
        }
    },

    updateData: function updateData(userData, form, formInfo){
        let copyUserData;
        try{
            copyUserData = userData.data;
            if(!copyUserData.Profile.ApplyForLoanData){
                copyUserData.Profile.ApplyForLoanData = {};
            }
            copyUserData.Profile.ApplyForLoanData[form]= formInfo;
            //copyUserData.Profile.ApplyForLoanData.CoborrowerNameForm = formInfo;
            return copyUserData;
        } catch(e){
            throw new Error("Could not update user data");
        }
    },

    updateProfile: async function updateProfile(copyUserData, url, token) {
        let putUserData;
        if(copyUserData) {
          try {
            putUserData = await axios.put(url, copyUserData, {
              headers: { Authorization: `Bearer ${token}` }
            });
            return putUserData;
          } catch (e) {
            throw new Error("Could not be added to database");
          }
    
        }
    },

    validString: function validString(input, inputName){
        if(!input) throw new Error(inputName + " is required");
        if (input.trim().length === 0) throw new Error(inputName + "input can't be empty");
        let regex = /^[a-z]/;
        if(!regex.test){
            throw new Error(inputName + " must be only letters");
        }
    },

    validName: function validName(name){
        if(!name) throw new Error("Name is required");
        if (name.trim().length === 0) throw new Error("Name input can't be empty");
        let regex = /^[a-z]{1, 10}$/;
        if(!regex.test){
            throw new Error("Name must be only letters");
        }
    },

    validEmail: function validEmail(email){
        if(!email) throw new Error("Email is required");
        if (email.trim().length === 0) throw new Error("Email input can't be empty");
        let regex = /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        if(!regex.test(email)){
            throw new Error("Invalid email input");
        }
    },

    validPhoneNumber: function validPhoneNumber(phoneNumber){
        if(!phoneNumber) throw new Error("Phone number is required");
        if (phoneNumber.trim().length === 0) throw new Error("Phone number input can't be emmpty");
        let regex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/;
        if(!regex.test(phoneNumber)){
            throw new Error("Invalid phone number input");
        }
    },

    validBirthday: function validBirthday(birthday){
        if(!birthday) throw new Error("Birthday is required");
        if (birthday.trim().length === 0) throw new Error("Birthday input can't be empty");
        let regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/ ;
        if(!regex.test(birthday)){
            throw new Error("Invalid birthday input");
        }
    },

    validSuffix: function validSuffix(suffix){
        if(!suffix) throw new Error("Suffix is required");
        if (suffix.trim().length === 0) throw new Error("Suffix input can't be empty");
        let regex = /^[a-z]$/;
        if(!regex.test){
            throw new Error("Suffix must be only letters a-z");
        }
    },

    validPronouns: function validPronouns(pronouns){
        if(!pronouns) throw new Error("Pronouns are required");
        if (pronouns.trim().length === 0) throw new Error("Prnouns input can't be empty");
        let regex = /^[a-z]$/;
        if(!regex.test){
            throw new Error("Pronouns must be only letters a-z");
        }
    },

    validSocial: function validSocial(social){
        if(!social) throw new Error("Social security number are required");
        if (social.trim().length === 0) throw new Error("Social security number input can't be empty");
        let regex = /^(?!(000|666|9))\d{3}-(?!00)\d{2}-(?!0000)\d{4}$/;
        if(!regex.test){
            throw new Error("Invalid input for ssn");
        }
    },

    validMaritalStatus: function validMaritalStatus(status){
        if(status != "married" && status != "single"){
            throw new Error("You must select a marital status");
        }
    },

    validInt: function validInt(input, inputName){
        if(isNaN(parseInt(input))){
            throw new Error(inputName + " must be a number");
        }
        if(parseInt(input) < 0){
            console.log("negative")
            throw new Error(inputName + " must be a positive integer");
        }
    },

    validAddress: function validAddress(address){
        if (!address) throw new Error("Street Address is required");
        if (address.trim().length === 0) throw new Error("Street Address input can't be empty");
        let regex = /^\d+[\s{1}\w]+$/;
        if(!regex.test(address)){
            throw new Error("Invalid input for address");
        }
    },

    validAddress2: function validAddress2(address2){
        //optional field
        if (!address2) return;
        address2 = address2.trim();
        let regex = /^(?!666|000|9\d{2})\d{3}-(?!00)\d{2}-(?!0{4})\d{4}$/;
        if(!regex.test(address2)){
            throw new Error("Invalid input for apt., suite, etc.");
        }
    },

    validCity: function validCity(city){
        if(!city) throw new Error("City is required");
        if (city.trim().length === 0) throw new Error("City input can't be empty");
        let regex = /^[A-Za-z\s\-.]+$/;
        if(!regex.test(city)){
            throw new Error("City must be only letters with a - or .");
        }
    },

    validZip: function validZip(zip){
        if(!zip) throw new Error("Postal code is required");
        if (zip.trim().length === 0) throw new Error("Zip code input can't be empty");
        let regex = /^(?:\d{5})?$/;
        if(!regex.test(zip)){
            throw new Error("Invalid input for zip code");
        }
    },

    validInput: function validInput(input, varName){
        if(!input) throw new Error(varName +" is required");
    },

    validYearandMonth: function validYearandMonth(year, month){
        if(!year) throw new Error("Year/month is required");
        if(!month) throw new Error("Year/month is required");
    },

    validIncomeType: function validIncomeType(income){
        let incomes = [
            "Employee wages and salary income",
            "Self-employed, freelance, and gig work income",
            "Part-time income",
            "Bonuses and commissions",
            "Interest and dividend income",
            "Retirement, government, and pension income",
            "Social Security income",
            "Disability payments",
            "Leave payments",
            "Foster care payments",
            "Alimony and child support",
            "Trust income",
            "Unemployment benefits",
            "Rental or investment income",
            "VA benefits",
            "Military income",
            "Other"
        ]
        if(!incomes.includes(income)){
            throw new Error("You must select a income type");
        }
    },

    validAssetType: function validAssetType(asset){
        if(asset != "Cash and Cash Equivalent Asset" && asset != "Physical Asset" && asset != "Nonphysical Asset" 
            && asset != "Liquid Asset" && asset != "Fixed Asset" && asset != "Equity Asset"
            && asset != "Fixed-Income Asset"){
            throw new Error("You must select a type");
        }
    },

    validLiability: function validLiability(liabilityType){
        if(liabilityType != "Accounts Payable" && liabilityType != "Principle & interest payable" && liabilityType != "Short-term loans" 
            && liabilityType != "Taxes payable" && liabilityType != "Accrued expenses" && liabilityType != "Unearned revenue"
            && liabilityType != "Notes payable" && liabilityType != "Mortgage payable" && liabilityType != "Bonds payable" && 
            liabilityType != "Deferred tax liabilities" && liabilityType != "Capital leases")
            throw new Error("You must select a type");        
    },

    validRealEstateStatus: function validRealEstateStatus(status){
        console.log(status)
        if(status != "Active" && status != "Active with Bump" 
            && status != "Active without Bump" && status != "Active with First Right of Refusal"
             && status != "Contingent" && status != "Pending" && status != "Sold" 
             && status != "Coming Soon" && status != "Other"){
            throw new Error("You must select a status");
        }
    },

    validDownPaymentSource: function validDownPaymentSource(downPayementSource){
        if(downPayementSource != "savings" && downPayementSource != "equity" && downPayementSource != "gift" 
            && downPayementSource != "loan" && downPayementSource != "investment" && downPayementSource != "retirement"
            && downPayementSource != "other"){
            throw new Error("You must select a source");
        }
    },

    validOwnRentStatus: function validOwnStatus(ownRentStatus){
        if(ownRentStatus != "own" && ownRentStatus != "rent" && ownRentStatus != "noRent" ){
            throw new Error("Own status is required");
        }
    },

    validUse: function validUse(use){
        if(use != "Primary Residence" && use != "Secondary Residence" && use != "Investment" ){
            throw new Error("You must select a use");
        }
    },

    validMailing: function validMailing(different){
        if(different != "false" && different != "true")
             throw new Error("You must select one");
    },

    validYesOrNo: function validYesOrNo(response){
        if(response != "Yes" && response != "No")
            throw new Error("Select yes or no");
    },

    validUSCitizen: function validUSCitizen(citizen){
        if(citizen != "US Citizen" && citizen != "Permanent Alien" && citizen != "Nonpermanent Alien")
            throw new Error("Select one");
    },
}

export default helpers;