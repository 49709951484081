import * as React from "react";
import styles from "./HomeBuyersCourse.module.css"
import SideMenuBar from "./SideMenuBar";
import NavBar from './NavBar';
import {useNavigate } from "react-router-dom";


export default function Mod1() {
  return (
    <>
      <div className={styles.div}>
                <NavBar/>
        {/* <div className={styles.t}> */}
        {/* <div className={styles.sideBar}>
          <SideMenuBar activeClass={"resources"}/>
          </div> */}
        <div className={styles.div2}>First Time Home Buyer’s Course</div>
        <div className={styles.div3}>
          <div className={styles.div4}>
            <div className={styles.column}>
              <div className={styles.div5}>
                <div className={styles.div7}>
                  Mod 1: Pros & Cons of Home Ownership
                </div>
                <div className={styles.modDiv}>Unit 1: Welcome to the Course</div>
                <div className={styles.modDiv}>Unit 2: Pros & Cons of Homeownership </div>
                <div className={styles.modDiv}>Unit 3: Assessment</div>                
              </div>
            </div>
            <div className="column-2">
              <div className="div-16">
                <div className="div-17">
                  <img
                    loading="lazy"
                    srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/0f43d969-9ad4-4356-926d-1836ea2bcf32?apiKey=0bfd1ce19fd24219afeed2675179684b&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/0f43d969-9ad4-4356-926d-1836ea2bcf32?apiKey=0bfd1ce19fd24219afeed2675179684b&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0f43d969-9ad4-4356-926d-1836ea2bcf32?apiKey=0bfd1ce19fd24219afeed2675179684b&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/0f43d969-9ad4-4356-926d-1836ea2bcf32?apiKey=0bfd1ce19fd24219afeed2675179684b&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/0f43d969-9ad4-4356-926d-1836ea2bcf32?apiKey=0bfd1ce19fd24219afeed2675179684b&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/0f43d969-9ad4-4356-926d-1836ea2bcf32?apiKey=0bfd1ce19fd24219afeed2675179684b&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/0f43d969-9ad4-4356-926d-1836ea2bcf32?apiKey=0bfd1ce19fd24219afeed2675179684b&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/0f43d969-9ad4-4356-926d-1836ea2bcf32?apiKey=0bfd1ce19fd24219afeed2675179684b&"
                    className="img"
                  />
                  <img
                    loading="lazy"
                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/ca980023-5b74-4cae-84b9-e49298c6bbe6?apiKey=0bfd1ce19fd24219afeed2675179684b&"
                    className="img-2"
                  />
                </div>
                <div className="div-18">Start Course</div>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
}

