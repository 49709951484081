import * as React from "react";
import SideMenuBar from "./SideMenuBar";
import NavBar from './NavBar';
import styles from './ApplyForLoan.module.css'
import YourLoanOfficer from "./YourLoanOfficer";
import {useNavigate } from "react-router-dom";

export default function ApplyForLoan() {
  const navigate = useNavigate(); 

  const handleForm = async (event) => {
    event.preventDefault()

    navigate("/apply-for-loan/borrowerform1")
  };

  return (
    <div className={styles.applyScreen}>
      <NavBar/>
      <div className={styles.applyContainer}>
        <div className={styles.applyContent}>
          <SideMenuBar activeClass={"applyForLoan"}/>
          <div className={styles.formContainer}>
            <div className={styles.rectangle}/>
            <div className={styles.borrowerFormCard}>
              <div className={styles.borrowerFormCardTitleText}>
                <div className={styles.borrowerFormTitle}>
                  Choose a Product
                </div>
              </div>
              <br/>
              <br/>
              <br/>
              <br/>
              <div className={styles.applyCardContent}>
                <div className={styles.inputsContainer}>
                  <div className={styles.purchaseButton}>
                    <button className={styles.purchaseButton}
                      onClick={handleForm}
                    >
                    <img
                      loading="lazy"
                      srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/343c6c96-d6aa-4c78-9286-94c12bb7433b?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/343c6c96-d6aa-4c78-9286-94c12bb7433b?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/343c6c96-d6aa-4c78-9286-94c12bb7433b?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/343c6c96-d6aa-4c78-9286-94c12bb7433b?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/343c6c96-d6aa-4c78-9286-94c12bb7433b?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/343c6c96-d6aa-4c78-9286-94c12bb7433b?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/343c6c96-d6aa-4c78-9286-94c12bb7433b?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/343c6c96-d6aa-4c78-9286-94c12bb7433b?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&"
                      className={styles.imgClass}
                    />
                    </button>
                    <div className={styles.purchase}>
                      Purchase
                    </div>
                  </div>
                  <div className={styles.purchaseButton}>
                    <button className={styles.purchaseButton}
                      onClick={handleForm}
                    >
                      <img
                        loading="lazy"
                        srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/b5d39b1e-a82b-4a52-ac8b-35fe76fea11e?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5d39b1e-a82b-4a52-ac8b-35fe76fea11e?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5d39b1e-a82b-4a52-ac8b-35fe76fea11e?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5d39b1e-a82b-4a52-ac8b-35fe76fea11e?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5d39b1e-a82b-4a52-ac8b-35fe76fea11e?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5d39b1e-a82b-4a52-ac8b-35fe76fea11e?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5d39b1e-a82b-4a52-ac8b-35fe76fea11e?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/b5d39b1e-a82b-4a52-ac8b-35fe76fea11e?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&"
                        className={styles.imgClass}
                      />
                    </button>
                    <div className={styles.purchase}>
                      Refinance
                    </div>
                  </div>
                </div>
              </div>
              <br/>
              <br/>
              
              <br/>
              <br/>
              <br/>
              <br/>
          </div>
        </div>
        <div className={styles.myLoanerContainer}>
          <div className={styles.myLoanerContent}>
              <YourLoanOfficer
                name={"Krishna Malyala"}
                email={"kmalyala@nexamortgage.com"}
                phone={"334-625-9252"}
                nmls1={"NMLS #1875937"}
                states={"NJ"}
                nmls2={"NMLS #1660690"}
              />
          </div>
        </div>
      </div>
    </div>
  </div>
  );
}

