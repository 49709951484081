import * as React from "react";
import SideMenuBar from "./SideMenuBar";
import NavBar from "./NavBar";
import styles from "./ApplyForLoan.module.css";
import YourLoanOfficer from "./YourLoanOfficer";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import actions from "../actions";
import helpers from "../helpers";

const deepClone = obj => {
  if (obj === null) return null;
  let clone = Object.assign({}, obj);
  Object.keys(clone).forEach(
    key =>
      (clone[key] =
        typeof obj[key] === 'object' ? deepClone(obj[key]) : obj[key])
  );
  if (Array.isArray(obj)) {
    clone.length = obj.length;
    return Array.from(clone);
  }
  return clone;
};

export default function AFLBorrowerNameForm() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [suffix, setSuffix] = useState("");
  const [email, setEmail] = useState("");
  const [pronouns, setPronouns] = useState("");
  const [phone, setPhone] = useState("");
  const [dob, setDob] = useState("");
  const [social, setSocial] = useState("");

  const [coFirstName, setCoFirstName] = useState("");
  const [coMiddleName, setCoMiddleName] = useState("");
  const [coLastName, setCoLastName] = useState("");
  const [coSuffix, setCoSuffix] = useState("");
  const [coEmail, setCoEmail] = useState("");
  const [coPronouns, setCoPronouns] = useState("");
  const [coPhone, setCoPhone] = useState("");

  const [coborrowerSelected, setCoborrowerSelected] = useState();

  const [coborrowerSelectedError, setCoborrowerSelectedError] = useState("");

  const [firstNameError, setFirstNameError] = useState("");
  const [middleNameError, setMiddleNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [suffixError, setSuffixError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [pronounsError, setPronounsError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [dobError, setDobError] = useState("");
  const [socialError, setSocialError] = useState("");

  const [coFirstNameError, setCoFirstNameError] = useState("");
  const [coMiddleNameError, setCoMiddleNameError] = useState("");
  const [coLastNameError, setCoLastNameError] = useState("");
  const [coSuffixError, setCoSuffixError] = useState("");
  const [coEmailError, setCoEmailError] = useState("");
  const [coPronounsError, setCoPronounsError] = useState("");
  const [coPhoneError, setCoPhoneError] = useState("");

  const authUser = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const [borrowerTab, setBorrowerTab] = useState(styles.active);
  const [coBorrowerTab, setCoBorrowerTab] = useState(styles.a);
  const [borrowerDiv, setBorrowerDiv] = useState(styles.borrowerFormCardTabs);
  const [coBorrowerDiv, setCoBorrowerDiv] = useState(
    styles.borrowerFormCardTabsInvisible
  );

  useEffect(() => {
    console.log(authUser);
    if(authUser[0].data.Profile && authUser[0].data.Profile.ApplyForLoanData.BorrowerNameForm) {
      if (authUser[0].data.Profile.ApplyForLoanData.BorrowerNameForm.borrower) {
        if (authUser[0].data.Profile.ApplyForLoanData.BorrowerNameForm.borrower.firstName) {
          setFirstName(authUser[0].data.Profile.ApplyForLoanData.BorrowerNameForm.borrower.firstName);
        }
        if (authUser[0].data.Profile.ApplyForLoanData.BorrowerNameForm.borrower.middleName) {
          setMiddleName(authUser[0].data.Profile.ApplyForLoanData.BorrowerNameForm.borrower.middleName);
        }
        if (authUser[0].data.Profile.ApplyForLoanData.BorrowerNameForm.borrower.lastName) {
          setLastName(authUser[0].data.Profile.ApplyForLoanData.BorrowerNameForm.borrower.lastName);
        }
        if (authUser[0].data.Profile.ApplyForLoanData.BorrowerNameForm.borrower.suffix) {
          setSuffix(authUser[0].data.Profile.ApplyForLoanData.BorrowerNameForm.borrower.suffix);
        }
        if (authUser[0].data.Profile.ApplyForLoanData.BorrowerNameForm.borrower.email) {
          setEmail(authUser[0].data.Profile.ApplyForLoanData.BorrowerNameForm.borrower.email);
        }
        if (authUser[0].data.Profile.ApplyForLoanData.BorrowerNameForm.borrower.pronouns) {
          setPronouns(authUser[0].data.Profile.ApplyForLoanData.BorrowerNameForm.borrower.pronouns);
        }
        if (authUser[0].data.Profile.ApplyForLoanData.BorrowerNameForm.borrower.phone) {
          setPhone(authUser[0].data.Profile.ApplyForLoanData.BorrowerNameForm.borrower.phone);
        }
        if (authUser[0].data.Profile.ApplyForLoanData.BorrowerNameForm.borrower.dob) {
          setDob(authUser[0].data.Profile.ApplyForLoanData.BorrowerNameForm.borrower.dob);
        }
        if(authUser[0].data.Profile.ApplyForLoanData.BorrowerNameForm.borrower.coborrowerSelected) {
          setCoborrowerSelected(authUser[0].data.Profile.ApplyForLoanData.BorrowerNameForm.coborrowerSelected);
        }
      }
    }
  }, [authUser]);

  function updateBorrowerTab() {
    setBorrowerTab(styles.active);
    setCoBorrowerTab(styles.a);
    setBorrowerDiv(styles.borrowerFormCardTabs);
    setCoBorrowerDiv(styles.borrowerFormCardTabsInvisible);
  }

  function updateCoBorrowerTab() {
    setBorrowerTab(styles.a);
    setCoBorrowerTab(styles.active);
    setBorrowerDiv(styles.borrowerFormCardTabsInvisible);
    setCoBorrowerDiv(styles.borrowerFormCardTabs);
  }

  const handleForm = async (event) => {
    event.preventDefault();
    let errors = 0;
    let coerrors = 0;

    //borrower validation
    try{
      helpers.validName(firstName);
      setFirstNameError("");
    } catch(e) {
      setFirstNameError(e.message);
      errors ++;
    }

    try{
      helpers.validName(middleName);
      setMiddleNameError("");
    } catch(e) {
      setMiddleNameError(e.message);
      errors ++;
    }

    try{
      helpers.validName(lastName);
      setLastNameError("");
    } catch(e) {
      setLastNameError(e.message);
      errors ++;
    }

    try{
      helpers.validEmail(email);
      setEmailError("");
    } catch(e) {
      setEmailError(e.message);
      errors ++;
    }

    try{
      helpers.validPhoneNumber(phone);
      setPhoneError("");
    } catch(e) {
      setPhoneError(e.message);
      errors ++;
    }

    try{
      helpers.validBirthday(dob);
      setDobError("");
    } catch(e) {
      setDobError(e.message);
      errors ++;
    }

    try{
      helpers.validPronouns(pronouns);
      setPronounsError("");
    } catch(e) {
      setPronounsError(e.message);
      errors ++;
    }

    try{
      helpers.validSocial(social);
      setSocialError("");
    } catch(e) {
      setSocialError(e.message);
      errors ++;
    }

    //coborrower validation
    try{
      helpers.validName(coFirstName);
      setCoFirstNameError("");
    } catch(e) {
      setCoFirstNameError(e.message);
      coerrors ++;
    }

    try{
      helpers.validName(coMiddleName);
      setCoMiddleNameError("");
    } catch(e) {
      setCoMiddleNameError(e.message);
      coerrors ++;
    }

    try{
      helpers.validName(coLastName);
      setCoLastNameError("");
    } catch(e) {
      setCoLastNameError(e.message);
      coerrors ++;
    }

    try{
      helpers.validEmail(coEmail);
      setCoEmailError("");
    } catch(e) {
      setCoEmailError(e.message);
      coerrors ++;
    }

    try{
      helpers.validPhoneNumber(coPhone);
      setCoPhoneError("");
    } catch(e) {
      setCoPhoneError(e.message);
      coerrors ++;
    }

    try{
      helpers.validPronouns(coPronouns);
      setCoPronounsError("");
    } catch(e) {
      setCoPronounsError(e.message);
      coerrors ++;
    }

    try{
      helpers.validInput(coborrowerSelected, "Selection");
      setCoborrowerSelectedError("");
    } catch(e) {
      setCoborrowerSelectedError(e.message);
      coerrors ++;
    }
    
    if(coborrowerSelected){
      if(errors == 0 && coerrors == 0){
        await localStorage();
      }
    } else {
      if(errors == 0){
        await localStorage();
      }
    }
  
  };

  const localStorage = async (event) => {
    setLoading(true);

    let borrower = {
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      suffix: suffix,
      email: email,
      pronouns: pronouns,
      phone: phone,
      dob: dob,
      ssn: social,
      coborrowerSelected: coborrowerSelected
    };

    let coborrower = {
      firstName: coFirstName,
      middleName: coMiddleName,
      lastName: coLastName,
      suffix: coSuffix,
      email: coEmail,
      pronouns: coPronouns,
      phone: coPhone,
    };

    const clientId = authUser[0].id;
    const url = "https://uat-api.tlcengine.com/v3/api/mlspin-md/clients/" + clientId;
    const token = authUser[0].user.AccessToken
    
    try {

      let userData = await helpers.getProfile(url, token);
      let copyUserData = helpers.updateData(userData, "BorrowerNameForm", {borrower, coborrower})
      await helpers.updateProfile(copyUserData, url, token);

      let thisClient = deepClone(authUser[0]);
      thisClient.data = copyUserData;
      dispatch(actions.addClientPI(thisClient))
    
      navigate("/apply-for-loan/borrowerform2");
      
    }catch(e) {
      dispatch(actions.unauthenticateUser());
    }
    
  }

  return (
    <div className={styles.applyScreen}>
      <NavBar />
      <div className={styles.applyContainer}>
        <div className={styles.applyContent}>
          <SideMenuBar activeClass={"applyForLoan"} />
          <div className={styles.formContainer}>
            <div className={styles.rectangleTabs} />
            <ul className={styles.tabs}>
              <li className={borrowerTab}>
                <button onClick={updateBorrowerTab}>Borrower</button>
              </li>
              { coborrowerSelected && 
                <li className={coBorrowerTab}>
                  <button onClick={updateCoBorrowerTab}>Co-Borrower</button>
                </li>
              }
            </ul>


            <div id="coborrower" className={coBorrowerDiv}>
              <div className={styles.borrowerFormCardDiv}>
              
                <div className={styles.borrowerFormCardTitleText}>
                  <div className={styles.borrowerFormTitle}>
                    Co-Borrower Information
                  </div>
                </div>
                {!loading  && 
                  <div>
                    <div className={styles.inputsContainer}>
                      <div className={styles.errorAndInputDiv}>
                        {coFirstNameError != "" && <div className={styles.errorDiv}>{coFirstNameError}</div>}
                        <div className={styles.inputDiv}>
                          <input
                            onChange={(e) => setCoFirstName(e.target.value)}
                            name="firstNameInput"
                            type="text"
                            className={styles.formInput}
                            placeholder={" Co-Borrower Legal First Name"}
                          />
                        </div>
                      </div>
                      <div className={styles.errorAndInputDiv}>
                        {coMiddleNameError != "" && <div className={styles.errorDiv}>{coMiddleNameError}</div>}
                        <div className={styles.inputDiv}>
                          <input
                            onChange={(e) => setCoMiddleName(e.target.value)}
                            name="middleNameInput"
                            type="text"
                            className={styles.formInput}
                            placeholder={"Co-Borrower Legal Middle Name"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.inputsContainer}>
                      <div className={styles.errorAndInputDiv}>
                        {coLastNameError != "" && <div className={styles.errorDiv}>{coLastNameError}</div>}
                        <div className={styles.inputDiv}>
                          <input
                            onChange={(e) => setCoLastName(e.target.value)}
                            name="lastNameInput"
                            type="text"
                            className={styles.formInput}
                            placeholder={"Co-Borrower Legal Last Name"}
                          />
                        </div>
                      </div>
                      <div className={styles.errorAndInputDiv}>
                        {coSuffixError != "" && <div className={styles.errorDiv}>{coSuffixError}</div>}
                        <div className={styles.inputDiv}>
                          <input
                            onChange={(e) => setCoSuffix(e.target.value)}
                            name="suffixInput"
                            type="text"
                            className={styles.formInput}
                            placeholder={"Suffix (Jr, Sr, etc.)"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.inputsContainer}>
                      <div className={styles.errorAndInputDiv}>
                        {coEmailError != "" && <div className={styles.errorDiv}>{coEmailError}</div>}
                        <div className={styles.inputDiv}>
                          <input
                            onChange={(e) => setCoEmail(e.target.value)}
                            name="emailInput"
                            type="text"
                            className={styles.formInput}
                            placeholder={"Email Address"}
                          />
                        </div>
                      </div>
                      <div className={styles.errorAndInputDiv}>
                        {coPronounsError != "" && <div className={styles.errorDiv}>{coPronounsError}</div>}
                        <div className={styles.inputDiv}>
                          <input
                            onChange={(e) => setCoPronouns(e.target.value)}
                            name="pronounsInput"
                            type="text"
                            className={styles.formInput}
                            placeholder={"Preferred Pronouns"}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.inputsContainer}>
                      <div className={styles.errorAndInputDiv}>
                        {coPhoneError != "" && <div className={styles.errorDiv}>{coPhoneError}</div>}
                        <div className={styles.inputDiv}>
                          <input
                            onChange={(e) => setCoPhone(e.target.value)}
                            name="phonenumberInput"
                            type="text"
                            className={styles.formInput}
                            placeholder={"Best Phone Number"}
                          />
                        </div>
                      </div>
                    </div>
                  
                  </div>
                }

                {loading &&
                  <div>
                    loading
                  </div>
                }
                
              </div>
                
            </div>

            <div id="borrower" className={borrowerDiv}>
              <div className={styles.borrowerFormCardDiv}>
                <div className={styles.borrowerFormCardTitleText}>
                  <div className={styles.borrowerFormTitle}>
                    Borrower Information
                  </div>
                </div>
                {!loading  && 
                  <div>
                    <div className={styles.inputsContainer}>
                      <div className={styles.errorAndInputDiv}>
                        {firstNameError != "" && <div className={styles.errorDiv}>{firstNameError}</div>}
                        <div className={styles.inputDiv}>
                          <input
                            onChange={(e) => setFirstName(e.target.value)}
                            name="firstNameInput"
                            type="text"
                            className={styles.formInput}
                            placeholder={"Legal First Name"}
                            value={firstName}
                          />
                        </div>
                      </div>
                      <div className={styles.errorAndInputDiv}>
                        {middleNameError != "" && <div className={styles.errorDiv}>{middleNameError}</div>}
                        <div className={styles.inputDiv}>
                          <input
                            onChange={(e) => setMiddleName(e.target.value)}
                            name="middleNameInput"
                            type="text"
                            className={styles.formInput}
                            placeholder={"Legal Middle Name"}
                            value={middleName}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.inputsContainer}>
                      <div className={styles.errorAndInputDiv}>
                        {lastNameError != "" && <div className={styles.errorDiv}>{lastNameError}</div>}
                        <div className={styles.inputDiv}>
                          <input
                            onChange={(e) => setLastName(e.target.value)}
                            name="lastNameInput"
                            type="text"
                            className={styles.formInput}
                            placeholder={"Legal Last Name"}
                            value={lastName}
                          />
                        </div>
                      </div>
                      <div className={styles.errorAndInputDiv}>
                        {suffixError != "" && <div className={styles.errorDiv}>{suffixError}</div>}
                        <div className={styles.inputDiv}>
                          <input
                            onChange={(e) => setSuffix(e.target.value)}
                            name="suffixInput"
                            type="text"
                            className={styles.formInput}
                            placeholder={"Suffix (Jr, Sr, etc.)"}
                            value={suffix}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.inputsContainer}>
                      <div className={styles.errorAndInputDiv}>
                        {emailError != "" && <div className={styles.errorDiv}>{emailError}</div>}
                        <div className={styles.inputDiv}>
                          <input
                            onChange={(e) => setEmail(e.target.value)}
                            name="emailInput"
                            type="text"
                            className={styles.formInput}
                            placeholder={"Email Address"}
                            value={email}
                          />
                        </div>
                      </div>
                      <div className={styles.errorAndInputDiv}>
                        {pronounsError != "" && <div className={styles.errorDiv}>{pronounsError}</div>}
                        <div className={styles.inputDiv}>
                          <input
                            onChange={(e) => setPronouns(e.target.value)}
                            name="pronounsInput"
                            type="text"
                            className={styles.formInput}
                            placeholder={"Preferred Pronouns"}
                            value={pronouns}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.inputsContainer}>
                      <div className={styles.errorAndInputDiv}>
                        {phoneError != "" && <div className={styles.errorDiv}>{phoneError}</div>}
                        <div className={styles.inputDiv}>
                          <input
                            onChange={(e) => setPhone(e.target.value)}
                            name="phonenumberInput"
                            type="text"
                            className={styles.formInput}
                            placeholder={"Best Phone Number"}
                            value={phone}
                          />
                        </div>
                      </div>
                      <div className={styles.errorAndInputDiv}>
                        {dobError != "" && <div className={styles.errorDiv}>{dobError}</div>}
                        <div className={styles.inputDiv}>
                          <input
                            onChange={(e) => setDob(e.target.value)}
                            name="birthdayInput"
                            type="text"
                            className={styles.formInput}
                            placeholder={"Date of Birth mm/dd/yyyy"}
                            value={dob}
                          />
                        </div>
                      </div>
                    </div>
                    <div className={styles.inputsContainer}>
                      <div className={styles.errorAndInputDiv}>
                        {socialError != "" && <div className={styles.errorDiv}>{socialError}</div>}
                        <div className={styles.inputDiv}>
                          <input
                            onChange={(e) => setSocial(e.target.value)}
                            name="socialInput"
                            type="text"
                            className={styles.formInput}
                            placeholder={"Social Security Number 123-45-6789"}
                          />
                        </div>
                      </div>
                      <div className={styles.coborrowerRadioContainer}>
                      <div className={styles.errorAndInputDiv}>
                        {coborrowerSelectedError != "" && <div className={styles.errorDiv}>{coborrowerSelectedError}</div>}
                        <br/>
                        <div>Co-Borrower Info</div>
                        <div className={styles.coborrowerRadio}>
                          <input
                            type="radio"
                            id="coborrower"
                            checked={coborrowerSelected}
                            value="coborrower"
                            name="coborrower"
                            onChange={(e) => setCoborrowerSelected(true)}
                          />
                          <label>I have a co-borrower</label>
                        </div>
                        <div className={styles.coborrowerRadio}>
                          <input
                            type="radio"
                            id="no-coborrower"
                            checked={!coborrowerSelected}
                            value="coborrower"
                            name="coborrower"
                            onChange={(e) => setCoborrowerSelected(false)}
                          />
                          <label>I do not have a co-borrower</label>
                        </div>
                        </div>

                      </div>
                    </div>
                  </div>
                }

                {loading && 
                  <div>
                    loading...
                  </div>
                }
              </div>

              <div className={styles.nextButtonDiv}>
                <button className={styles.nextButton} onClick={handleForm}>
                  Next
                </button>
              </div>
            </div>
          </div>

          <div className={styles.myLoanerContainer}>
            <div className={styles.myLoanerContent}>
              <YourLoanOfficer
                name={"Krishna Malyala"}
                email={"kmalyala@nexamortgage.com"}
                phone={"334-625-9252"}
                nmls1={"NMLS #1875937"}
                states={"NJ"}
                nmls2={"NMLS #1660690"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
