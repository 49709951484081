import * as React from "react";
import SideMenuBar from "./SideMenuBar";
import NavBar from './NavBar';
import styles from './Calculators.module.css'
import YourLoanOfficer from "./YourLoanOfficer";

export default function Calculators() {
    return (
        <div className="div">
            <NavBar/>
          <div className="div-12">
            <div className="div-13">
                <SideMenuBar activeClass={"Resources"}/>
              <div className="column-2">
                <div className="div-37">
                  <div className="div-38">Calculators</div>
                  <div className="div-39">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/4e42f96d-de0f-452d-86ec-850fbdd79593?apiKey=0bfd1ce19fd24219afeed2675179684b&"
                      className="img-15"
                    />
                    <div className="div-40">Affordability</div>
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/74763051-e523-469c-b4c6-0abf83d9eb61?apiKey=0bfd1ce19fd24219afeed2675179684b&"
                      className="img-16"
                    />
                  </div>
                  <div className="div-41">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/05020c6f-1856-477f-8aa0-af38908ddea7?apiKey=0bfd1ce19fd24219afeed2675179684b&"
                      className="img-17"
                    />
                    <div className="div-42">Refinance</div>
                  </div>
                  <div className="div-43">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/d2baef27-ce02-4ad9-9abd-a07a671fa8b4?apiKey=0bfd1ce19fd24219afeed2675179684b&"
                      className="img-18"
                    />
                    Monthly Payment{" "}
                  </div>
                  <div className="div-45">
                    <img
                      loading="lazy"
                      src="https://cdn.builder.io/api/v1/image/assets/TEMP/1c425ec3-6b47-4781-9f2e-89b366adcd05?apiKey=0bfd1ce19fd24219afeed2675179684b&"
                      className="img-19"
                    />
                    Debt to Income
                  </div>
                </div>
              </div>
              <div className="column-3">
                <img
                  loading="lazy"
                  srcSet="https://cdn.builder.io/api/v1/image/assets/TEMP/91a4a1f7-aab7-439b-a42f-342ade85eb40?apiKey=0bfd1ce19fd24219afeed2675179684b&width=100 100w, https://cdn.builder.io/api/v1/image/assets/TEMP/91a4a1f7-aab7-439b-a42f-342ade85eb40?apiKey=0bfd1ce19fd24219afeed2675179684b&width=200 200w, https://cdn.builder.io/api/v1/image/assets/TEMP/91a4a1f7-aab7-439b-a42f-342ade85eb40?apiKey=0bfd1ce19fd24219afeed2675179684b&width=400 400w, https://cdn.builder.io/api/v1/image/assets/TEMP/91a4a1f7-aab7-439b-a42f-342ade85eb40?apiKey=0bfd1ce19fd24219afeed2675179684b&width=800 800w, https://cdn.builder.io/api/v1/image/assets/TEMP/91a4a1f7-aab7-439b-a42f-342ade85eb40?apiKey=0bfd1ce19fd24219afeed2675179684b&width=1200 1200w, https://cdn.builder.io/api/v1/image/assets/TEMP/91a4a1f7-aab7-439b-a42f-342ade85eb40?apiKey=0bfd1ce19fd24219afeed2675179684b&width=1600 1600w, https://cdn.builder.io/api/v1/image/assets/TEMP/91a4a1f7-aab7-439b-a42f-342ade85eb40?apiKey=0bfd1ce19fd24219afeed2675179684b&width=2000 2000w, https://cdn.builder.io/api/v1/image/assets/TEMP/91a4a1f7-aab7-439b-a42f-342ade85eb40?apiKey=0bfd1ce19fd24219afeed2675179684b&"
                  className="img-20"
                />
              </div>
              <div className={styles.myLoanerContainer}>
                <div className={styles.myLoanerContent}>
                    <YourLoanOfficer
                        name={"Krishna Malyala"}
                        email={"kmalyala@nexamortgage.com"}
                        phone={"334-625-9252"}
                        nmls1={"NMLS #1875937"}
                        states={"NJ"}
                        nmls2={"NMLS #1660690"}
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
    );
}
