import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const PublicRoute = () => {
  const user = useSelector((state) => state.users);

  return user[0] ? <Navigate to="/" /> :<Outlet /> ;
};

export default PublicRoute;
