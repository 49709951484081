import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import Home from "./components/Home";
import ForgotPassword from "./components/ForgotPassword";
import SignUp from "./components/SignUp";
import ForgotPasswordSent from "./components/ForgotPasswordSent";
import SignIn from "./components/SignIn";
import ApplyForLoan from "./components/ApplyForLoan";
import HomeSearch from "./components/HomeSearch";
import Resources from "./components/Resources";
import Calculators from "./components/Calculators";
import HomeBuyersGuide from "./components/HomeBuyersGuide";
import HomeBuyersCourse from "./components/HomeBuyersCourse";
import Mod1 from "./components/Mod1";
import CreditScore from "./components/CreditScore";
import ConnectYourBank from "./components/ConnectYourBank";
import ChatWithLo from "./components/ChatWithLo";
import EscrowCenter from "./components/EscrowCenter";
import Profile from "./components/Profile";
import Settings from "./components/Settings";
import AFLBorrowerNameForm from "./components/AFLPersonalInfoForm1";
import AFLBorrowerHomeInfoForm from "./components/AFLPersonalInfoForm2";
import AFLBorrowerAddressForm from "./components/AFLAddressForm";
import AFLPropertyInfoForm from "./components/AFLPropertyInfoForm";
import AFLDownPaymentForm from "./components/AFLDownPaymentForm";
import AFLEmploymentForm from "./components/AFLEmploymentForm";
import AFLIncomeForm from "./components/AFLIncomeForm";
import AFLAssetsForm from "./components/AFLAssetsForm";
import AFLliabilitiesForm from "./components/AFLLiabilitiesForm";
import AFLRealEstateForm from "./components/AFLRealEstateForm";
import AFLDeclarationsForm from "./components/AFLDeclarationsForm";
import AFLGovtMonitoringForm from "./components/AFLGovtMonitoringForm";
import AFLGovtInfoForm1 from "./components/AFLGovtInfoForm1";
import AFLGovtInfoForm2 from "./components/AFLGovtInfoForm2";
import AFLConfirmationForm from "./components/AFLConfirmationForm";
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";
import CSConfirmChoice from "./components/CSConfirmChoice";
import CSConfirmInfo from "./components/CSConfirmInfo";

import { useSelector } from "react-redux";
import axios from "axios";
import { useDispatch } from "react-redux";
import actions from "./actions";

function App() {
  const dispatch = useDispatch();

  const [loggedIn, setLoggedIn] = useState(false);
  const [userInfo, setUserInfo] = useState({});
  const [count, setCount] = useState(0);

  const authUser = useSelector((state) => state.users);

  useEffect(() => {
    if (authUser[0]) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [authUser]);

  useEffect(() => {
    if (authUser[0]) {
      if (authUser[0].user) {
        setUserInfo(authUser[0]);
        reauthenticate();
      }
    }
  }, []);

    const reauthenticate = async (event) => {
        //reauthentication
        const {data} = await axios.post("https://uat-api.tlcengine.com/v3/api/mlspin-md/accesstokens/verifytoken",
            {
                AudienceId: authUser[0].user.Audience.Id,
                AudienceType: authUser[0].user.AudienceType,
                TokenId: authUser[0].user.AccessToken
            }
        );
        
        //update local storage
        let thisClient = {};
        let changes = 0;

        if (authUser[0] && !authUser[0].hasOwnProperty("user")) {
            thisClient = {
                ...authUser[0],
                user: {},
            };
        } else {
            thisClient = { ...authUser[0] };
        }

        if (data) {
            thisClient.user = data;
            changes++;
        }

        if (changes) {
            dispatch(actions.addClientPI(thisClient));
        }
  };

  useEffect(() => {
    if (loggedIn && authUser[0].user) {
      const interval = setInterval(() => {
        reauthenticate();
      }, 300000);

      return () => clearInterval(interval);
    }
  }, [loggedIn]);

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<Home />} />
          </Route>
          <Route path="/sign-in" element={<PublicRoute />}>
            <Route path="/sign-in" element={<SignIn />} />
          </Route>
          <Route path="/sign-up" element={<PublicRoute />}>
            <Route path="/sign-up" element={<SignUp />} />
          </Route>
          <Route path="/forgot-password" element={<PublicRoute />}>
            <Route path="/forgot-password" element={<ForgotPassword />} />
          </Route>
          <Route path="/forgot-password-sent" element={<PublicRoute />}>
            <Route
              path="/forgot-password-sent"
              element={<ForgotPasswordSent />}
            />
          </Route>
          <Route path="/apply-for-loan" element={<PrivateRoute />}>
            <Route path="/apply-for-loan" element={<ApplyForLoan />} />
          </Route>
          <Route path="/home-search" element={<PrivateRoute />}>
            <Route path="/home-search" element={<HomeSearch />} />
          </Route>
          <Route path="/resources" element={<PrivateRoute />}>
            <Route path="/resources" element={<Resources />} />
          </Route>
          <Route path="/resources/calculators" element={<PrivateRoute />}>
            <Route path="/resources/calculators" element={<Calculators />} />
          </Route>
          <Route path="/resources/homebuyersguide" element={<PrivateRoute />}>
            <Route path="/resources/homebuyersguide" element={<HomeBuyersGuide />} />
          </Route>
          <Route path="/resources/homebuyerscourse" element={<PrivateRoute />}>
            <Route path="/resources/homebuyerscourse" element={<HomeBuyersCourse />} />
            </Route>
          <Route path="/resources/homebuyerscourse/module1" element={<PrivateRoute />}>
            <Route path="/resources/homebuyerscourse/module1" element={<Mod1 />} />
          </Route>
          <Route path="/credit-score" element={<PrivateRoute />}>
            <Route path="/credit-score" element={<CreditScore />} />
          </Route>
          <Route path="/connect-your-bank" element={<PrivateRoute />}>
            <Route path="/connect-your-bank" element={<ConnectYourBank />} />
          </Route>
          <Route path="/chat-with-lo" element={<PrivateRoute />}>
            <Route path="/chat-with-lo" element={<ChatWithLo />} />
          </Route>
          <Route path="/escrow-center" element={<PrivateRoute />}>
            <Route path="/escrow-center" element={<EscrowCenter />} />
          </Route>
          <Route path="/profile" element={<PrivateRoute />}>
            <Route path="/profile" element={<Profile />} />
          </Route>
          <Route path="/settings" element={<PrivateRoute />}>
            <Route path="/settings" element={<Settings />} />
          </Route>
          <Route
            path="/apply-for-loan/borrowerform1"
            element={<PrivateRoute />}
          >
            <Route
              path="/apply-for-loan/borrowerform1"
              element={<AFLBorrowerNameForm />}
            />
          </Route>
          <Route
            path="/apply-for-loan/borrowerform2"
            element={<PrivateRoute />}
          >
            <Route
              path="/apply-for-loan/borrowerform2"
              element={<AFLBorrowerHomeInfoForm />}
            />
          </Route>
          <Route
            path="/apply-for-loan/borrowerform3"
            element={<PrivateRoute />}
          >
            <Route
              path="/apply-for-loan/borrowerform3"
              element={<AFLBorrowerAddressForm />}
            />
          </Route>
          <Route
            path="/apply-for-loan/borrowerform4"
            element={<PrivateRoute />}
          >
            <Route
              path="/apply-for-loan/borrowerform4"
              element={<AFLPropertyInfoForm />}
            />
          </Route>
          <Route
            path="/apply-for-loan/borrowerform5"
            element={<PrivateRoute />}
          >
            <Route
              path="/apply-for-loan/borrowerform5"
              element={<AFLDownPaymentForm />}
            />
          </Route>
          <Route
            path="/apply-for-loan/borrowerform6"
            element={<PrivateRoute />}
          >
            <Route
              path="/apply-for-loan/borrowerform6"
              element={<AFLEmploymentForm />}
            />
          </Route>
          <Route
            path="/apply-for-loan/borrowerform7"
            element={<PrivateRoute />}
          >
            <Route
              path="/apply-for-loan/borrowerform7"
              element={<AFLIncomeForm />}
            />
          </Route>
          <Route
            path="/apply-for-loan/borrowerform8"
            element={<PrivateRoute />}
          >
            <Route
              path="/apply-for-loan/borrowerform8"
              element={<AFLAssetsForm />}
            />
          </Route>
          <Route
            path="/apply-for-loan/borrowerform9"
            element={<PrivateRoute />}
          >
            <Route
              path="/apply-for-loan/borrowerform9"
              element={<AFLliabilitiesForm />}
            />
          </Route>
          <Route
            path="/apply-for-loan/borrowerform10"
            element={<PrivateRoute />}
          >
            <Route
              path="/apply-for-loan/borrowerform10"
              element={<AFLRealEstateForm />}
            />
          </Route>
          <Route
            path="/apply-for-loan/borrowerform11"
            element={<PrivateRoute />}
          >
            <Route
              path="/apply-for-loan/borrowerform11"
              element={<AFLDeclarationsForm />}
            />
          </Route>
          <Route
            path="/apply-for-loan/borrowerform12"
            element={<PrivateRoute />}
          >
            <Route
              path="/apply-for-loan/borrowerform12"
              element={<AFLGovtMonitoringForm />}
            />
          </Route>
          <Route
            path="/apply-for-loan/borrowerform13"
            element={<PrivateRoute />}
          >
            <Route
              path="/apply-for-loan/borrowerform13"
              element={<AFLGovtInfoForm1 />}
            />
          </Route>
          <Route
            path="/apply-for-loan/borrowerform14"
            element={<PrivateRoute />}
          >
            <Route
              path="/apply-for-loan/borrowerform14"
              element={<AFLGovtInfoForm2 />}
            />
          </Route>
          <Route
            path="/apply-for-loan/borrowerform15"
            element={<PrivateRoute />}
          >
            <Route
              path="/apply-for-loan/borrowerform15"
              element={<AFLConfirmationForm />}
            />
          </Route>
          <Route path="/credit-score/confirminfo" element={<PrivateRoute />}>
            <Route
              path="/credit-score/confirminfo"
              element={<CSConfirmInfo />}
            />
          </Route>
          <Route path="/credit-score/confirmchoice" element={<PrivateRoute />}>
            <Route
              path="/credit-score/confirmchoice"
              element={<CSConfirmChoice />}
            />
          </Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
