let initialState = [];
let copyState = null;
let index = 0;

const deepClone = obj => {
  if (obj === null) return null;
  let clone = Object.assign({}, obj);
  Object.keys(clone).forEach(
    key =>
      (clone[key] =
        typeof obj[key] === 'object' ? deepClone(obj[key]) : obj[key])
  );
  if (Array.isArray(obj)) {
    clone.length = obj.length;
    return Array.from(clone);
  }
  return clone;
};

const userReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case "UNAUTHENTICATE_USER":
      copyState = [...state];
      index = copyState.findIndex((x) => x.id === payload.id);
      if (copyState.length > 0) {
        copyState.splice(index, 1);
      }
      return [...copyState];

    case "ADD_CLIENT_PI":
        copyState = deepClone(state);
        index = copyState.findIndex((x) => x.id === payload.id);
        if(index<0) {
            index = 0;
        }
        copyState[index] = payload.client

        return deepClone(copyState)

    default:
      return state;
  }
};

export default userReducer;
