import * as React from "react";
import SideMenuBar from "./SideMenuBar";
import NavBar from './NavBar';
import styles from './ApplyForLoan.module.css'
import YourLoanOfficer from "./YourLoanOfficer";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useState } from "react";

export default function CSConfirmChoice() {
  const navigate = useNavigate();
  const authUser = useSelector((state) => state.users)
  const clientState = useSelector((state) => state.client);

  //console.log(clientState)
  let firstName = "";
  if(authUser[0].data.Profile.CreditScoreData) {
    if(authUser[0].data.Profile.CreditScoreData.firstName) {

    firstName = authUser[0].data.Profile.CreditScoreData.firstName
    }
  }

  const handleForm = async (event) => {
    event.preventDefault()

    var data = {
      
    }

    console.log(data);

    // navigate("/apply-for-loan/borrowerform2")
  };

  return (
    <div className={styles.applyScreen}>
      <NavBar/>
      <div className={styles.applyContainer}>
        <div className={styles.applyContent}>
          <SideMenuBar activeClass={"creditScore"}/>
          <div className={styles.formContainer}>
            <div className={styles.rectangle}/>
            <div className={styles.borrowerFormCard}>
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/60c78b5b-549d-4212-9133-2c665550b970?apiKey=2ec65fe7dbb04cbab802e4c2b096ab48&"
                className={styles.imgLogo}
              />
              <div className={styles.welcomeMessageDiv}>Welcome, {firstName}</div>
              <div className={styles.miniCard}>
                <div className={styles.miniCardTitle}>Confirm Identity</div>
                <div className={styles.miniCardText}>
                  Please choose how you want to receive your passcode, which will be
                  sent to the phone number you entered on the previous page
                </div>
                <div className={styles.miniCardRadio}>
                  <input type="radio" id="phoneText" name="phoneText" />
                  <label className={styles.miniCardTitle}>Text Message</label>
                </div>
                <div className={styles.miniCardRadio}>
                  <input type="radio" id="phoneText" name="phoneText" />
                  <label className={styles.miniCardTitle}>Phone Call</label>
                </div>
              </div>
              <div className={styles.carButtonDiv}>
                <button className={styles.cardButton} onClick={handleForm}>Agree and Send Passcode</button>
              </div>
            </div>
          </div>
          <div className={styles.myLoanerContainer}>
             <div className={styles.myLoanerContent}>
              <YourLoanOfficer
                name={"Krishna Malyala"}
                email={"kmalyala@nexamortgage.com"}
                phone={"334-625-9252"}
                nmls1={"NMLS #1875937"}
                states={"NJ"}
                nmls2={"NMLS #1660690"}
              />
            </div>  
          </div>
      </div>
    </div>
  </div>
  );
}

