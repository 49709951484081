import * as React from "react";
import SideMenuBar from "./SideMenuBar";
import NavBar from './NavBar';
import styles from './ApplyForLoan.module.css'
import YourLoanOfficer from "./YourLoanOfficer";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import actions from "../actions";
import helpers from "../helpers";

const deepClone = obj => {
  if (obj === null) return null;
  let clone = Object.assign({}, obj);
  Object.keys(clone).forEach(
    key =>
      (clone[key] =
        typeof obj[key] === 'object' ? deepClone(obj[key]) : obj[key])
  );
  if (Array.isArray(obj)) {
    clone.length = obj.length;
    return Array.from(clone);
  }
  return clone;
};

export default function AFLAssetsForm() {
  const navigate = useNavigate(); 

  const authUser = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const [coborrowerSelected, setCoborrowerSelected] = useState(false);

  const [incomeAssetsError, setAssetsInputsError] = useState("");
  const [coAssetsInputsError, setCoAssetsInputsError] = useState("");

  const [assetNameError, setAssetNameError] = useState('');
  const [assetTypeError, setAssetTypeError] = useState('');
  const [accountNumberError, setAccountNumberError] = useState('');
  const [accountBalanceError, setAccountBalanceError] = useState('');

  const [coAssetNameError, setCoAssetNameError] = useState('');
  const [coAssetTypeError, setCoAssetTypeError] = useState('');
  const [coAccountNumberError, setCoAccountNumberError] = useState('');
  const [coAccountBalanceError, setCoAccountBalanceError] = useState('');

  const [assetInputs, setAssetInputs] = useState([]);
  const [oldInputs, setOldInputs] = useState(false);

  const [coAssetInputs, setCoAssetInputs] = useState([]);
  const [coOldInputs, setCoOldInputs] = useState(false);

  const [assetName, setAssetName] = useState('');
  const [assetType, setAssetType] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [accountBalance, setAccountBalance] = useState('');

  const [coAssetName, setCoAssetName] = useState('');
  const [coAssetType, setCoAssetType] = useState('');
  const [coAccountNumber, setCoAccountNumber] = useState('');
  const [coAccountBalance, setCoAccountBalance] = useState('');

  const [borrowerTab, setBorrowerTab] = useState(styles.active);
  const [coBorrowerTab, setCoBorrowerTab] = useState(styles.a);
  const [borrowerDiv, setBorrowerDiv] = useState(styles.borrowerFormCardTabs);
  const [coBorrowerDiv, setCoBorrowerDiv] = useState(styles.borrowerFormCardTabsInvisible);

  useEffect(() => {
    if(authUser[0].data.Profile.ApplyForLoanData.BorrowerNameForm) {
      setCoborrowerSelected(authUser[0].data.Profile.ApplyForLoanData.BorrowerNameForm.borrower.coborrowerSelected)
    }
    if(authUser[0].data.Profile.ApplyForLoanData.AssetsForm) {
      if(authUser[0].data.Profile.ApplyForLoanData.AssetsForm.borrower) {
        setOldInputs(true);
      }
      if(authUser[0].data.Profile.ApplyForLoanData.AssetsForm.coborrower) {
        setCoOldInputs(true);
      }
    }
  }, [authUser])

  useEffect(()=> {
    if(authUser[0].data.Profile.ApplyForLoanData.AssetsForm) {
      if(authUser[0].data.Profile.ApplyForLoanData.AssetsForm.borrower && oldInputs === true) {
        let oldInputVals = authUser[0].data.Profile.ApplyForLoanData.AssetsForm.borrower;
        for(let i = 0; i < oldInputVals.length; i++){
            addAssetsInitial(oldInputVals[i][0], oldInputVals[i][1], oldInputVals[i][2], oldInputVals[i][3], "list");
        }
      }
    }
  }, [oldInputs])

  useEffect(()=> {
    if(authUser[0].data.Profile.ApplyForLoanData.AssetsForm) {
      if(authUser[0].data.Profile.ApplyForLoanData.AssetsForm.coborrower && coOldInputs === true) {
        let oldInputVals = authUser[0].data.Profile.ApplyForLoanData.AssetsForm.coborrower;
        for(let i = 0; i < oldInputVals.length; i++){
            addAssetsInitial(oldInputVals[i][0], oldInputVals[i][1], oldInputVals[i][2], oldInputVals[i][3], "colist");
        }
      }
    }
  }, [coOldInputs])

  const addAssetsInitial = (currentAssetName, currentAssetType, currentAccountNumber, currentAccountBalance, list) => {
    let errors = 0;

    if(list === "list"){
      try{
        helpers.validString(currentAssetName, "Asset Name");
        setAssetNameError("");
      } catch(e) {
        setAssetNameError(e.message);
        errors ++;
      }

      try{
        helpers.validAssetType(currentAssetType);
        setAssetTypeError("");
      } catch(e) {
        setAssetTypeError(e.message);
        errors ++;
      }

      try{
        helpers.validString(currentAccountNumber, "Account Number");
        setAccountNumberError("");
      } catch(e) {
        setAccountNumberError(e.message);
        errors ++;
      }

      try{
        helpers.validInt(currentAccountBalance, "Account Balance");
        setAccountBalanceError("");
      } catch(e) {
        setAccountBalanceError(e.message);
        errors ++;
      }
    } else {
      try{
        helpers.validString(currentAssetName, "Asset Name");
        setCoAssetNameError("");
      } catch(e) {
        setCoAssetNameError(e.message);
        errors ++;
      }

      try{
        helpers.validAssetType(currentAssetType);
        setCoAssetTypeError("");
      } catch(e) {
        setCoAssetTypeError(e.message);
        errors ++;
      }

      try{
        helpers.validString(currentAccountNumber, "Account Number");
        setCoAccountNumberError("");
      } catch(e) {
        setCoAccountNumberError(e.message);
        errors ++;
      }

      try{
        helpers.validInt(currentAccountBalance, "Account Balance");
        setCoAccountBalanceError("");
      } catch(e) {
        setCoAccountBalanceError(e.message);
        errors ++;
      }
    }


    if(errors === 0)
    {
      setAssetsInputsError("");
      setCoAssetsInputsError("");
      let source = [currentAssetName, currentAssetType, currentAccountNumber, currentAccountBalance];
      if(list === "list"){
        setAssetInputs(arr => [...arr, source]);
      } else {
        setCoAssetInputs(arr => [...arr, source]);
      }

      let li = document.createElement("li");
      let text = document.createTextNode(currentAssetName + " - " + currentAssetType + " - " + currentAccountNumber + " - " + currentAccountBalance);
      li.id = currentAssetName + " " + currentAssetType + " " + currentAccountNumber + " " + currentAccountBalance + " " + list;
      li.appendChild(text);

      let removeBtn = document.createElement("input")
      removeBtn.type = "button";
      removeBtn.value = "Remove";
      removeBtn.className = styles.removeButton
      removeBtn.addEventListener("click", function() {
        let elementToRemove = li.id.trim().split(" ");
        if(elementToRemove[elementToRemove.length - 1] === "list"){
          setAssetInputs(arr => arr.filter(asset => !assetsEqual(asset, li.id, list)))
        } else {
          setCoAssetInputs(arr => arr.filter(asset => !assetsEqual(asset, li.id, list)))
        }
        li.remove();
      })
      li.appendChild(removeBtn)

      document.getElementById(list).appendChild(li);
    }
  }

  useEffect(()=> {
    console.log(assetInputs);
  }, [assetInputs])

  const addAssets = (list) => {
    let errors = 0;

    if(list === "list"){
      try{
        helpers.validString(assetName, "Asset Name");
        setAssetNameError("");
      } catch(e) {
        setAssetNameError(e.message);
        errors ++;
      }

      try{
        helpers.validAssetType(assetType);
        setAssetTypeError("");
      } catch(e) {
        setAssetTypeError(e.message);
        errors ++;
      }

      try{
        helpers.validString(accountNumber, "Account Number");
        setAccountNumberError("");
      } catch(e) {
        setAccountNumberError(e.message);
        errors ++;
      }

      try{
        helpers.validInt(accountBalance, "Account Balance");
        setAccountBalanceError("");
      } catch(e) {
        setAccountBalanceError(e.message);
        errors ++;
      }
    } else {
      try{
        helpers.validString(coAssetName, "Asset Name");
        setCoAssetNameError("");
      } catch(e) {
        setCoAssetNameError(e.message);
        errors ++;
      }

      try{
        helpers.validAssetType(coAssetType);
        setCoAssetTypeError("");
      } catch(e) {
        setCoAssetTypeError(e.message);
        errors ++;
      }

      try{
        helpers.validString(coAccountNumber, "Account Number");
        setCoAccountNumberError("");
      } catch(e) {
        setCoAccountNumberError(e.message);
        errors ++;
      }

      try{
        helpers.validInt(coAccountBalance, "Account Balance");
        setCoAccountBalanceError("");
      } catch(e) {
        setCoAccountBalanceError(e.message);
        errors ++;
      }
    }

    if(errors === 0)
    {
      setAssetsInputsError("");
      let source = []
      if(list === "list"){
        source = [assetName, assetType, accountNumber, accountBalance];
        setAssetInputs(arr => [...arr, source]);
      } else {
        source = [coAssetName, coAssetType, coAccountNumber, coAccountBalance];
        setCoAssetInputs(arr => [...arr, source]);
      }

      let li = document.createElement("li");
      let text = document.createTextNode(source[0] + " - " + source[1] + " - " + source[2] + " - " + source[3]);
      li.id = source[0] + " " + source[1] + " " + source[2] + " " + source[3] + " " + list;
      li.appendChild(text);

      let removeBtn = document.createElement("input")
      removeBtn.type = "button";
      removeBtn.value = "Remove";
      removeBtn.className = styles.removeButton
      removeBtn.addEventListener("click", function() {
        let elementToRemove = li.id.trim().split(" ");
        if(elementToRemove[elementToRemove.length - 1] === "list"){
          setAssetInputs(arr => arr.filter(asset => !assetsEqual(asset, li.id, list)))
        } else {
          setCoAssetInputs(arr => arr.filter(asset => !assetsEqual(asset, li.id, list)))
        }
        li.remove();
      })
      li.appendChild(removeBtn)

      document.getElementById(list).appendChild(li);
    }
  }

  const assetsEqual = (a, b, list) => {
    let temp = a[0] + " " + a[1] + " " + a[2] + " " + a[3] + " " + list;
    if(temp === b ){
      return true;
    } else {
      return false
    }
  }

  function updateBorrowerTab() {
    setBorrowerTab(styles.active)
    setCoBorrowerTab(styles.a)
    setBorrowerDiv(styles.borrowerFormCardTabs)
    setCoBorrowerDiv(styles.borrowerFormCardTabsInvisible)
  }

  function updateCoBorrowerTab() {
    setBorrowerTab(styles.a)
    setCoBorrowerTab(styles.active)
    setBorrowerDiv(styles.borrowerFormCardTabsInvisible)
    setCoBorrowerDiv(styles.borrowerFormCardTabs)
  }

  const handleForm = async (event) => {
    event.preventDefault();

    if(assetInputs.length === 0){
      setAssetsInputsError("You must add at least one asset");
    } else if (coborrowerSelected && coAssetInputs.length === 0){
      setAssetsInputsError("You must add assets for your coborrower");
    }else {
      setAssetsInputsError("")
      await localStorage();
    }
  };

  const localStorage = async () => {
    let assetInfo = {
      borrower: assetInputs,
      coborrower: coAssetInputs
    }

    const clientId = authUser[0].id;
    const url = "https://uat-api.tlcengine.com/v3/api/mlspin-md/clients/" + clientId;
    const token = authUser[0].user.AccessToken

    let userData = await helpers.getProfile(url, token);
    let copyUserData = helpers.updateData(userData, "AssetsForm", assetInfo);
    await helpers.updateProfile(copyUserData, url, token);

    let thisClient = deepClone(authUser[0]);
    thisClient.data = copyUserData;
    dispatch(actions.addClientPI(thisClient));

    navigate("/apply-for-loan/borrowerform9")
  }

  return (
    <div className={styles.applyScreen}>
      <NavBar/>
      <div className={styles.applyContainer}>
        <div className={styles.applyContent}>
          <SideMenuBar activeClass={"applyForLoan"}/>
          <div className={styles.formContainer}>
            <div className={styles.rectangleTabs}/>
            <ul className={styles.tabs}>
              <li className={borrowerTab}><button onClick={updateBorrowerTab}>Borrower</button></li> 
              {coborrowerSelected && <li className={coBorrowerTab}><button onClick={updateCoBorrowerTab}>Co-Borrower</button></li> }
            </ul>
            <div id="borrower" className={borrowerDiv}>
              <div className={styles.borrowerFormCardDiv}>
                <div className={styles.borrowerFormCardTitleText}>
                  <div className={styles.borrowerFormTitle}>
                    Borrower Assets
                  </div>
                </div>
                <div className={styles.errorAndInputDiv}>
                  {incomeAssetsError != "" && <div className={styles.errorDiv}>{incomeAssetsError}</div>}
                </div>
                <div><br/></div>
                <div className={styles.inputsContainer}>
                  <div className={styles.uldiv}>
                    <ul id="list"></ul>
                  </div>
                </div>
                <div>
                  <div className={styles.inputsContainer}>
                    <div className={styles.errorAndInputDiv}>
                      {assetNameError != "" && <div className={styles.errorDiv}>{assetNameError}</div>}
                        <div className={styles.inputDiv}>
                            <input
                                onChange={(e) => setAssetName(e.target.value)}
                                name="assetTypeInput"
                                type="text"
                                className={styles.formInput}
                                placeholder={"Bank/Asset Name"}
                                value={assetName}
                            />
                        </div>
                    </div>
                    <div className={styles.errorAndInputDiv}>
                      {assetTypeError != "" && <div className={styles.errorDiv}>{assetTypeError}</div>}
                      <div className={styles.inputDiv}>
                          <div className={styles.dropdown}>
                              <select className={styles.selectBox}
                                onChange={(e) => setAssetType(e.target.value)}
                              >
                                  <option>Type of Asset</option>
                                  <option>Cash and Cash Equivalent Asset</option>
                                  <option>Physical Asset</option>
                                  <option>Nonphysical Asset</option>
                                  <option>Liquid Asset</option>
                                  <option>Fixed Asset</option>
                                  <option>Equity Asset</option>
                                  <option>Fixed-Income Asset</option>
                              </select>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.inputsContainer}>
                    <div className={styles.errorAndInputDiv}>
                      {accountNumberError != "" && <div className={styles.errorDiv}>{accountNumberError}</div>}
                      <div className={styles.inputDiv}>
                        <input
                            onChange={(e) => setAccountNumber(e.target.value)}
                            name="accountNumberInput"
                            type="text"
                            className={styles.formInput}
                            placeholder={"Account Number"}
                            value={accountNumber}
                        />
                      </div>
                    </div>
                    <div className={styles.errorAndInputDiv}>
                      {accountBalanceError != "" && <div className={styles.errorDiv}>{accountBalanceError}</div>}
                      <div className={styles.inputDiv}>
                        <input
                            onChange={(e) => setAccountBalance(e.target.value)}
                            name="balanceInput"
                            type="text"
                            className={styles.formInput}
                            placeholder={"$ Balance or Value"}
                            value={accountBalance}
                        />
                      </div>
                    </div>
                    </div>
                  </div>
                  <div>
                <button className={styles.newIncomeButton} onClick={() => addAssets("list")}>Add Another Source of Income</button>
              </div>
              </div>
              <div className={styles.blankSpaceAssets}/>
              <div className={styles.nextButtonDiv}>
                <button className={styles.backButton} onClick={()=>navigate("/apply-for-loan/borrowerform7")}>Back</button>
                <button className={styles.nextButton} onClick={handleForm}>Next</button>
              </div>
            </div>
          </div>


          <div id="coborrower" className={coBorrowerDiv}>
          <div className={styles.borrowerFormCardDiv}>
            <div className={styles.borrowerFormCardTitleText}>
              <div className={styles.borrowerFormTitle}>
                Co-Borrower Assets
              </div>
            </div>
            <div className={styles.errorAndInputDiv}>
              {coAssetsInputsError != "" && <div className={styles.errorDiv}>{coAssetsInputsError}</div>}
            </div>
            <div className={styles.inputsContainer}>
              <div className={styles.uldiv}>
                <ul id="colist"></ul>
              </div>
            </div>
            <div >
              <div className={styles.inputsContainer}>
                <div className={styles.errorAndInputDiv}>
                  {coAssetNameError != "" && <div className={styles.errorDiv}>{coAssetNameError}</div>}
                  <div className={styles.inputDiv}>
                      <input
                          onChange={(e) => setCoAssetName(e.target.value)}
                          name="assetTypeInput"
                          type="text"
                          className={styles.formInput}
                          placeholder={"Bank/Asset Name"}
                      />
                  </div>
                </div>
                <div className={styles.errorAndInputDiv}>
                  {coAssetTypeError != "" && <div className={styles.errorDiv}>{coAssetTypeError}</div>}
                  <div className={styles.inputDiv}>
                      <div className={styles.dropdown}>
                          <select className={styles.selectBox}
                            onChange={(e) => setCoAssetType(e.target.value)}
                          >
                                  <option>Type of Asset</option>
                                  <option>Cash and Cash Equivalent Asset</option>
                                  <option>Physical Asset</option>
                                  <option>Nonphysical Asset</option>
                                  <option>Liquid Asset</option>
                                  <option>Fixed Asset</option>
                                  <option>Equity Asset</option>
                                  <option>Fixed-Income Asset</option>
                          </select>
                      </div>
                  </div>
                </div>
              </div>
              <div className={styles.inputsContainer}>
                <div className={styles.errorAndInputDiv}>
                  {coAccountNumberError != "" && <div className={styles.errorDiv}>{coAccountNumberError}</div>}
                  <div className={styles.inputDiv}>
                    <input
                        onChange={(e) => setCoAccountNumber(e.target.value)}
                        name="accountNumberInput"
                        type="text"
                        className={styles.formInput}
                        placeholder={"Account Number"}
                    />
                  </div>
                </div>
                <div className={styles.errorAndInputDiv}>
                  {coAccountBalanceError != "" && <div className={styles.errorDiv}>{coAccountBalanceError}</div>}
                  <div className={styles.inputDiv}>
                    <input
                        onChange={(e) => setCoAccountBalance(e.target.value)}
                        name="balanceInput"
                        type="text"
                        className={styles.formInput}
                        placeholder={"$ Balance or Value"}
                    />
                  </div>
                </div>
              </div>
              <div>
            <button className={styles.newIncomeButton} onClick={() => addAssets("colist")}>Add Another Source of Income</button>
          </div>
          </div>
        </div>
        </div>

        <div className={styles.myLoanerContainer}>
             <div className={styles.myLoanerContent}>
              <YourLoanOfficer
                name={"Krishna Malyala"}
                email={"kmalyala@nexamortgage.com"}
                phone={"334-625-9252"}
                nmls1={"NMLS #1875937"}
                states={"NJ"}
                nmls2={"NMLS #1660690"}
              />
            </div>      
            
          </div>


        </div>
      </div>
    </div>
  );
}
