import * as React from "react";
import SideMenuBar from "./SideMenuBar";
import NavBar from './NavBar';
import styles from './ApplyForLoan.module.css'
import YourLoanOfficer from "./YourLoanOfficer";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import actions from "../actions";
import helpers from "../helpers";

const deepClone = obj => {
  if (obj === null) return null;
  let clone = Object.assign({}, obj);
  Object.keys(clone).forEach(
    key =>
      (clone[key] =
        typeof obj[key] === 'object' ? deepClone(obj[key]) : obj[key])
  );
  if (Array.isArray(obj)) {
    clone.length = obj.length;
    return Array.from(clone);
  }
  return clone;
};

export default function AFLPropertyInfoForm() {
  const navigate = useNavigate(); 

  const [state, setState] = useState('Alabama');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [use, setUse] = useState('');

  const [address1Error, setAddress1Error] = useState('');
  const [address2Error, setAddress2Error] = useState('');
  const [cityError, setCityError] = useState('');
  const [zipError, setZipError] = useState('');
  const [useError, setUseError] = useState('');

  const authUser = useSelector((state) => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    console.log(authUser[0])
    if(authUser[0].data.Profile.ApplyForLoanData.PropertyInfoForm) {
      if(authUser[0].data.Profile.ApplyForLoanData.PropertyInfoForm.state) {
        setState(authUser[0].data.Profile.ApplyForLoanData.PropertyInfoForm.state);
      }
      if(authUser[0].data.Profile.ApplyForLoanData.PropertyInfoForm.address1) {
        setAddress1(authUser[0].data.Profile.ApplyForLoanData.PropertyInfoForm.address1);
      }
      if(authUser[0].data.Profile.ApplyForLoanData.PropertyInfoForm.address2) {
        setAddress2(authUser[0].data.Profile.ApplyForLoanData.PropertyInfoForm.address2);
      }
      if(authUser[0].data.Profile.ApplyForLoanData.PropertyInfoForm.city) {
        setCity(authUser[0].data.Profile.ApplyForLoanData.PropertyInfoForm.city);
      }
      if(authUser[0].data.Profile.ApplyForLoanData.PropertyInfoForm.zip) {
        setZip(authUser[0].data.Profile.ApplyForLoanData.PropertyInfoForm.zip)
      }
      if(authUser[0].data.Profile.ApplyForLoanData.PropertyInfoForm.use) {
        setUse(authUser[0].data.Profile.ApplyForLoanData.PropertyInfoForm.use)
      }
      
    }
  }, [authUser])
  const handleForm = async (event) => {
    event.preventDefault();
    let errors = 0;

    try {
      helpers.validAddress(address1);
      setAddress1Error("");
    } catch (e) {
      setAddress1Error(e.message);
      errors++;
    }

    try {
      helpers.validAddress2(address2);
      setAddress2Error("");
    } catch (e) {
      setAddress2Error(e.message);
      errors++;
    }

    try {
      helpers.validCity(city);
      setCityError("");
    } catch (e) {
      setCityError(e.message);
      errors++;
    }

    try {
      helpers.validZip(zip);
      setZipError("");
    } catch (e) {
      setZipError(e.message);
      errors++;
    }

    try {
      helpers.validUse(use);
      setUseError("");
    } catch (e) {
      setUseError(e.message);
      errors++;
    }

    if (errors == 0) {
      await localStorage();
    } else {
      if (errors == 0) {
        await localStorage();
      }
    }

    var borrower = {
      state: state,
      address1: address1,
      address2: address2,
      city: city,
      zip: zip,
      use: use
    }

    console.log(borrower);
  };
  const localStorage = async (event) => {
    let borrower = {
      state: state,
      address1: address1,
      address2: address2,
      city: city,
      zip: zip,
      use: use
    }

    const clientId = authUser[0].id;
    const url = "https://uat-api.tlcengine.com/v3/api/mlspin-md/clients/" + clientId;
    const token = authUser[0].user.AccessToken

    let userData = await helpers.getProfile(url, token);
    let copyUserData = helpers.updateData(userData, "PropertyInfoForm", borrower)
    await helpers.updateProfile(copyUserData, url, token);
    
    let thisClient = deepClone(authUser[0]);
    thisClient.data = copyUserData;
    dispatch(actions.addClientPI(thisClient))

    navigate("/apply-for-loan/borrowerform5")
  }
  return (
    <div className={styles.applyScreen}>
      <NavBar/>
      <div className={styles.applyContainer}>
        <div className={styles.applyContent}>
          <SideMenuBar activeClass={"applyForLoan"}/>
          <div className={styles.formContainer}>
            <div className={styles.rectangle}/>
            <div className={styles.borrowerFormCard}>
            <div className={styles.borrowerFormCardDiv}>
              <div className={styles.borrowerFormCardTitleText}>
                <div className={styles.borrowerFormTitle}>
                  Property Information
                </div>
              </div>
              <div >
                <div className={styles.inputsContainer}>
                <div className={styles.errorAndInputDiv}>
                        {address1Error != "" && (<div className={styles.errorDiv}>{address1Error}</div> )}
                  <div className={styles.inputDiv}>
                    <input
                        onChange={(e) => setAddress1(e.target.value)}
                        name="addressInput"
                        type="text"
                        className={styles.formInput}
                        placeholder={"Property Street Address"}
                        value={address1}
                    />
                  </div>
                </div>
                <div className={styles.errorAndInputDiv}>
                        {address2Error != "" && (<div className={styles.errorDiv}>{address2Error}</div>)}
                    <div className={styles.inputDiv}>
                      <input
                          onChange={(e) => setAddress2(e.target.value)}
                          name="secondAddressInput"
                          type="text"
                          className={styles.formInput}
                          placeholder={"Property Street Address Second Line"}
                          value={address2}
                      />
                    </div>
                  </div>
  
                </div>
                <div className={styles.inputsContainer}>
                  <div className={styles.errorAndInputDiv}>
                        {cityError != "" && (<div className={styles.errorDiv}>{cityError}</div>)}
                    <div className={styles.inputDiv}>
                      <input
                          onChange={(e) => setCity(e.target.value)}
                          name="cityInput"
                          type="text"
                          className={styles.formInput}
                          placeholder={"Property City"}
                          value={city}
                      />
                    </div>
                  </div>
                  <div className={styles.inputDiv}>
                    <div className={styles.dropdown}>
                        <select className={styles.selectBox}
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                        >
                            <option>Alabama</option>
                            <option>Alaska</option>
                            <option>Arizona</option>
                            <option>Arkansas</option>
                            <option>California</option>
                            <option>Colorado</option>
                            <option>Connecticut</option>
                            <option>Deleware</option>
                            <option>Florida</option>
                            <option>Georgia</option>
                            <option>Hawaii</option>
                            <option>Idaho</option>
                            <option>Illinois</option>
                            <option>Indiana</option>
                            <option>Iowa</option>
                            <option>Kansas</option>
                            <option>Kentucky</option>
                            <option>Louisiana</option>
                            <option>Maine</option>
                            <option>Maryland</option>
                            <option>Massachusetts</option>
                            <option>Michigan</option>
                            <option>Minnesota</option>
                            <option>Mississippi</option>
                            <option>Missouri</option>
                            <option>Montana</option>
                            <option>Nebraska</option>
                            <option>Nevada</option>
                            <option>New Hampshire</option>
                            <option>New Jersey</option>
                            <option>New Mexico</option>
                            <option>New York</option>
                            <option>North Carolina</option>
                            <option>North Dakota</option>
                            <option>Ohio</option>
                            <option>Oklahoma</option>
                            <option>Oregon</option>
                            <option>Pennsylvania</option>
                            <option>Rhode Island</option>
                            <option>South Carolina</option>
                            <option>South Dakota</option>
                            <option>Tennessee</option>
                            <option>Texas</option>
                            <option>Utah</option>
                            <option>Vermont</option>
                            <option>Virginia</option>
                            <option>Washington</option>
                            <option>West Virginia</option>
                            <option>Wisconsin</option>
                            <option>Wyoming</option>
                        </select>
                    </div>
                </div>
                </div>
                <div className={styles.inputsContainer}>
                  <div className={styles.errorAndInputDiv}>
                        {zipError != "" && (<div className={styles.errorDiv}>{zipError}</div>)}
                    <div className={styles.inputDiv}>
                      <input
                          onChange={(e) => setZip(e.target.value)}
                          name="zipCodeInput"
                          type="text"
                          className={styles.formInput}
                          placeholder={"Postal Code"}
                          value={zip}
                      />
                    </div>
                  </div>
                  <div className={styles.addressRadioButtons}>
                  <div className={styles.errorAndInputDiv}>
                        {useError != "" && (<div className={styles.errorDiv}>{useError}</div>)}
                        <br/>
                    <div className={styles.useAddressDiv}>
                        How will you use this property?
                    </div>
                    <div className={styles.coborrowerRadio}>
                      <input type="radio" id="primaryResidence" name="use" checked={use=="Primary Residence"} onChange={(e) => setUse("Primary Residence")}/>
                      <label>Primary Residence</label>
                    </div>
                    <br/>
                    <div className={styles.coborrowerRadio}>
                      <input type="radio" id="secondaryResidence" name="use" checked={use=="Secondary Residence"} onChange={(e) => setUse("Secondary Residence")}/>
                      <label>Secondary Residence</label>
                    </div>
                    <br/>
                    <div className={styles.coborrowerRadio}>
                      <input type="radio" id="investment" name="use" checked={use=="Investment"} onChange={(e) => setUse("Investment")}/>
                      <label>Investment</label>
                    </div>
                  </div>
                  </div>
              </div>
            </div>
          </div>
          <div className={styles.nextButtonDiv}>
              <button className={styles.backButton} onClick={()=>navigate("/apply-for-loan/borrowerform3")}>Back</button>
              <button className={styles.nextButton} onClick={handleForm}>Next</button>
            </div>
          </div>
        </div>
        <div className={styles.myLoanerContainer}>
             <div className={styles.myLoanerContent}>
              <YourLoanOfficer
                name={"Krishna Malyala"}
                email={"kmalyala@nexamortgage.com"}
                phone={"334-625-9252"}
                nmls1={"NMLS #1875937"}
                states={"NJ"}
                nmls2={"NMLS #1660690"}
              />
            </div>      
            
          </div>
      </div>
    </div>
  </div>
  );
}

