import * as React from "react";
import SideMenuBar from "./SideMenuBar";
import NavBar from "./NavBar";
import styles from "./ApplyForLoan.module.css";
import YourLoanOfficer from "./YourLoanOfficer";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import actions from "../actions";
import helpers from "../helpers";

const deepClone = (obj) => {
  if (obj === null) return null;
  let clone = Object.assign({}, obj);
  Object.keys(clone).forEach(
    (key) =>
      (clone[key] =
        typeof obj[key] === "object" ? deepClone(obj[key]) : obj[key])
  );
  if (Array.isArray(obj)) {
    clone.length = obj.length;
    return Array.from(clone);
  }
  return clone;
};

export default function AFLGovtInfoForm1() {
  const navigate = useNavigate();

  const [race, setRace] = useState("");
  const [ethnicity, setEthnicity] = useState("");
  const [coRace, setCoRace] = useState("");
  const [coEthnicity, setCoEthnicity] = useState("");

  const [borrowerTab, setBorrowerTab] = useState(styles.active);
  const [coBorrowerTab, setCoBorrowerTab] = useState(styles.a);
  const [borrowerDiv, setBorrowerDiv] = useState(styles.borrowerFormCardTabs);
  const [coBorrowerDiv, setCoBorrowerDiv] = useState(
    styles.borrowerFormCardTabsInvisible
  );

  const authUser = useSelector((state) => state.users);
  const dispatch = useDispatch();

  function updateBorrowerTab() {
    setBorrowerTab(styles.active);
    setCoBorrowerTab(styles.a);
    setBorrowerDiv(styles.borrowerFormCardTabs);
    setCoBorrowerDiv(styles.borrowerFormCardTabsInvisible);
  }

  function updateCoBorrowerTab() {
    setBorrowerTab(styles.a);
    setCoBorrowerTab(styles.active);
    setBorrowerDiv(styles.borrowerFormCardTabsInvisible);
    setCoBorrowerDiv(styles.borrowerFormCardTabs);
  }

  useEffect(() => {
    if (authUser[0].data.Profile.ApplyForLoanData.GovernmentRaceInfoForm) {
      if (
        authUser[0].data.Profile.ApplyForLoanData.GovernmentRaceInfoForm
          .borrower
      ) {
        if (
          authUser[0].data.Profile.ApplyForLoanData.GovernmentRaceInfoForm
            .borrower.race
        ) {
          setRace(
            authUser[0].data.Profile.ApplyForLoanData.GovernmentRaceInfoForm
              .borrower.race
          );
        }
        if (
          authUser[0].data.Profile.ApplyForLoanData.GovernmentRaceInfoForm
            .borrower.ethnicity
        ) {
          setEthnicity(
            authUser[0].data.Profile.ApplyForLoanData.GovernmentRaceInfoForm
              .borrower.ethnicity
          );
        }
      }
    }
  }, [authUser]);

  const handleForm = async (event) => {
    event.preventDefault();

    await localStorage();
    navigate("/apply-for-loan/borrowerform14");
  };

  const localStorage = async () => {
    let borrower = {
      race: race,
      ethnicity: ethnicity,
    };
    let coborrower = {
      race: coRace,
      ethnicity: coEthnicity,
    };

    const clientId = authUser[0].id;
    const url =
      "https://uat-api.tlcengine.com/v3/api/mlspin-md/clients/" + clientId;
    const token = authUser[0].user.AccessToken;

    let userData = await helpers.getProfile(url, token);
    let copyUserData = helpers.updateData(userData, "GovernmentRaceInfoForm", {
      borrower,
      coborrower,
    });
    await helpers.updateProfile(copyUserData, url, token);

    let thisClient = deepClone(authUser[0]);
    thisClient.data = copyUserData;
    dispatch(actions.addClientPI(thisClient));
  };

  return (
    <div className={styles.applyScreen}>
      <NavBar />
      <div className={styles.applyContainer}>
        <div className={styles.applyContent}>
          <SideMenuBar activeClass={"applyForLoan"} />
          <div className={styles.formContainer}>
            <div className={styles.rectangleTabs} />
            <ul className={styles.tabs}>
              <li className={borrowerTab}>
                <button onClick={updateBorrowerTab}>Borrower</button>
              </li>
              <li className={coBorrowerTab}>
                <button onClick={updateCoBorrowerTab}>Co-Borrower</button>
              </li>
            </ul>

            <div id="borrower" className={borrowerDiv}>
              <div className={styles.borrowerFormCardDiv}>
                <div className={styles.borrowerFormCardTitleText}>
                  <div className={styles.borrowerFormTitle}>
                    Borrower info for Government
                  </div>
                </div>
                <div>
                  <div className={styles.inputsContainer}>
                    <div className={styles.coborrowerRadio}>
                      <div className={styles.declarationsDiv}>
                        <div className={styles.addressRadioButtons}>
                          <div className={styles.labelEthnicity}>
                            Borrower Ethnicity
                          </div>
                          <br />
                          <div className={styles.coborrowerRadio}>
                            <input
                              type="radio"
                              id="primaryResidence"
                              name="ethnicity"
                              checked={ethnicity == "Not Provided"}
                              onChange={(e) => setEthnicity("Not Provided")}
                            />
                            <label>
                              I do not wish to provide this information
                            </label>
                          </div>
                          <br />
                          <div className={styles.coborrowerRadio}>
                            <input
                              type="radio"
                              id="secondaryResidence"
                              name="ethnicity"
                              checked={
                                ethnicity !== "Not Provided" &&
                                ethnicity !== "" &&
                                ethnicity !== "Not Hispanic"
                              }
                            />
                            <label>Hispanic or Latino</label>
                            <div className={styles.coborrowerRadioHispanic}>
                              <div className={styles.coborrowerRadio}>
                                <input
                                  type="radio"
                                  id="secondaryResidence"
                                  name="hispanicEthnicity"
                                  checked={ethnicity == "Mexican"}
                                  onChange={(e) => setEthnicity("Mexican")}
                                />
                                <label>Mexican</label>
                              </div>
                              <div className={styles.coborrowerRadio}>
                                <input
                                  type="radio"
                                  id="secondaryResidence"
                                  name="hispanicEthnicity"
                                  checked={ethnicity == "Puerto Rican"}
                                  onChange={(e) => setEthnicity("Puerto Rican")}
                                />
                                <label>Puerto Rican</label>
                              </div>
                              <div className={styles.coborrowerRadio}>
                                <input
                                  type="radio"
                                  id="secondaryResidence"
                                  name="hispanicEthnicity"
                                  checked={ethnicity == "Cuban"}
                                  onChange={(e) => setEthnicity("Cuban")}
                                />
                                <label>Cuban</label>
                              </div>
                              <div className={styles.coborrowerRadio}>
                                <input
                                  type="radio"
                                  id="secondaryResidence"
                                  name="hispanicEthnicity"
                                />
                                <label>Other</label>
                              </div>
                              <div className={styles.inputDivEthnicity}>
                                <input
                                  onChange={(e) => setEthnicity(e.target.value)}
                                  id="secondaryResidence"
                                  name="hispanicEthnicity"
                                  type="text"
                                  className={styles.formInputEthnicity}
                                  placeholder={"Other"}
                                />
                              </div>
                            </div>
                          </div>
                          <br />
                          <div className={styles.coborrowerRadio}>
                            <input
                              type="radio"
                              id="secondaryResidence"
                              name="hispanicEthnicity"
                              checked={ethnicity == "Not Hispanic"}
                              onChange={(e) => setEthnicity("Not Hispanic")}
                            />
                            <label>Not Hispanic</label>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className={styles.coborrowerRadio}>
                      <div className={styles.addressRadioButtons}>
                        <div className={styles.labelEthnicity}>
                          Borrower Race
                        </div>
                        <br />
                        <div className={styles.coborrowerRadio}>
                          <input
                            type="radio"
                            id="primaryResidence"
                            name="race"
                            onChange={(e) => setRace("notProvided")}
                          />
                          <label>
                            I do not wish to provide this information
                          </label>
                        </div>
                        <div className={styles.blankSpaceGovtInfo} />
                        <div className={styles.coborrowerRadio}>
                          <input
                            type="radio"
                            id="secondaryResidence"
                            name="race"
                          />
                          <label>American Indian or Alaska Native</label>
                          <div className={styles.coborrowerRadioHispanic}>
                            <div className={styles.inputDivEthnicity}>
                              <input
                                onChange={(e) => setRace(e.target.value)}
                                name="otherInput"
                                type="text"
                                className={styles.formInputEthnicity}
                                placeholder={"Other"}
                              />
                            </div>
                          </div>
                        </div>
                        <div className={styles.blankSpaceGovtInfo} />
                        <div className={styles.coborrowerRadio}>
                          <input
                            type="radio"
                            id="primaryResidence"
                            name="race"
                            onChange={(e) => setRace("Black")}
                          />
                          <label>Black or African American</label>
                        </div>
                        <div className={styles.blankSpaceGovtInfo} />
                        <div className={styles.coborrowerRadio}>
                          <input
                            type="radio"
                            id="primaryResidence"
                            name="race"
                            onChange={(e) => setRace("White")}
                          />
                          <label>White</label>
                        </div>
                        <div className={styles.blankSpaceGovtInfo} />
                        <div className={styles.coborrowerRadio}>
                          <input
                            type="radio"
                            id="primaryResidence"
                            name="race"
                            onChange={(e) => setRace("Asian")}
                          />
                          <label>Asian</label>
                        </div>
                        <div className={styles.blankSpaceGovtInfo} />
                        <div className={styles.coborrowerRadio}>
                          <input
                            type="radio"
                            id="primaryResidence"
                            name="race"
                            onChange={(e) => setRace("pacificIslander")}
                          />
                          <label>
                            Native Hawaiian or other Pacific Islander
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
              <div className={styles.nextButtonDiv}>
                <button
                  className={styles.backButton}
                  onClick={() => navigate("/apply-for-loan/borrowerform12")}
                >
                  Back
                </button>
                <button className={styles.nextButton} onClick={handleForm}>
                  Next
                </button>
              </div>
            </div>

            <div id="coborrower" className={coBorrowerDiv}>
              <div className={styles.borrowerFormCardDiv}>
                <div className={styles.borrowerFormCardTitleText}>
                  <div className={styles.borrowerFormTitle}>
                    Co-Borrower info for Government
                  </div>
                </div>
                <div>
                  <div className={styles.inputsContainer}>
                    <div className={styles.coborrowerRadio}>
                      <div className={styles.declarationsDiv}>
                        <div className={styles.addressRadioButtons}>
                          <div className={styles.labelEthnicity}>
                            Borrower Ethnicity
                          </div>
                          <br />
                          <div className={styles.coborrowerRadio}>
                            <input
                              type="radio"
                              id="primaryResidence"
                              name="ethnicity"
                              onChange={(e) => setCoEthnicity("notProvided")}
                            />
                            <label>
                              I do not wish to provide this information
                            </label>
                          </div>
                          <br />
                          <div className={styles.coborrowerRadio}>
                            <input
                              type="radio"
                              id="secondaryResidence"
                              name="ethnicity"
                            />
                            <label>Hispanic or Latino</label>
                            <div className={styles.coborrowerRadioHispanic}>
                              <div className={styles.coborrowerRadio}>
                                <input
                                  type="radio"
                                  id="secondaryResidence"
                                  name="hispanicEthnicity"
                                  onChange={(e) => setCoEthnicity("Mexican")}
                                />
                                <label>Mexican</label>
                              </div>
                              <div className={styles.coborrowerRadio}>
                                <input
                                  type="radio"
                                  id="secondaryResidence"
                                  name="hispanicEthnicity"
                                  onChange={(e) =>
                                    setCoEthnicity("PuertoRican")
                                  }
                                />
                                <label>Puerto Rican</label>
                              </div>
                              <div className={styles.coborrowerRadio}>
                                <input
                                  type="radio"
                                  id="secondaryResidence"
                                  name="hispanicEthnicity"
                                  onChange={(e) => setCoEthnicity("Cuban")}
                                />
                                <label>Cuban</label>
                              </div>
                              <div className={styles.coborrowerRadio}>
                                <input
                                  type="radio"
                                  id="secondaryResidence"
                                  name="hispanicEthnicity"
                                />
                                <label>Other</label>
                              </div>
                              <div className={styles.inputDivEthnicity}>
                                <input
                                  onChange={(e) => setCoEthnicity(e.target)}
                                  name="otherInput"
                                  type="text"
                                  className={styles.formInputEthnicity}
                                  placeholder={"Other"}
                                />
                              </div>
                            </div>
                          </div>
                          <br />
                          <div className={styles.coborrowerRadio}>
                            <input
                              type="radio"
                              id="investment"
                              name="ethnicity"
                            />
                            <label>Not Hispanic</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.coborrowerRadio}>
                      <div className={styles.addressRadioButtons}>
                        <div className={styles.labelEthnicity}>
                          Borrower Race
                        </div>
                        <br />
                        <div className={styles.coborrowerRadio}>
                          <input
                            type="radio"
                            id="primaryResidence"
                            name="race"
                            onChange={(e) => setCoRace("notProvided")}
                          />
                          <label>
                            I do not wish to provide this information
                          </label>
                        </div>
                        <div className={styles.blankSpaceGovtInfo} />
                        <div className={styles.coborrowerRadio}>
                          <input
                            type="radio"
                            id="secondaryResidence"
                            name="race"
                          />
                          <label>American Indian or Alaska Native</label>
                          <div className={styles.coborrowerRadioHispanic}>
                            <div className={styles.inputDivEthnicity}>
                              <input
                                onChange={(e) => setCoRace(e.target)}
                                name="otherInput"
                                type="text"
                                className={styles.formInputEthnicity}
                                placeholder={"Other"}
                              />
                            </div>
                          </div>
                        </div>
                        <div className={styles.blankSpaceGovtInfo} />
                        <div className={styles.coborrowerRadio}>
                          <input
                            type="radio"
                            id="primaryResidence"
                            name="race"
                            onChange={(e) => setCoRace("Black")}
                          />
                          <label>Black or African American</label>
                        </div>
                        <div className={styles.blankSpaceGovtInfo} />
                        <div className={styles.coborrowerRadio}>
                          <input
                            type="radio"
                            id="primaryResidence"
                            name="race"
                            onChange={(e) => setCoRace("White")}
                          />
                          <label>White</label>
                        </div>
                        <div className={styles.blankSpaceGovtInfo} />
                        <div className={styles.coborrowerRadio}>
                          <input
                            type="radio"
                            id="primaryResidence"
                            name="race"
                            onChange={(e) => setCoRace("Asian")}
                          />
                          <label>Asian</label>
                        </div>
                        <div className={styles.blankSpaceGovtInfo} />
                        <div className={styles.coborrowerRadio}>
                          <input
                            type="radio"
                            id="primaryResidence"
                            name="race"
                            onChange={(e) => setCoRace("pacificIslander")}
                          />
                          <label>
                            Native Hawaiian or other Pacific Islander
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.myLoanerContainer}>
            <div className={styles.myLoanerContent}>
              <YourLoanOfficer
                name={"Krishna Malyala"}
                email={"kmalyala@nexamortgage.com"}
                phone={"334-625-9252"}
                nmls1={"NMLS #1875937"}
                states={"NJ"}
                nmls2={"NMLS #1660690"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
