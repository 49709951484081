import * as React from "react";
import SideMenuBar from "./SideMenuBar";
import NavBar from './NavBar';

export default function ChatWithLo() {
  return (
    <div>
        <NavBar/>
        <SideMenuBar activeClass={"chatWithLo"}/>
    </div>
  );
}
