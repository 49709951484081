import * as React from "react";
import SideMenuBar from "./SideMenuBar";
import NavBar from './NavBar';
import styles from './ApplyForLoan.module.css'
import YourLoanOfficer from "./YourLoanOfficer";
import {useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import actions from "../actions";
import helpers from "../helpers";

const deepClone = obj => {
  if (obj === null) return null;
  let clone = Object.assign({}, obj);
  Object.keys(clone).forEach(
    key =>
      (clone[key] =
        typeof obj[key] === 'object' ? deepClone(obj[key]) : obj[key])
  );
  if (Array.isArray(obj)) {
    clone.length = obj.length;
    return Array.from(clone);
  }
  return clone;
};

export default function AFLBorrowerAddressForm() {
  const navigate = useNavigate(); 
  
  const authUser = useSelector((state) => state.users);
  const dispatch = useDispatch();

  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('Alabama');
  const [zip, setZip] = useState('');
  const [ownRentStatus, setOwnRentStatus] = useState('');
  const [yearsAtCurrent, setYearsAtCurrent] = useState('');
  const [monthsAtCurrent, setMonthsAtCurrent] = useState('');
  // const [liveAtPresent1, setLiveAtPresent1] = useState('');
  const [diffMailing1, setDiffMailing1] = useState('');

  const [address1Error, setAddress1Error] = useState('');
  const [address2Error, setAddress2Error] = useState('');
  const [cityError, setCityError] = useState('');
  const [zipError, setZipError] = useState('');
  const [ownRentStatusError, setOwnRentStatusError] = useState('');
  const [yearandMonthAtCurrentError, setYearandMonthAtCurrentError] = useState('');
  const [mailingError1, setMailingError1] = useState('');

  useEffect(() => {
    if(authUser[0].data.Profile.ApplyForLoanData.AddressForm) {
      if(authUser[0].data.Profile.ApplyForLoanData.AddressForm.address1) {
        setAddress1(authUser[0].data.Profile.ApplyForLoanData.AddressForm.address1);
      }
      if(authUser[0].data.Profile.ApplyForLoanData.AddressForm.address2) {
        setAddress2(authUser[0].data.Profile.ApplyForLoanData.AddressForm.address2);
      }
      if(authUser[0].data.Profile.ApplyForLoanData.AddressForm.city) {
        setCity(authUser[0].data.Profile.ApplyForLoanData.AddressForm.city);
      }
      if(authUser[0].data.Profile.ApplyForLoanData.AddressForm.state) {
        setState(authUser[0].data.Profile.ApplyForLoanData.AddressForm.state);
      }
      if(authUser[0].data.Profile.ApplyForLoanData.AddressForm.zip) {
        setZip(authUser[0].data.Profile.ApplyForLoanData.AddressForm.zip);
      }
      if(authUser[0].data.Profile.ApplyForLoanData.AddressForm.ownRentStatus) {
        setOwnRentStatus(authUser[0].data.Profile.ApplyForLoanData.AddressForm.ownRentStatus);
      }
      if(authUser[0].data.Profile.ApplyForLoanData.AddressForm.yearsAtCurrent) {
        setYearsAtCurrent(authUser[0].data.Profile.ApplyForLoanData.AddressForm.yearsAtCurrent);
      }
      if(authUser[0].data.Profile.ApplyForLoanData.AddressForm.monthsAtCurrent) {
        setMonthsAtCurrent(authUser[0].data.Profile.ApplyForLoanData.AddressForm.monthsAtCurrent);
      }
      // if(authUser[0].data.Profile.ApplyForLoanData.AddressForm.liveAtPresent1) {
      //   setLiveAtPresent1(authUser[0].data.Profile.ApplyForLoanData.AddressForm.liveAtPresent1);
      // }
      if(authUser[0].data.Profile.ApplyForLoanData.AddressForm.diffMailing1) {
        setDiffMailing1(authUser[0].data.Profile.ApplyForLoanData.AddressForm.diffMailing1);
      }
    }
  }, authUser)

  const handleForm = async (event) => {
    event.preventDefault()
    let errors = 0;

    //address validation
    try {
      helpers.validAddress(address1);
      setAddress1Error("");
    } catch (e) {
      setAddress1Error(e.message);
      errors++;
    }

    try {
      helpers.validAddress2(address2);
      setAddress2Error("");
    } catch (e) {
      setAddress2Error(e.message);
      errors++;
    }

    try {
      helpers.validCity(city);
      setCityError("");
    } catch (e) {
      setCityError(e.message);
      errors++;
    }

    try {
      helpers.validZip(zip);
      setZipError("");
    } catch (e) {
      setZipError(e.message);
      errors++;
    }

    try {
      helpers.validOwnRentStatus(ownRentStatus);
      setOwnRentStatusError("");
    } catch (e) {
      setOwnRentStatusError(e.message);
      errors++;
    }

    try {
      helpers.validYearandMonth(yearsAtCurrent, monthsAtCurrent);
      setYearandMonthAtCurrentError("");
    } catch (e) {
      setYearandMonthAtCurrentError(e.message);
      errors++;
    }

    try {
      helpers.validMailing(diffMailing1);
      setMailingError1("");
    } catch (e) {
      setMailingError1(e.message);
      errors++;
    }

    if (errors == 0) {
      await localStorage();
    } else {
      if (errors == 0) {
        await localStorage();
      }
    }  
  };

  const localStorage = async () => {
    let data = {
      address1: address1,
      address2: address2,
      city: city,
      state: state,
      zip: zip,
      ownRentStatus: ownRentStatus,
      yearsAtCurrent: yearsAtCurrent,
      monthsAtCurrent: monthsAtCurrent,
      // liveAtPresent: liveAtPresent1,
      diffMailing1: diffMailing1
    }

    const clientId = authUser[0].id;
    const url = "https://uat-api.tlcengine.com/v3/api/mlspin-md/clients/" + clientId;
    const token = authUser[0].user.AccessToken

    let userData = await helpers.getProfile(url, token);
    let copyUserData = helpers.updateData(userData, "AddressForm", data);
    await helpers.updateProfile(copyUserData, url, token);
    
    let thisClient = deepClone(authUser[0]);
    thisClient.data = copyUserData;
    dispatch(actions.addClientPI(thisClient))

    navigate("/apply-for-loan/borrowerform4")
  }

  return (
    <div className={styles.applyScreen}>
      <NavBar/>
      <div className={styles.applyContainer}>
        <div className={styles.applyContent}>
          <SideMenuBar activeClass={"applyForLoan"}/>
          <div className={styles.formContainer}>
            <div className={styles.rectangle}/>
            <div className={styles.borrowerFormCard}>
            <div className={styles.borrowerFormCardDiv}>
              <div className={styles.borrowerFormCardTitleText}>
                <div className={styles.borrowerFormTitle}>
                  Borrower Information
                </div>
              </div>
              <div >
                <div className={styles.inputsContainer}>
                  <div className={styles.errorAndInputDiv}>
                        {address1Error != "" && (<div className={styles.errorDiv}>{address1Error}</div> )}
                    <div className={styles.inputDiv}>
                      <input
                          onChange={(e) => setAddress1(e.target.value)}
                          name="addressInput"
                          type="text"
                          className={styles.formInput}
                          placeholder={"Street Address"}
                          value={address1}
                      />
                    </div>
                  </div>
                  <div className={styles.errorAndInputDiv}>
                      {address2Error != "" && (<div className={styles.errorDiv}>{address2Error}</div>)}
                    <div className={styles.inputDiv}>
                      <input
                          onChange={(e) => setAddress2(e.target.value)}
                          name="secondAddressInput"
                          type="text"
                          className={styles.formInput}
                          placeholder={"Street Address Second Line"}
                          value={address2}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.inputsContainer}>
                  <div className={styles.errorAndInputDiv}>
                        {cityError != "" && (<div className={styles.errorDiv}>{cityError}</div>)}
                    <div className={styles.inputDiv}>
                      <input
                          onChange={(e) => setCity(e.target.value)}
                          name="cityInput"
                          type="text"
                          className={styles.formInput}
                          placeholder={"City"}
                          value={city}
                      />
                    </div>
                  </div>
                  <div className={styles.inputDiv}>
                    <div className={styles.dropdown}>
                        <select className={styles.selectBox}
                          onChange={(e) => setState(e.target.value)}
                          value={state}
                        >
                            <option>Alabama</option>
                            <option>Alaska</option>
                            <option>Arizona</option>
                            <option>Arkansas</option>
                            <option>California</option>
                            <option>Colorado</option>
                            <option>Connecticut</option>
                            <option>Deleware</option>
                            <option>Florida</option>
                            <option>Georgia</option>
                            <option>Hawaii</option>
                            <option>Idaho</option>
                            <option>Illinois</option>
                            <option>Indiana</option>
                            <option>Iowa</option>
                            <option>Kansas</option>
                            <option>Kentucky</option>
                            <option>Louisiana</option>
                            <option>Maine</option>
                            <option>Maryland</option>
                            <option>Massachusetts</option>
                            <option>Michigan</option>
                            <option>Minnesota</option>
                            <option>Mississippi</option>
                            <option>Missouri</option>
                            <option>Montana</option>
                            <option>Nebraska</option>
                            <option>Nevada</option>
                            <option>New Hampshire</option>
                            <option>New Jersey</option>
                            <option>New Mexico</option>
                            <option>New York</option>
                            <option>North Carolina</option>
                            <option>North Dakota</option>
                            <option>Ohio</option>
                            <option>Oklahoma</option>
                            <option>Oregon</option>
                            <option>Pennsylvania</option>
                            <option>Rhode Island</option>
                            <option>South Carolina</option>
                            <option>South Dakota</option>
                            <option>Tennessee</option>
                            <option>Texas</option>
                            <option>Utah</option>
                            <option>Vermont</option>
                            <option>Virginia</option>
                            <option>Washington</option>
                            <option>West Virginia</option>
                            <option>Wisconsin</option>
                            <option>Wyoming</option>
                        </select>
                    </div>
                  </div>
                </div>
                <div className={styles.inputsContainer}>
                  <div className={styles.errorAndInputDiv}>
                        {zipError != "" && (<div className={styles.errorDiv}>{zipError}</div>)}
                    <div className={styles.inputDiv}>
                      <input
                          onChange={(e) => setZip(e.target.value)}
                          name="zipCodeInput"
                          type="text"
                          className={styles.formInput}
                          placeholder={"Postal Code"}
                          value={zip}
                      />
                    </div>
                  </div>
                  <div className={styles.ownerRadioButtons}>
                    <div className={styles.errorAndInputDiv}>
                          {ownRentStatusError != "" && (<div className={styles.errorDiv}>{ownRentStatusError}</div>)}
                      <br/>
                      <div className={styles.coborrowerRadio}>
                        <input type="radio" id="own" name="ownerRadio" checked={ownRentStatus=="own"} onChange={(e) => setOwnRentStatus("own")}/>
                        <label>Own</label>
                      </div>
                      <div className={styles.coborrowerRadio}>
                        <input type="radio" id="rent" name="ownerRadio" checked={ownRentStatus=="rent"}onChange={(e) => setOwnRentStatus("rent")}/>
                        <label>Rent</label>
                      </div>
                      <div className={styles.coborrowerRadio}>
                        <input type="radio" id="noRent" name="ownerRadio" checked={ownRentStatus=="no rent"} onChange={(e) => setOwnRentStatus("no rent")}/>
                        <label>No Rent</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.errorAndInputDiv}>
                    {yearandMonthAtCurrentError != "" && (<div className={styles.errorDiv}>{yearandMonthAtCurrentError}</div>)}
                  <div className={styles.label} htmlFor="pswdInput">
                          How long at current address?
                  </div>
                </div>
                <div className={styles.inputsContainer}>
                    <div className={styles.yearsAtAddressDiv}>
                        <div className={styles.dropdown}>
                            <select className={styles.selectBox}
                              onChange={(e) => setYearsAtCurrent(e.target.value)}
                              value={yearsAtCurrent}>
                                <option>Years</option>
                                <option>0</option>
                                <option>1</option>
                                <option>2</option>
                                <option>3</option>
                                <option>4</option>
                            </select>
                        </div>
                    </div>
                    <div className={styles.yearsAtAddressDiv}>
                          <div className={styles.dropdown}>
                              <select className={styles.selectBox}
                                onChange={(e) => setMonthsAtCurrent(e.target.value)}
                                value={monthsAtCurrent}>
                                  <option>Months</option>
                                  <option>1</option>
                                  <option>2</option>
                                  <option>3</option>
                                  <option>4</option>
                                  <option>5</option>
                                  <option>6</option>
                                  <option>7</option>
                                  <option>8</option>
                                  <option>9</option>
                                  <option>10</option>
                                  <option>11</option>
                                  <option>12</option>
                              </select>
                          </div>
                      </div>
                </div>
            </div>
            <br/>
            <div className={styles.errorAndInputDiv}>
                    {mailingError1 != "" && (<div className={styles.errorDiv}>{mailingError1}</div>)}
              <div className={styles.nextButtonWithRadiosDiv}>
                  <div className={styles.coborrowerRadio}>
                    <input type="radio" id="presentAddress"  name="presentOrDiffAddress" onChange={
                      (e) => setDiffMailing1("false")}/>
                    <label>I live at my present address</label>
                </div>
                  <div className={styles.coborrowerRadio}>
                    <input type="radio" id="mailingAddress" name="presentOrDiffAddress" onChange={
                        (e) => setDiffMailing1("true")}/>
                    <label>I use a separate mailing address</label>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.nextButtonDiv}>
            <button className={styles.backButton} onClick={()=>navigate("/apply-for-loan/borrowerform2")}>Back</button>
              <button className={styles.nextButton} onClick={handleForm}>Next</button>
            </div>
        </div>
        </div>
        <div className={styles.myLoanerContainer}>
             <div className={styles.myLoanerContent}>
              <YourLoanOfficer
                name={"Krishna Malyala"}
                email={"kmalyala@nexamortgage.com"}
                phone={"334-625-9252"}
                nmls1={"NMLS #1875937"}
                states={"NJ"}
                nmls2={"NMLS #1660690"}
              />
            </div>      
            
          </div>
      </div>
    </div>
  </div>
  );
}


